@media all and (min-width: 1025px) {
  .wrapper_h {
    display: flex;
    width: 100%;
    aspect-ratio: 2.55;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
  }
  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    /* background-repeat: no-repeat; */
    background-position: 50% 0;
    background-size: cover;
    transition: 0.3s;
    opacity: 1;
    animation: fadeInBg 10s linear both;
  }

  .background:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .background_blur {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    /* z-index: -1; */
  }
  .contact_area {
    height: 100%;
  }
  .contact_title_wrap {
    display: flex;
    flex-direction: column;
    row-gap: 58px;
    align-items: center;
    height: 100%;
    justify-content: center;
  }
  .contact_title {
    margin-top: 80px;
    font-family: Pretendard;
    font-size: 75px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
  .contact_subtitle {
    font-family: Pretendard;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
  .contact_subtitle2 {
    width: 546px;
    height: 66px;
    border-radius: 32px;
    background-color: #fff;
    margin-top: 25px;
    font-family: Pretendard;
    font-size: 22px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #222;
    line-height: 66px;
  }

  .contact_category {
    height: 90px;
    line-height: 90px;
    border-bottom: 1px solid #c0c0c0;
    margin-bottom: 59px;
    display: flex;
    justify-content: center;
  }
  .contact_category_buttons {
    display: flex;
    width: 1280px;
  }
  .contact_button {
    line-height: 89px;
    height: 89px;
    font-size: 19px;
    font-family: Pretendard;
    margin-right: 61px;
    font-weight: bold;
    color: #191919;
  }
  .button_focus {
    border-bottom: 2px solid #00177c;
    color: #00177c;
  }

  .contact_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 111px;
  }
  .online_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .contact_body {
    width: 1280px;
  }
  .contact_quote {
    width: 100%;
    text-align: right;
    margin-bottom: 65px;
    font-family: Pretendard;
  }
  .contact_quote_mark {
    color: #009fa9;
  }
  .contact_contents {
    display: flex;
    justify-content: space-between;
  }
  .contact_header {
    margin-top: 84px;
  }
  .contact_title_2 {
    font-family: Pretendard;
    font-size: 45px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.23px;
    text-align: left;
    color: #191919;
    margin-bottom: 47px;
  }
  .contact_title_3 {
    color: #00177c;
  }
  .contact_input_line {
    width: 831px;
    display: flex;
    justify-content: space-between;
  }

  .contact_input_wrapper {
    margin-bottom: 60px;
  }
  .contact_input_header {
    font-family: pretendard;
    font-size: 19px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #111;
    margin-bottom: 16px;
  }
  .contact_input_header_none {
    height: 39px;
  }
  .contact_input_body {
    width: 356px;
    height: 58px;
    padding: 0px 22px;
    display: flex;
    border: 1px solid #e1e1e1;
  }
  .contact_input_post {
    width: 787px;
    height: 58px;
    padding: 0px 22px;
    display: flex;
    border: 1px solid #e1e1e1;
  }
  .contact_input_textarea {
    width: 787px;
    height: 122px;
    padding: 20px 22px;
    display: flex;
    border: 1px solid #e1e1e1;
  }
  .contact_input {
    width: 100%;
    outline: none;
    border: none;
    font-size: 19px;
    font-family: Pretendard;
  }
  .contact_input_time {
    width: 130px;
    outline: none;
    border: none;
    font-size: 19px;
    font-family: Pretendard;
  }
  .contact_post {
    width: 100%;
    outline: none;
    border: none;
    font-size: 19px;
    font-family: Pretendard;
  }
  .contact_textarea {
    width: 100%;
    outline: none;
    border: none;
    font-size: 19px;
    height: 122px;
    resize: none;
    font-family: Pretendard;
  }
  .contact_line {
    width: 100%;
    height: 1px;
    background-color: #e1e1e1;
    margin-bottom: 50px;
  }
  .postmodal {
    margin: 10% auto;
    width: 600px;
    border: 1px solid black;
  }
  .selectbox {
    outline: NONE;
    border: NONE;
    width: 100%;
    font-size: 19px;
    font-family: Pretendard;
  }
  .selectbox_contact {
    width: 400px;
    padding: 0px 22px;
    height: 60px;
    outline: none;
    font-size: 19px;
    font-family: Pretendard;
    border: 1px solid #e1e1e1;
    background: url("http://bluetns.co.kr/img/common/arrow_down.png") no-repeat
      94% 50% / 15px auto;
    appearance: none;
  }
  .selectbox_free {
    width: 400px;
    padding: 0px 22px;
    height: 60px;
    outline: none;
    font-size: 19px;
    font-family: Pretendard;
    border: 1px solid #e1e1e1;
    background: url("http://bluetns.co.kr/img/common/arrow_down.png") no-repeat
      94% 50% / 15px auto;
    appearance: none;
  }
  .selectbox_list {
    height: 100px;
  }
  .send_button {
    margin: 35px auto 230px auto;
    width: 255px;
    height: 60px;
    border-radius: 5px;
    line-height: 60px;
    text-align: center;
    background-color: #00177c;
    font-family: Pretendard;
    font-size: 19px;
    font-weight: 500;
    color: #fff;
  }
  .contact_select_product {
    display: flex;
    width: 831px;
    justify-content: space-between;
  }
  .selected {
    width: 257px;
    height: 58px;
    border-radius: 5px;
    background-color: #009fa9;
    line-height: 60px;
    font-family: Pretendard;
    font-size: 19px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    border: 1px solid #009fa9;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    cursor: pointer;
  }
  .nonselected {
    width: 257px;
    height: 58px;
    border-radius: 5px;
    background-color: #fff;
    line-height: 60px;
    font-family: Pretendard;
    font-size: 19px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    border: 1px solid #e1e1e1;
    letter-spacing: normal;
    text-align: center;
    color: #111111;
    cursor: pointer;
  }
  .contact_promotion {
    display: flex;
    align-items: center;
  }
  .contact_promotion2 {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
  .contact_promotion_box {
    margin-left: 31px;

    font-family: Pretendard;
    font-size: 19px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #111;
    display: flex;
    align-items: center;
    gap: 12px;
  }

  /* 여기 */
  @media all and (min-width: 1025px) and (max-width: 1300px) {
    .contact_title_wrap {
      display: flex;
      flex-direction: column;
      row-gap: 30px;
      align-items: center;
      height: 100%;
      justify-content: center;
    }
    .contact_header {
      margin-top: 0px;
    }
    .contact_contents {
      display: flex;
      flex-direction: column;
      width: calc(100% - 80px);
    }
    .contact_body {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .contact_body_wrapper {
      width: 900px;
    }
    .contact_input_line {
      width: 900px;
      display: flex;
      justify-content: space-between;
    }
    .contact_input_post {
      width: 856px;
      height: 58px;
      padding: 0px 22px;
      display: flex;
      border: 1px solid #e1e1e1;
    }
    .contact_title_2 {
      font-family: Pretendard;
      font-size: 40px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.23px;
      text-align: left;
      color: #191919;
      margin-bottom: 0px;
    }
    .contact_select_product {
      display: flex;
      width: 900px;
      justify-content: space-between;
    }
  }
}

/* PC(해상도 1920px ~)*/
@media all and (min-width: 1921px) {
}

/* 테블릿 가로, 테블릿 세로 (해상도 768px ~ 1024px)*/
@media all and (min-width: 768px) and (max-width: 1024px) {
  .wrapper_h {
    display: flex;
    width: 100%;
    height: 630px;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
  }
  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    /* background-repeat: no-repeat; */
    background-position: 50% 0;
    background-size: cover;
    transition: 0.3s;
    opacity: 1;
    animation: fadeInBg 10s linear both;
  }

  .background:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .background_blur {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    /* z-index: -1; */
  }
  .contact_area {
    height: 100%;
  }
  .contact_title_wrap {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    align-items: center;
    height: 100%;
    justify-content: center;
  }
  .contact_title {
    margin-top: 40px;
    font-family: Pretendard;
    font-size: 60px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
  .contact_subtitle {
    font-family: Pretendard;
    font-size: 19px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
  .contact_subtitle2 {
    width: 350px;
    height: 40px;
    border-radius: 28px;
    background-color: #fff;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #222;
    line-height: 40px;
  }

  .contact_category {
    height: 90px;
    line-height: 90px;
    border-bottom: 1px solid #c0c0c0;
    margin-bottom: 59px;
    display: flex;
    justify-content: center;
  }
  .contact_category_buttons {
    display: flex;
    width: 1280px;
  }
  .contact_button {
    line-height: 89px;
    height: 89px;
    font-size: 19px;
    font-family: Pretendard;
    margin-right: 61px;
    font-weight: bold;
    color: #191919;
  }
  .button_focus {
    border-bottom: 2px solid #00177c;
    color: #00177c;
  }

  .contact_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }
  .online_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .contact_body {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .contact_body_wrapper {
    width: 660px;
  }
  .contact_quote {
    width: 100%;
    text-align: right;
    margin-bottom: 30px;
    font-family: Pretendard;
  }
  .contact_quote_mark {
    color: #009fa9;
  }
  .contact_contents {
    display: flex;
    flex-direction: column;
    width: 660px;
  }
  .contact_header {
    margin-top: 0px;
  }
  .contact_title_2 {
    font-family: Pretendard;
    font-size: 38px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.23px;
    text-align: left;
    color: #191919;
    margin-bottom: 40px;
  }
  .contact_title_3 {
    color: #00177c;
  }
  .contact_input_line {
    width: 660px;
    display: flex;
    justify-content: space-between;
  }

  .contact_input_wrapper {
    margin-bottom: 25px;
  }
  .contact_input_header {
    font-family: pretendard;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #111;
    margin-bottom: 16px;
  }
  .contact_input_header_none {
    height: 39px;
  }
  .contact_input_body {
    width: 356px;
    height: 58px;
    padding: 0px 22px;
    display: flex;
    border: 1px solid #e1e1e1;
  }
  .contact_input_post {
    width: 616px;
    height: 58px;
    padding: 0px 22px;
    display: flex;
    border: 1px solid #e1e1e1;
  }
  .contact_input_textarea {
    width: 787px;
    height: 122px;
    padding: 20px 22px;
    display: flex;
    border: 1px solid #e1e1e1;
  }
  .contact_input {
    width: 100%;
    outline: none;
    border: none;
    font-size: 16px;
    font-family: Pretendard;
  }
  .contact_input_time {
    width: 130px;
    outline: none;
    border: none;
    font-size: 19px;
    font-family: Pretendard;
  }
  .contact_post {
    width: 100%;
    outline: none;
    border: none;
    font-size: 16px;
    font-family: Pretendard;
  }
  .contact_textarea {
    width: 100%;
    outline: none;
    border: none;
    font-size: 19px;
    height: 122px;
    resize: none;
    font-family: Pretendard;
  }
  .contact_line {
    width: 100%;
    height: 1px;
    background-color: #e1e1e1;
    margin-bottom: 50px;
  }
  .postmodal {
    margin: 10% auto;
    width: 600px;
    border: 1px solid black;
  }
  .selectbox {
    outline: NONE;
    border: NONE;
    width: 100%;
    font-size: 16px;
    font-family: Pretendard;
  }
  .selectbox_contact {
    width: 300px;
    padding: 0px 22px;
    height: 60px;
    outline: none;
    font-size: 16px;
    font-family: Pretendard;
    border: 1px solid #e1e1e1;
    background: url("http://bluetns.co.kr/img/common/arrow_down.png") no-repeat
      94% 50% / 15px auto;
    appearance: none;
  }
  .selectbox_free {
    width: 300px;
    padding: 0px 22px;
    height: 60px;
    outline: none;
    font-size: 16px;
    font-family: Pretendard;
    border: 1px solid #e1e1e1;
    background: url("http://bluetns.co.kr/img/common/arrow_down.png") no-repeat
      94% 50% / 15px auto;
    appearance: none;
  }
  .selectbox_list {
    height: 100px;
  }
  .send_button {
    margin: 35px auto 100px auto;
    width: 255px;
    height: 60px;
    border-radius: 5px;
    line-height: 60px;
    text-align: center;
    background-color: #00177c;
    font-family: Pretendard;
    font-size: 19px;
    font-weight: 500;
    color: #fff;
  }
  .contact_select_product {
    display: flex;
    width: 660px;
    justify-content: space-between;
  }
  .selected {
    width: 200px;
    height: 58px;
    border-radius: 5px;
    background-color: #009fa9;
    line-height: 60px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    border: 1px solid #009fa9;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    cursor: pointer;
  }
  .nonselected {
    width: 200px;
    height: 58px;
    border-radius: 5px;
    background-color: #fff;
    line-height: 60px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    border: 1px solid #e1e1e1;
    letter-spacing: normal;
    text-align: center;
    color: #111111;
    cursor: pointer;
  }
  .contact_promotion {
    display: flex;
    align-items: center;
  }
  .contact_promotion2 {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
  .contact_promotion_box {
    margin-left: 31px;

    font-family: Pretendard;
    font-size: 19px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #111;
    display: flex;
    align-items: center;
    gap: 12px;
  }
}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width: 767px) {
  .wrapper_h {
    display: flex;
    width: 100%;
    height: 530px;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
  }
  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    /* background-repeat: no-repeat; */
    background-position: 50% 0;
    background-size: cover;
    transition: 0.3s;
    opacity: 1;
    animation: fadeInBg 10s linear both;
  }

  .background:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .background_blur {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    /* z-index: -1; */
  }
  .contact_area {
    height: 100%;
  }
  .contact_title_wrap {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    align-items: center;
    height: 100%;
    justify-content: center;
  }
  .contact_title {
    margin-top: 40px;
    font-family: Pretendard;
    font-size: 34px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
  .contact_subtitle {
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
  .contact_subtitle2 {
    width: 325px;
    height: 45px;
    border-radius: 22px;
    background-color: #fff;
    font-family: Pretendard;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    margin-top: 15px;
    letter-spacing: normal;
    text-align: center;
    color: #222;
    line-height: 45px;
  }

  .contact_category {
    height: 90px;
    line-height: 90px;
    border-bottom: 1px solid #c0c0c0;
    margin-bottom: 59px;
    display: flex;
    justify-content: center;
  }
  .contact_category_buttons {
    display: flex;
    width: 1280px;
  }
  .contact_button {
    line-height: 89px;
    height: 89px;
    font-size: 19px;
    font-family: Pretendard;
    margin-right: 61px;
    font-weight: bold;
    color: #191919;
  }
  .button_focus {
    border-bottom: 2px solid #00177c;
    color: #00177c;
  }

  .contact_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
  .online_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .contact_body {
    width: calc(100% - 40px);
    display: flex;
    justify-content: center;
  }
  .contact_body_wrapper {
    width: 100%;
  }
  .contact_quote {
    width: 100%;
    text-align: right;
    margin-bottom: 23px;
    font-family: Pretendard;
    font-size: 14px;
  }
  .contact_quote_mark {
    color: #009fa9;
  }
  .contact_contents {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .contact_header {
    margin-top: 0px;
  }
  .contact_title_2 {
    font-family: Pretendard;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.23px;
    text-align: center;
    color: #191919;
    margin-bottom: 40px;
  }
  .contact_title_3 {
    color: #00177c;
  }
  .contact_input_line {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .contact_input_wrapper {
    width: 100%;
    margin-bottom: 25px;
  }
  .contact_input_header {
    font-family: pretendard;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #111;
    margin-bottom: 16px;
  }
  .contact_input_header_none {
    height: 35px;
  }
  .contact_input_body {
    width: 356px;
    height: 58px;
    padding: 0px 22px;
    display: flex;
    border: 1px solid #e1e1e1;
  }
  .contact_input_post {
    width: calc(100% - 30px);
    height: 50px;
    padding: 0px 15px;
    display: flex;
    border: 1px solid #e1e1e1;
  }
  .contact_input_textarea {
    width: 787px;
    height: 122px;
    padding: 20px 22px;
    display: flex;
    border: 1px solid #e1e1e1;
  }
  .contact_input {
    width: 100%;
    outline: none;
    border: none;
    font-size: 16px;
    font-family: Pretendard;
  }
  .contact_input_time {
    width: 130px;
    outline: none;
    border: none;
    font-size: 19px;
    font-family: Pretendard;
  }
  .contact_post {
    width: 100%;
    outline: none;
    border: none;
    font-size: 16px;
    font-family: Pretendard;
  }
  .contact_textarea {
    width: 100%;
    outline: none;
    border: none;
    font-size: 19px;
    height: 122px;
    resize: none;
    font-family: Pretendard;
  }
  .contact_line {
    width: 100%;
    height: 1px;
    background-color: #e1e1e1;
    margin-bottom: 50px;
  }
  .postmodal {
    margin: 10% auto;
    width: 600px;
    border: 1px solid black;
  }
  .selectbox {
    outline: NONE;
    border: NONE;
    width: 100%;
    font-size: 16px;
    font-family: Pretendard;
  }
  .selectbox_contact {
    width: 100%;
    padding: 0px 15px;
    height: 60px;
    outline: none;
    font-size: 16px;
    font-family: Pretendard;
    border: 1px solid #e1e1e1;
    background: url("http://bluetns.co.kr/img/common/arrow_down.png") no-repeat
      94% 50% / 15px auto;
    appearance: none;
  }
  .selectbox_free {
    width: 100%;
    padding: 0px 15px;
    height: 50px;
    outline: none;
    font-size: 16px;
    font-family: Pretendard;
    border: 1px solid #e1e1e1;
    background: url("http://bluetns.co.kr/img/common/arrow_down.png") no-repeat
      94% 50% / 15px auto;
    appearance: none;
  }
  .selectbox_list {
    height: 100px;
  }
  .send_button {
    margin: 25px auto 60px auto;
    width: 140px;
    height: 50px;
    border-radius: 5px;
    line-height: 50px;
    text-align: center;
    background-color: #00177c;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
  }
  .contact_select_product {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .selected {
    width: 31%;
    height: 50px;
    border-radius: 5px;
    background-color: #009fa9;
    line-height: 50px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    border: 1px solid #009fa9;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    cursor: pointer;
  }
  .nonselected {
    width: 31%;
    height: 50px;
    border-radius: 5px;
    background-color: #fff;
    line-height: 50px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    border: 1px solid #e1e1e1;
    letter-spacing: normal;
    text-align: center;
    color: #111111;
    cursor: pointer;
  }
  .contact_promotion {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .contact_promotion2 {
    display: flex;
    align-items: center;
    margin-top: 20px;
    gap: 20px;
  }
  .contact_promotion_box {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #111;
    display: flex;
    align-items: center;
    gap: 12px;
  }
  .cotact_input_flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .input_wrapper2 {
    width: 50%;
  }
  .input_wrapper3 {
    width: 45%;
  }
  .select_box_contact {
    width: 200px;
  }
}
