.file_list {
  background-color: #fff;
}

@media all and (min-width: 1025px) {
  .wrapper_h {
    display: flex;
    width: 100%;
    aspect-ratio: 2.55;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
  }
  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    /* background-repeat: no-repeat; */
    background-position: 50% 0;
    background-size: cover;
    transition: 0.3s;
    opacity: 1;
    animation: fadeInBg 10s linear both;
  }

  .background:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .background_blur {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    /* z-index: -1; */
  }
  .community_area {
    height: 100%;
  }
  .community_title_wrap {
    display: flex;
    flex-direction: column;
    row-gap: 58px;
    align-items: center;
    height: 100%;
    justify-content: center;
  }
  .community_title {
    font-family: Pretendard;
    font-size: 75px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
  .community_subtitle {
    font-family: Pretendard;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .community_category {
    height: 90px;
    line-height: 90px;
    border-bottom: 1px solid #c0c0c0;
    margin-bottom: 59px;
    display: flex;
    justify-content: center;
  }
  .community_category_buttons {
    display: flex;
    width: 1280px;
  }
  .community_button {
    line-height: 89px;
    height: 89px;
    font-size: 19px;
    font-family: Pretendard;
    margin-right: 61px;
    font-weight: bold;
    color: #191919;
  }
  .button_focus {
    border-bottom: 2px solid #00177c;
    color: #00177c;
  }

  .announcementline {
    display: flex;
    justify-content: center;
  }
  .announcementsearchline {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .announcementsearch {
    width: 1280px;
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid #191919;
  }
  .announcementsearchbox {
    width: 337px;
    height: 52px;
    border: 1px solid #e1e1e1;
    padding: 0px 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 36px;
  }
  .announcementsearchinput {
    width: 300px;
    height: 17px;
    font-size: 17px;
    line-height: 17px;
    font-family: Pretendard;
    outline: none;
    border: none;
  }
  .announcement_wrapper {
    margin-bottom: 230px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .announcement_body {
    width: 1280px;
  }
  .event_body {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 75px;
    column-gap: 30px;
  }
  @media all and (min-width: 1025px) and (max-width: 1300px) {
    .eventcard_mg {
      width: 406px;
      margin-left: 40px;
    }
    .event_body {
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 75px;
    }
    .community_category {
      padding-left: 40px;
    }
  }
  .announcement_data {
    display: flex;
    padding-left: 47px;
    padding-right: 49px;
    border-bottom: 1px solid #e1e1e1;
    height: 190px;
    align-items: center;
    justify-content: space-between;
  }
  .announcement_left {
    display: flex;
  }
  .announcement_num {
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Pretendard;
    font-size: 20px;
  }
  .announcement_img {
    margin: 0px 48px;
    width: 139px;
    height: 130px;
  }
  .announcement_th_text {
    height: 92px;
    padding: 19px 0px;
    display: flex;
    flex-direction: column;
    row-gap: 28px;
  }
  .announcement_th_title {
    height: 21px;
    line-height: 21px;
    font-size: 21px;
    font-family: Pretendard;
    font-weight: 600;
    color: #111;
  }
  .announcement_th_contents {
    max-height: 45px;
    overflow: hidden;
    max-width: 700px;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #6f7277;
  }
  .innertext {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .announcement_date {
    font-family: Pretendard;
    font-size: 17px;
  }
  .case_wrapper {
    margin-bottom: 230px;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .case_body {
    width: 1280px;
    text-align: center;
  }
  .case_body {
  }
  .case_title {
    font-family: Pretendard;
    font-size: 64px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;

    letter-spacing: -0.32px;
    color: #191919;
    margin-bottom: 39px;
  }
  .case_subtitle {
    font-family: Pretendard;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #2e2f30;
    margin-bottom: 145px;
  }

  .case_contents {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    row-gap: 29px;
    column-gap: 13px;
  }
  .paging_line {
    margin-top: 95px;
  }

  .detail_wrapper {
    margin-bottom: 230px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .detail_body {
    width: 1280px;
    border-top: 1px solid #191919;
  }

  .detail_title {
    height: 38px;
    font-size: 38px;
    line-height: 38px;
    font-family: Pretendard;
    font-size: 38px;
    font-weight: 600;
    margin: 34px 0px;
    width: 1184px;
    padding-left: 48px;
    padding-right: 48px;
  }
  .detail_sub {
    display: flex;
    padding-bottom: 33px;
    width: 1184px;
    padding-left: 48px;
    padding-right: 48px;
    justify-content: space-between;
    border-bottom: 1px solid #e1e1e1;
    margin-bottom: 29px;
  }

  .detail_files {
    width: 1184px;
    padding-left: 48px;
    padding-right: 48px;
    display: flex;
    justify-content: flex-end;
  }
  .detail_text {
    width: 1184px;
    padding: 60px 48px;
  }
  .detail_ln {
    display: flex;
    justify-content: space-between;
    width: 1184px;
    padding-left: 48px;
    padding-right: 48px;
    padding-top: 30px;
    border-top: 1px solid #e1e1e1;
  }
  .prev,
  .next {
    display: flex;
    height: 19px;
    align-items: center;
    font-family: Pretendard;
    font-size: 19px;
  }
  .detail_ra {
    margin-left: 29px;
  }
  .detail_la {
    margin-right: 29px;
  }
  @media all and (min-width: 1025px) and (max-width: 1300px) {
    .case_subtitle {
      font-family: Pretendard;
      font-size: 26px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: #2e2f30;
      margin-bottom: 80px;
    }

    .case_contents {
      margin-left: 40px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      row-gap: 29px;
      column-gap: 13px;
    }

    .detail_wrapper {
      margin-bottom: 230px;
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .detail_body {
      width: calc(100% - 80px);
      border-top: 1px solid #191919;
    }
    .detail_title {
      height: 38px;
      font-size: 38px;
      line-height: 38px;
      font-family: Pretendard;
      font-size: 38px;
      font-weight: 600;
      margin: 34px 0px;
      width: calc(100% - 168px);
      padding-left: 48px;
      padding-right: 48px;
    }
    .detail_sub {
      display: flex;
      padding-bottom: 33px;
      width: calc(100% - 168px);
      padding-left: 48px;
      padding-right: 48px;
      justify-content: space-between;
      border-bottom: 1px solid #e1e1e1;
      margin-bottom: 29px;
    }

    .detail_files {
      width: calc(100% - 168px);
      padding-left: 48px;
      padding-right: 48px;
      display: flex;
      justify-content: flex-end;
    }
    .detail_text {
      width: calc(100% - 168px);
      padding: 30px 48px;
    }
    .detail_text img {
      max-width: calc(100% - 168px);
    }
    .detail_ln {
      display: flex;
      justify-content: space-between;
      width: calc(100% - 168px);
      padding-left: 48px;
      padding-right: 48px;
      padding-top: 30px;
      border-top: 1px solid #e1e1e1;
    }
    .prev,
    .next {
      display: flex;
      height: 15px;
      align-items: center;
      font-family: Pretendard;
      font-size: 15px;
    }
    .detail_ra {
      margin-left: 20px;
      height: 15px;
    }
    .detail_la {
      margin-right: 20px;
      height: 15px;
    }
  }
  @media all and (min-width: 1025px) and (max-width: 1152px) {
    .case_contents {
      margin-left: 40px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      row-gap: 29px;
      column-gap: 13px;
    }
  }

  .detail_iddt {
    font-family: Pretendard;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #191919;
  }
  .detail_count {
    color: #6f7277;
    padding-left: 7px;
  }

  .move_lr {
  }
  .gl {
    color: #e1e1e1;
    margin-left: 15px;
    margin-right: 15px;
  }

  .tolist {
    margin-top: 46px;
    display: flex;
    justify-content: center;
  }
  .list_button {
    width: 191px;
    height: 60px;
    border-radius: 5px;
    background-color: #00177c;
    text-align: center;
    line-height: 60px;
    font-family: Pretendard;
    font-size: 19px;
    font-weight: 500;
    color: #fff;
  }
  .file_header {
    font-family: Pretendard;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #191919;
    position: relative;
  }
  .file_close {
    text-align: right;
    font-size: 15px;
    padding: 0px 10px 10px 10px;
    display: flex;
    justify-content: space-between;
    font-family: Pretendard;
    font-weight: bold;
    color: #393939;
  }
  .file_closeicon {
    color: #6f7277;
    font-weight: 500;
  }
  .file_list {
    position: absolute;
    width: 170px;
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    padding: 5px 0px 10px 0px;
    top: 30px;
    right: 0px;
  }
  .file_line {
    display: flex;
    height: 20px;
    line-height: 20px;
    justify-content: space-between;
    padding: 0px 10px;
    color: #6f7277;
  }
  .file_line:hover {
    background-color: #f8f8f8;
  }
  .file_line_bg {
    display: flex;
    background-color: black;
  }
  .file_oriNm {
    font-size: 13px;
    font-family: Pretendard;
    width: 125px;
    overflow: hidden;
  }

  .eventcard {
    width: 406px;
  }
  .eventcard_mg {
    width: 406px;
  }
  .eventcard_imgbox {
    height: 250px;
  }
  .eventcard_img {
    width: 406px;
    height: 229px;
    margin-bottom: 20px;
  }
  .eventcard_title {
    font-family: Pretendard;
    font-size: 21px;
    font-weight: 600;
    line-height: 32px;
  }
  .eventcard_date {
    font-family: Pretendard;
    font-size: 17px;
    color: #6f7277;
    margin-top: 40px;
  }
}

/* PC(해상도 1920px ~)*/
@media all and (min-width: 1921px) {
}

/* 테블릿 가로, 테블릿 세로 (해상도 768px ~ 1024px)*/
@media all and (min-width: 768px) and (max-width: 1024px) {
  .wrapper_h {
    display: flex;
    width: 100%;
    height: 630px;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
  }
  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    /* background-repeat: no-repeat; */
    background-position: 50% 0;
    background-size: cover;
    transition: 0.3s;
    opacity: 1;
    animation: fadeInBg 10s linear both;
  }

  .background:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .background_blur {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    /* z-index: -1; */
  }
  .community_area {
    height: 100%;
  }
  .community_title_wrap {
    display: flex;
    flex-direction: column;
    row-gap: 49px;
    align-items: center;
    height: 100%;
    justify-content: center;
  }
  .community_title {
    font-family: Pretendard;
    font-size: 60px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
  .community_subtitle {
    font-family: Pretendard;
    font-size: 19px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .community_category {
    height: 67px;
    line-height: 67px;
    border-bottom: 1px solid #c0c0c0;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
  }
  .community_category_buttons {
    display: flex;
    width: 660px;
  }
  .community_button {
    line-height: 89px;
    height: 89px;
    font-size: 19px;
    font-family: Pretendard;
    margin-right: 61px;
    font-weight: bold;
    color: #191919;
  }
  .button_focus {
    border-bottom: 2px solid #00177c;
    color: #00177c;
  }

  .announcementline {
    display: flex;
    justify-content: center;
  }
  .announcementsearchline {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .announcementsearch {
    width: 660px;
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid #191919;
  }
  .announcementsearchbox {
    width: 337px;
    height: 52px;
    border: 1px solid #e1e1e1;
    padding: 0px 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 36px;
  }
  .announcementsearchinput {
    width: 300px;
    height: 17px;
    font-size: 17px;
    line-height: 17px;
    font-family: Pretendard;
    outline: none;
    border: none;
  }
  .announcement_wrapper {
    margin-bottom: 110px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .announcement_body {
    width: 660px;
  }
  .event_body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 50px;
    column-gap: 100px;
  }
  .announcement_data {
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    border-bottom: 1px solid #e1e1e1;
    height: 190px;
    align-items: center;
    justify-content: space-between;
  }
  .announcement_left {
    display: flex;
  }
  .announcement_num {
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Pretendard;
    font-size: 20px;
  }
  .announcement_img {
    margin: 0px 15px;
    width: 139px;
    height: 130px;
  }
  .announcement_th_text {
    height: 92px;
    padding: 19px 0px;
    display: flex;
    flex-direction: column;
    row-gap: 28px;
  }
  .announcement_th_title {
    height: 21px;
    line-height: 21px;
    font-size: 18px;
    font-family: Pretendard;
    font-weight: 600;
    color: #111;
  }
  .announcement_th_contents {
    max-height: 35px;
    overflow: hidden;
    max-width: 700px;
    font-family: Pretendard;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #6f7277;
  }
  .innertext {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .announcement_date {
    font-family: Pretendard;
    font-size: 15px;
    min-width: 90px;
  }
  .case_wrapper {
    margin-bottom: 230px;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .case_body {
    width: 660px;
    text-align: center;
  }
  .case_body {
  }
  .case_title {
    font-family: Pretendard;
    font-size: 64px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;

    letter-spacing: -0.32px;
    color: #191919;
    margin-bottom: 20px;
  }
  .case_subtitle {
    font-family: Pretendard;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #2e2f30;
    margin-bottom: 50px;
  }
  .case_contents {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 29px;
    column-gap: 13px;
  }
  .case_img {
    width: 155px;
    height: 55px;
  }
  .paging_line {
    margin-top: 30px;
  }

  .detail_wrapper {
    margin-bottom: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .detail_body {
    width: 660px;
    border-top: 1px solid #191919;
  }

  .detail_title {
    height: 38px;
    font-size: 25px;
    line-height: 38px;
    font-family: Pretendard;
    font-weight: 600;
    margin: 25px 0px;
    width: 660px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .detail_sub {
    display: flex;
    padding-bottom: 25px;
    width: 620px;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: space-between;
    border-bottom: 1px solid #e1e1e1;
    margin-bottom: 25px;
  }

  .detail_iddt {
    font-family: Pretendard;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #191919;
  }
  .detail_count {
    color: #6f7277;
    padding-left: 7px;
  }
  .detail_files {
    width: 620px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: flex-end;
  }

  .detail_text {
    width: 620px;
    padding: 30px 20px;
  }

  .detail_text img {
    max-width: 620px;
  }

  .detail_ln {
    display: flex;
    justify-content: space-between;
    width: 620px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
    border-top: 1px solid #e1e1e1;
  }

  .move_lr {
  }
  .gl {
    color: #e1e1e1;
    margin-left: 15px;
    margin-right: 15px;
  }
  .prev,
  .next {
    display: flex;
    height: 13px;
    align-items: center;
    font-family: Pretendard;
    font-size: 13px;
  }

  .detail_ra {
    margin-left: 15px;
    height: 13px;
  }
  .detail_la {
    margin-right: 15px;
    height: 13px;
  }
  .tolist {
    margin-top: 46px;
    display: flex;
    justify-content: center;
  }
  .list_button {
    width: 128px;
    height: 40px;
    border-radius: 3px;
    background-color: #00177c;
    text-align: center;
    line-height: 40px;
    font-family: Pretendard;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
  }
  .file_header {
    font-family: Pretendard;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #191919;
    position: relative;
  }
  .file_close {
    text-align: right;
    font-size: 13px;
    padding: 0px 10px 10px 10px;
    display: flex;
    justify-content: space-between;
    font-family: Pretendard;
    font-weight: bold;
    color: #393939;
  }
  .file_closeicon {
    color: #6f7277;
    font-weight: 500;
  }
  .file_list {
    position: absolute;
    width: 170px;
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    padding: 5px 0px 10px 0px;
    top: 30px;
    right: 0px;
  }
  .file_line {
    display: flex;
    height: 20px;
    line-height: 20px;
    justify-content: space-between;
    padding: 0px 10px;
    color: #6f7277;
  }
  .file_line:hover {
    background-color: #f8f8f8;
  }
  .file_line_bg {
    display: flex;
    background-color: black;
  }
  .file_oriNm {
    font-size: 12px;
    font-family: Pretendard;
    width: 125px;
    overflow: hidden;
  }

  .eventcard {
    width: 280px;
  }
  .eventcard_mg {
    width: 280px;
  }
  .eventcard_imgbox {
    height: 178px;
  }
  .eventcard_img {
    width: 280px;
    height: 158px;
    margin-bottom: 20px;
  }
  .eventcard_title {
    font-family: Pretendard;
    font-size: 19px;
    font-weight: 600;
    line-height: 32px;
  }
  .eventcard_date {
    font-family: Pretendard;
    font-size: 16px;
    color: #6f7277;
    margin-top: 20px;
  }
}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width: 767px) {
  .wrapper_h {
    display: flex;
    width: 100%;
    height: 530px;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
  }
  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    /* background-repeat: no-repeat; */
    background-position: 50% 0;
    background-size: cover;
    transition: 0.3s;
    opacity: 1;
    animation: fadeInBg 10s linear both;
  }

  .background:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .background_blur {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    /* z-index: -1; */
  }
  .community_area {
    height: 100%;
  }
  .community_title_wrap {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    align-items: center;
    height: 100%;
    justify-content: center;
  }
  .community_title {
    font-family: Pretendard;
    font-size: 34px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
  .community_subtitle {
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .community_category {
    height: 67px;
    line-height: 67px;
    border-bottom: 1px solid #c0c0c0;
    margin-bottom: 35px;
    display: flex;
    justify-content: center;
  }
  .community_category_buttons {
    display: flex;
  }
  .community_button {
    line-height: 89px;
    height: 89px;
    font-size: 19px;
    font-family: Pretendard;
    margin-right: 61px;
    font-weight: bold;
    color: #191919;
  }
  .button_focus {
    border-bottom: 2px solid #00177c;
    color: #00177c;
  }

  .announcementline {
    display: flex;
    justify-content: center;
  }
  .announcementsearchline {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .announcementsearch {
    width: calc(100% - 40px);
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid #191919;
  }
  .announcementsearchbox {
    width: 215px;
    height: 45px;
    border: 1px solid #e1e1e1;
    padding: 0px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 36px;
  }
  .searchimg {
    width: 19px;
  }
  .announcementsearchinput {
    width: calc(100% - 20px);
    height: 14px;
    font-size: 14px;
    line-height: 14px;
    font-family: Pretendard;
    outline: none;
    border: none;
  }
  .announcement_wrapper {
    margin-bottom: 110px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .announcement_body {
    width: calc(100% - 40px);
  }
  .event_body {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    align-items: center;
    width: 100%;
  }
  .announcement_data {
    display: flex;
    padding-left: 7px;
    padding-right: 7px;
    border-bottom: 1px solid #e1e1e1;
    height: 114px;
    align-items: center;
    justify-content: space-between;
  }
  .announcement_left {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .announcement_num {
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Pretendard;
    font-size: 11px;
  }
  .announcement_img {
    margin: 0px 10px 0px 8px;
    width: 85px;
    height: 91px;
  }
  .announcement_th_text {
    height: 92px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    justify-content: center;
    width: 100%;
  }
  .announcement_th_title {
    min-height: 42px;
    line-height: 21px;
    font-size: 15px;
    font-family: Pretendard;
    font-weight: 600;
    color: #111;
  }
  .announcement_th_contents {
    max-height: 35px;
    overflow: hidden;
    max-width: 700px;
    font-family: Pretendard;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #6f7277;
  }
  .innertext {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .announcement_date {
    font-family: Pretendard;
    font-size: 15px;
    min-width: 90px;
    display: none;
  }
  .announcement_th_text .announcement_date {
    display: flex;
  }
  .case_wrapper {
    margin-bottom: 230px;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .case_body {
    width: 660px;
    text-align: center;
  }
  .case_body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .case_title {
    font-family: Pretendard;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;

    letter-spacing: -0.32px;
    color: #191919;
    margin-bottom: 15px;
  }
  .case_subtitle {
    font-family: Pretendard;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #2e2f30;
    margin-bottom: 50px;
  }
  .case_contents {
    display: grid;
    width: calc(100% - 40px);
    grid-template-columns: 1fr 1fr;
    gap: 15px 9px;
  }
  .case_img {
    width: 100%;
  }
  .paging_line {
    margin-top: 30px;
  }

  .detail_wrapper {
    margin-bottom: 70px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .detail_body {
    width: calc(100% - 40px);
    border-top: 1px solid #191919;
  }

  .detail_title {
    min-height: 38px;
    font-size: 18px;
    line-height: 25px;
    font-family: Pretendard;
    font-weight: 600;
    margin: 24px 0px;
    width: calc(100% - 16px);
    padding-left: 8px;
    padding-right: 8px;
  }
  .detail_sub {
    display: flex;
    padding-bottom: 25px;
    width: calc(100% - 16px);
    padding-left: 8px;
    padding-right: 8px;
    justify-content: space-between;
    border-bottom: 1px solid #e1e1e1;
    margin-bottom: 25px;
  }

  .detail_iddt {
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #191919;
  }
  .detail_count {
    color: #6f7277;
    padding-left: 7px;
  }
  .detail_files {
    width: calc(100% - 16px);
    padding-left: 8px;
    padding-right: 8px;
    display: flex;
    justify-content: flex-end;
  }

  .detail_text {
    width: 100%;
    padding: 30px 0px;
  }

  .detail_text img {
    max-width: 100%;
  }

  .detail_ln {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 16px);
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 20px;
    border-top: 1px solid #e1e1e1;
  }

  .move_lr {
  }
  .gl {
    color: #e1e1e1;
    margin-left: 15px;
    margin-right: 15px;
  }
  .prev,
  .next {
    display: flex;
    height: 13px;
    align-items: center;
    font-family: Pretendard;
    font-size: 13px;
  }

  .detail_ra {
    margin-left: 15px;
    height: 13px;
  }
  .detail_la {
    margin-right: 15px;
    height: 13px;
  }
  .tolist {
    margin-top: 46px;
    display: flex;
    justify-content: center;
  }
  .list_button {
    width: 128px;
    height: 40px;
    border-radius: 3px;
    background-color: #00177c;
    text-align: center;
    line-height: 40px;
    font-family: Pretendard;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
  }
  .file_header {
    font-family: Pretendard;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #191919;
    position: relative;
  }
  .file_close {
    text-align: right;
    font-size: 13px;
    padding: 0px 10px 10px 10px;
    display: flex;
    justify-content: space-between;
    font-family: Pretendard;
    font-weight: bold;
    color: #393939;
  }
  .file_closeicon {
    color: #6f7277;
    font-weight: 500;
  }
  .file_list {
    position: absolute;
    width: 170px;
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    padding: 5px 0px 10px 0px;
    top: 30px;
    right: 0px;
  }
  .file_line {
    display: flex;
    height: 20px;
    line-height: 20px;
    justify-content: space-between;
    padding: 0px 10px;
    color: #6f7277;
  }
  .file_line:hover {
    background-color: #f8f8f8;
  }
  .file_line_bg {
    display: flex;
    background-color: black;
  }
  .file_oriNm {
    font-size: 12px;
    font-family: Pretendard;
    width: 125px;
    overflow: hidden;
  }
  .event_card_wrapper {
    width: 100%;
  }

  .eventcard {
    width: 100%;
  }
  .eventcard_mg {
    width: 100%;
  }
  .eventcard_imgbox {
    width: 100%;
  }
  .eventcard_img {
    width: 100%;
    aspect-ratio: 1.88;
    margin-bottom: 20px;
  }
  .eventcard_title {
    font-family: Pretendard;
    font-size: 18px;
    font-weight: 600;
    line-height: 32px;
  }
  .eventcard_date {
    font-family: Pretendard;
    font-size: 15px;
    color: #6f7277;
    margin-top: 20px;
  }
}
