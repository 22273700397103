.sidebar_wrap {
  min-width: 253px;
  overflow-x: hidden;
  overflow-y: auto;
  transition-duration: 0.1s;
  transition-property: min-width width;
  transition-timing-function: linear;
  width: 253px;
}

.sidebar {
  /* border: 1px solid black; */
  /* width: 250px; */
  width: 100%;
  min-width: 250px;
  min-height: 100%;
  /* padding: 35px 25px 0 0; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.sidebar * {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.sidebar.bg_white {
  background-color: #fff;
}

.menu_btn {
  padding: 10px 20px;
  width: 100%;
  border-radius: 0 5px 5px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: 0.2s all ease-in-out;
  margin-bottom: 10px;
  font-family: Pretendard;
}

.menu_btn span {
  margin-left: 15px;
  font-size: 15px;
}

.menu_btn:hover {
  background-color: #e4eeff;
  cursor: pointer;
}

.dropdown_icon {
  height: 20px;
  width: 20px;
  /* border: 1px solid blue; */
  justify-content: end;
  margin-left: 50px;
}

.dropdown_icon img {
  height: 100%;
  width: 100%;
}

.menu_dropdown.close {
  display: none;
}

.menu_dropdown li {
  /* margin: 10px 0; */
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  border-radius: 0 5px 5px 0;
  padding-left: 10px;
}

.menu_dropdown li:last-of-type {
  margin-bottom: 15px;
}

.menu_dropdown li:hover {
  background-color: #e4eeff;
  cursor: pointer;
}

.menu_dropdown .nav_link {
  color: #000;
  padding-left: 50px;
  text-decoration: none;
  transition: 0.2s all ease-in-out;
  font-size: 15px;
}

.nav_link .dot {
  background-color: #111;
  border-radius: 10px;
  display: inline;
  width: 6px;
  height: 6px;
}

.menu_btn.active,
.nav_link.active {
  background-color: #e4eeff;
}

.menu_btn.active,
.nav_link.active {
  background-color: #e4eeff;
}
