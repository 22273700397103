@media all and (max-width: 1024px) {
  .menu_wrapper {
    width: 100%;
    display: flex;
    height: 100%;
    justify-content: center;
    z-index: 5;
  }

  .menu_box {
    width: calc(100% - 40px);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid #001572;
    font-family: Pretendard;
    max-width: 660px;
  }

  .menu_selected {
    width: 100%;
    text-align: center;
    height: 100%;
    min-height: 100%;
    line-height: 68px;
    font-size: 18px;
    font-weight: 600;
    color: #001572;
    display: flex;
    justify-content: space-between;
  }

  .zw_menu_list {
    width: 100%;
    text-align: center;
    background-color: white;
    height: 200px;
    min-height: 200px;
    transition: all 0.3s ease-in-out;
    position: relative;
    top: 2px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.16);
  }

  .menu_list {
    width: 100%;
    text-align: center;
    background-color: white;
    height: 50px;
    min-height: 50px;
    transition: all 0.3s ease-in-out;
    position: relative;
    top: 2px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.16);
  }

  .menu_list_off {
    width: 100%;
    height: 0px;
    overflow: hidden;
    min-height: 0px;
    transition: all 0.3s ease-in-out;
    background-color: #fff;
  }

  .menu_select {
    width: 100%;
    text-align: center;
    height: 50px;
    line-height: 50px;
    font-family: Pretendard;
    font-size: 16px;
    border-bottom: 1px solid #e1e1e1;
    background-color: #fff;
    transform: translatey(0);
    transition-property: transform;
    transition-duration: 0.3s;
  }

  .menu_select_off {
    width: 100%;
    text-align: center;
    height: 50px;
    line-height: 50px;
    font-family: Pretendard;
    font-size: 16px;
    border-bottom: 1px solid #e1e1e1;
    background-color: #fff;
    transform: translatey(-100%);
    transition-property: transform;
    transition-duration: 0.3s;
  }

  .arrow_down {
    padding-right: 18px;
    transition: 0.1s;
    padding-left: 18px;
    transform: rotate(180deg);
  }

  .arrow_up {
    padding-right: 18px;
    padding-left: 18px;

    transition: 0.1s;
  }
}
