.wrapper {
  height: calc(100vh - 80px);
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}
.header {
  width: 1400px;
  border-bottom: 3px solid black;
  height: 60px;
  line-height: 60px;
  font-size: 25px;
  font-family: Pretendard;
  font-weight: bold;
}
.contents {
  display: flex;
  min-height: 20px;
  padding: 10px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  font-family: Pretendard;
}
.contents_header {
  width: 150px;
  font-family: Pretendard;
  font-size: 16px;
  line-height: 25px;
}
.contents_body {
  width: 1000px;
  font-family: Pretendard;
  line-height: 25px;
}
.contents_file {
  display: flex;
}
.contents_files {
  width: 600px;
  min-height: 25px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.files_btn {
  font-family: Pretendard;
}
.file_list_single {
  padding: 0px 15px;
  max-height: 25px;
}
.file_list {
  padding: 0px 15px;
  height: 50px;
  overflow-y: auto;
}
.file_list_none {
  padding: 0px 15px;
  max-height: 60px;
  overflow-y: auto;
  color: #6f7277;
  font-size: 15px;
}

.files_line {
  display: flex;
  align-items: center;
  font-family: Pretendard;
}
.files_name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.file_remove :hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.file_remove {
  width: 20px;
  height: 20px;
}
.contents_files_annotation {
  font-size: 12px;
  height: 14px;
  line-height: 14px;
  margin-top: 8px;
}

.contents_title {
  height: 25px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0px 15px;
}
.contents_title_input {
  height: 23px;
  border: none;
  outline: none;
  padding: 0px;
  font-family: Pretendard;
  font-size: 15px;
  width: 100%;
}

.contents_text_annotation {
  font-size: 12px;
  height: 14px;
  line-height: 14px;
  margin-bottom: 8px;
}

/*마지막*/
.buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.board_list_wrapper {
  width: 1280px;
  margin: 30px auto 0px auto;
}
.board_list_title {
  display: flex;
  justify-content: space-between;
  height: 60px;
  line-height: 60px;
  font-size: 25px;
  align-items: center;
  margin-bottom: 40px;
  font-family: Pretendard;
  font-weight: bold;
}
.board_list_input {
  height: 40px;
  width: 80px;
  line-height: 40px;
  background-color: #00177c;
  font-size: 15px;
  border-radius: 8px;
  color: #ffffff;
  text-align: center;
  font-weight: 500;
}
.board_list_header {
  display: flex;
  border-bottom: 1px solid #e1e1e1;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  font-family: Pretendard;
  font-weight: bold;
}
.board_num {
  width: 10%;
}
.board_title {
  width: 30%;
}
.board_writer {
  width: 10%;
}
.board_date {
  width: 20%;
}

.board_del {
  width: 10%;
}

.board_list_body {
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
}
.board_list_body_line {
  display: flex;
  text-align: center;
  font-size: 13px;
  font-family: Pretendard;
  height: 20px;
  padding: 10px 0px;
  cursor: pointer;
  border-bottom: 1px solid #e1e1e1;
  align-items: center;
}
.board_list_body_line:hover {
  background-color: #f8f8f8;
}

.board_submit {
  height: 40px;
  width: 80px;
  line-height: 40px;
  background-color: #00177c;
  font-size: 15px;
  border-radius: 8px;
  color: #ffffff;
  text-align: center;
  font-weight: 500;
  font-family: Pretendard;
}
.board_cancel {
  margin-right: 20px;
  height: 40px;
  width: 80px;
  line-height: 40px;
  background-color: #7c0000;
  font-size: 15px;
  border-radius: 8px;
  color: #ffffff;
  text-align: center;
  font-weight: 500;
  font-family: Pretendard;
}

.aupmodal {
  width: 310px;
  height: 290px;
  margin: 15% auto;
  padding: 20px 20px 20px 20px;
  border-radius: 20px;
  background-color: #fff;
  outline: none;
}
.aup_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1001;
}
.aup_title {
  height: 24px;
  font-family: Pretendard;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #111;
  line-height: 24px;
}

.aup_footer {
  display: flex;
}
.aup_input_box {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}
.aup_body {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.aup_input_header {
  height: 15px;
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #111;
  line-height: 15px;
}
.aup_input {
  width: 300px;
  height: 25px;
  outline: none;
  font-family: Pretendard;
  font-size: 15px;
  border: 1px solid #e1e1e1;
}
.aup_footer {
  display: flex;
  justify-content: center;
  column-gap: 40px;
}
.aup_close,
.aup_submit {
  width: 82px;
  height: 34px;
  line-height: 34px;

  border-radius: 8px;
  text-align: center;
  color: #fff;
  font-family: Pretendard;
}
.aup_close {
  background-color: #7c0000;
}
.aup_submit {
  background-color: #00177c;
}

.request_wrapper {
  width: 1280px;
  margin: 30px auto 0px auto;
}
.request_body {
  width: 1280px;
}
.request_list_title {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  height: 60px;
  line-height: 60px;
  font-size: 25px;
  align-items: center;
  margin-bottom: 40px;
  font-family: Pretendard;
  font-weight: bold;
}
/* fre e*/
.free_list_header {
  display: flex;
  border-bottom: 1px solid #e1e1e1;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  font-family: Pretendard;
  font-weight: bold;
}
.free_item {
  width: 9%;
}
.free_version {
  width: 9%;
}
.free_user {
  width: 9%;
}
.free_phone {
  width: 9%;
}
.free_company {
  width: 11%;
}
.free_mail {
  width: 17%;
}
.free_loc1 {
  width: 17%;
}
.free_dt {
  width: 8%;
}
.free_state {
  width: 11%;
}
.r5 {
  width: 5%;
}
.r6 {
  width: 6%;
}
.r7 {
  width: 7%;
}
.r8 {
  width: 8%;
}
.r9 {
  width: 9%;
}
.r10 {
  width: 10%;
}
.r11 {
  width: 11%;
}
.r12 {
  width: 12%;
}
.r13 {
  width: 13%;
}
.r14 {
  width: 14%;
}
.r15 {
  width: 15%;
}
.r16 {
  width: 16%;
}
.r17 {
  width: 17%;
}
.r18 {
  width: 18%;
}
.r19 {
  width: 19%;
}
.r20 {
  width: 20%;
}
.r25 {
  width: 25%;
}
.r30 {
  width: 30%;
}

.free_list_body_line {
  display: flex;
  text-align: center;
  font-size: 13px;
  font-family: Pretendard;
  height: 30px;
  padding: 10px 0px;
  border-bottom: 1px solid #e1e1e1;
  align-items: center;
}

/*  ////////////// */

.request_state {
  display: flex;
  justify-content: center;
}
.state_click {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.state_list {
  position: relative;
  top: 20px;
  right: 25px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.state_selects_text {
  font-family: Pretendard;
  margin-left: 10px;
}
.state_list_off {
  display: none;
}
.state_list_body {
  position: absolute;
  z-index: 1000;
  height: 40px;
  top: -30px;
  width: 100px;
  left: 35px;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  justify-content: center;
  border: 1px solid #e1e1e1;
  border-radius: 6px;
  align-items: center;
}
.state_selects {
  display: flex;
  width: 80px;
  align-items: center;
}
.state_selects:hover {
  background-color: #f8f8f8;
}

.state_red {
  width: 10px;
  height: 10px;
  border-radius: 15px;
  background-color: red;
}
.state_grey {
  width: 10px;
  height: 10px;
  border-radius: 15px;
  background-color: grey;
}
.state_green {
  width: 10px;
  height: 10px;
  border-radius: 15px;
  background-color: green;
}

.data_open {
  padding: 20px;
  min-height: 200px;
  border-bottom: 1px solid #e1e1e1;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  font-family: Pretendard;
}
.data_close {
  padding: 0px 20px;
  min-height: 0px;
  height: 0px;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  font-family: Pretendard;
}
.onsite_list_line {
  cursor: pointer;
}
.onsite_list_line:hover {
  background-color: #f8f8f8;
}
.request_body1 {
  display: flex;
}

.request_left {
  width: 700px;
  display: flex;
}
.request_right {
  width: 580px;
}

.detail_box {
  display: flex;
  width: 350px;
  height: 30px;
}
.detail_box2 {
  display: flex;
  width: 580px;
  height: 30px;
}
.detail_box_header {
  width: 100px;
  height: 30px;
  line-height: 30px;
  font-size: 15px;
  font-weight: bold;
}
.detail_box_data {
  width: 200px;
  height: 29px;
  line-height: 30px;
  font-size: 15px;
  border-bottom: 1px solid #e1e1e1;
}
.detail_box_data2 {
  width: 400px;
  height: 29px;
  line-height: 30px;
  font-size: 15px;
  border-bottom: 1px solid #e1e1e1;
}

.detail_box_contents {
  font-size: 15px;
}
.detail_box3 {
  display: flex;
}
.detail_box3_header {
  width: 100px;
  font-size: 15px;
  font-weight: bold;
}
.detail_box3_contents {
  width: 1100px;
}

.filemodal {
  width: 400px;
  height: 180px;
  margin: 15% auto;
  padding: 20px 20px 20px 20px;
  border-radius: 20px;
  background-color: #fff;
  outline: none;
}
.file_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1001;
}

.product_file {
  display: flex;
}
.product_url {
  display: flex;
}
.product_line {
  width: 250px;
  border: 1px solid black;
  height: 25px;

  font-family: Pretendard;
  font-size: 15px;
  line-height: 25px;
  padding: 0px 10px;
  margin-right: 10px;
}
.product_list_none {
  width: 250px;
  border: 1px solid black;
  height: 25px;

  font-family: Pretendard;
  font-size: 15px;
  line-height: 25px;
  padding: 0px 10px;
  margin-right: 10px;
}
.product_btn {
  font-family: Pretendard;
  height: 25px;
  line-height: 25px;
}

.product_url_input {
  outline: none;
  border: 1px solid black;
  height: 25px;
  width: 320px;
  padding: 0px 10px;
}
.product_header {
  font-family: Pretendard;
  height: 25px;
  line-height: 25px;
  width: 50px;
}
.product_body {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.product_title {
  height: 24px;
  font-family: Pretendard;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #111;
  line-height: 24px;
  display: flex;
}
.product_radio {
  font-size: 15px;
  margin-left: 10px;
}

.popup_sub_title {
  font-size: 20px;
  font-family: Pretendard;
  margin-bottom: 20px;
  font-weight: bold;
}

.popupmodal {
  width: 420px;
  height: 380px;
  margin: 10% auto;
  padding: 30px 30px 30px 30px;
  border-radius: 20px;
  background-color: #fff;
  outline: none;
  font-family: Pretendard;
}
.popupmodal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1001;
}
.pm_title {
  font-size: 24px;
  font-weight: bold;
}
.pm_body {
  margin-top: 20px;
}
.pm_body {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.pm_line {
  display: flex;
  height: 30px;
  align-items: center;
}

.pm_header {
  font-size: 17px;
  width: 85px;
  text-align: right;
  margin-right: 10px;
}
.pop_img {
  display: flex;
  align-items: center;
}
.contents_file_pop {
  width: 200px;
  min-height: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-right: 10px;
}
.file_pop_none {
  padding: 0px 5px;
  height: 100%;
  overflow-y: auto;
  color: #6f7277;
  font-size: 15px;
  line-height: 20px;
}
.img_name {
  width: 150px;
  overflow: hidden;
  max-height: 20px;
}

.pop_input {
  outline: none;
  border: none;
  border-bottom: 2px solid #e1e1e1;
  padding: 5px 0px;
  height: 18px;
  font-size: 15px;
  width: 300px;
  cursor: pointer;
  font-family: Pretendard;
}
