.bar_hidden {
  display: none;
}

@media all and (min-width: 1301px) {
  .bar {
    display: flex;
    width: 100%;
    height: 115px;
    background-color: #111111;
    justify-content: center;
    align-items: center;
    z-index: 0;
    transition: all 0.3s ease-in-out;
  }

  .bar_active {
    display: flex;
    position: fixed;
    width: 100%;
    height: 115px;
    background-color: #111111;
    justify-content: center;
    align-items: center;
    z-index: 0;
    bottom: 0;
    transition: all 0.3s ease-in-out;
  }

  .bar_contents {
    width: 1280px;
    display: flex;
    justify-content: space-between;
  }

  .bar_left,
  .bar_right {
    display: flex;
  }

  .bar_c1 {
    font-size: 26px;
    color: #ffffff;
    font-family: Pretendard;
    font-weight: 600;
    line-height: 49px;
    margin-right: 60px;
  }

  .bar_c2 {
    padding: 0px 24px;
    height: 49px;
    border-radius: 24px;
    border: solid 1px #fff;
    color: #ffffff;
    text-align: center;
    line-height: 49px;
    display: flex;
    align-items: center;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: 500;
  }

  .bar_c3 {
    width: 152px;
    height: 53px;
    border-radius: 5px;
    background-color: #fff;
    text-align: center;
    line-height: 53px;
    font-family: Pretendard;
    font-size: 17px;
    color: #050b26;
  }

  .bar_c4 {
    width: 152px;
    height: 53px;
    border-radius: 5px;
    background-color: #009fa9;
    text-align: center;
    line-height: 53px;
    font-family: Pretendard;
    font-size: 17px;
    color: #fff;
    margin-left: 21px;
  }

  .bar_c5 {
    padding: 0px 24px;
    height: 49px;
    border-radius: 24px;
    border: solid 1px #fff;
    color: #ffffff;
    text-align: center;
    line-height: 49px;
    margin-left: 21px;
    display: flex;
    align-items: center;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: 500;
  }
  .download_img {
    margin-left: 20px;
    /* height: 19px; */
  }
}
@media all and (min-width: 1025px) and (max-width: 1300px) {
  .bar {
    display: flex;
    width: 100%;
    height: 115px;
    background-color: #111111;
    justify-content: center;
    align-items: center;
    z-index: 0;
    transition: all 0.3s ease-in-out;
  }

  .bar_active {
    display: flex;
    position: fixed;
    width: 100%;
    height: 115px;
    background-color: #111111;
    justify-content: center;
    align-items: center;
    z-index: 0;
    bottom: 0;
    transition: all 0.3s ease-in-out;
  }

  .bar_contents {
    width: calc(100% - 80px);
    display: flex;
    justify-content: space-between;
  }

  .bar_left,
  .bar_right {
    display: flex;
  }

  .bar_c1 {
    font-size: 26px;
    color: #ffffff;
    font-family: Pretendard;
    font-weight: 600;
    line-height: 49px;
    margin-right: 60px;
  }

  .bar_c2 {
    padding: 0px 24px;
    height: 49px;
    border-radius: 24px;
    border: solid 1px #fff;
    color: #ffffff;
    text-align: center;
    line-height: 49px;
    display: flex;
    align-items: center;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: 500;
  }

  .bar_c3 {
    width: 152px;
    height: 53px;
    border-radius: 5px;
    background-color: #fff;
    text-align: center;
    line-height: 53px;
    font-family: Pretendard;
    font-size: 17px;
    color: #050b26;
  }

  .bar_c4 {
    width: 152px;
    height: 53px;
    border-radius: 5px;
    background-color: #009fa9;
    text-align: center;
    line-height: 53px;
    font-family: Pretendard;
    font-size: 17px;
    color: #fff;
    margin-left: 21px;
  }

  .bar_c5 {
    padding: 0px 24px;
    height: 49px;
    border-radius: 24px;
    border: solid 1px #fff;
    color: #ffffff;
    text-align: center;
    line-height: 49px;
    margin-left: 21px;
    display: flex;
    align-items: center;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: 500;
  }
  .download_img {
    margin-left: 20px;
    /* height: 19px; */
  }
}

@media all and (max-width: 1024px) {
  .bar {
    display: flex;
    width: 100%;
    height: 68px;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    z-index: 0;
    transition: all 0.3s ease-in-out;
  }

  .bar_active {
    display: flex;
    position: fixed;
    width: 100%;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    z-index: 0;
    bottom: 0;
    min-height: 68px;
    transition: all 0.3s ease-in-out;
  }

  .mobilebar_active {
    display: flex;
    position: fixed;
    width: 100%;
    background-color: #fff;
    justify-content: center;
    z-index: 0;
    bottom: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    z-index: 1002;
    min-height: 220px;
    transition: all 0.1s ease-in-out;
  }

  .bar_button {
    width: calc(100% - 40px);
    height: 50px;
    font-size: 15px;
    font-family: Pretendard;
    text-align: center;
    background-color: #00177c;
    color: #fff;
    border-radius: 3px;
    line-height: 50px;
  }
  .mobilebar_body {
    width: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    padding-top: 8px;
    align-items: center;
  }

  .blur {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.48);
    z-index: 1001;
  }
  .title {
    width: 100%;
    font-family: Pretendard;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #00177c;
    margin-bottom: 24px;
  }
  .close {
    width: 45px;
    height: 10px;
    border-radius: 10px;
    background-color: #ddd;
    margin-bottom: 15px;
  }
  .buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 9px;
  }
  .freebtn {
    width: 165px;
    height: 50px;
    border-radius: 3px;
    line-height: 50px;
    text-align: center;
    background-color: #00177c;
    cursor: pointer;
    color: #fff;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
  }
  .requestbtn {
    width: 165px;
    height: 50px;
    border-radius: 3px;
    line-height: 50px;
    text-align: center;
    background-color: #009fa9;
    cursor: pointer;
    color: #fff;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
  }
  .downlinxbox {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 13px;
    margin-bottom: 18px;
  }
  .downlink {
    width: calc(100% - 45px);
    border: solid 1px #c0c0c0;
    border-radius: 3px;
    height: 50px;
    padding-left: 21px;
    padding-right: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Pretendard;
    font-size: 16px;
  }
}
