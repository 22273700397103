@media all and (min-width: 1025px) {
  .wrapper {
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    min-height: 900px;
  }

  .wrapper_cometo {
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    min-height: 900px;
  }

  .wrapper_org {
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    min-height: 900px;
  }

  .wrapper_h {
    display: flex;
    width: 100%;
    height: 77vh;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
  }

  .background {
    position: absolute;
    width: 100%;
    height: 80vh;
    z-index: -1;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    /* transform: scale(1.1) rotate(-0.01deg); */
    transition: 0.3s;
    opacity: 1;
    animation: fadeInBg 10s linear both;
  }

  .background_org {
    position: absolute;
    width: 100%;
    height: 100vh;
    min-height: 700px;
    z-index: -1;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    /* transform: scale(1.1) rotate(-0.01deg); */
    transition: 0.3s;
    opacity: 1;
    animation: fadeInBg 10s linear both;
  }

  .background:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 80vh;
  }

  .background_blur {
    display: flex;
    width: 100%;
    height: 80vh;
    background-color: rgba(0, 0, 0, 0.4);
    /* z-index: -1; */
  }

  .background_blur2 {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* z-index: -1; */
  }

  .background_blur3 {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.15);
    /* z-index: -1; */
  }

  .company_area {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 80vh;
    min-height: 700px;
  }

  .company_org_area {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    min-height: 700px;
  }

  .company_title_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80vh;
    gap: 49px;
  }

  .company_org_title_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 700px;
    gap: 49px;
  }

  .company_title {
    height: 72px;
    font-family: Pretendard;
    font-size: 75px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 72px;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .company_subtitle {
    height: 20px;
    font-family: Pretendard;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .introduce {
    margin-top: 18vh;
    height: 77vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 5vh;
    min-height: 703px;
  }

  .introduce_header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 92px;
  }

  .introduce_title {
    font-size: 40px;
    font-family: Pretendard;
    font-weight: 600;
    height: 40px;
    line-height: 40px;
  }

  .introduce_subtitle {
    font-size: 43px;
    font-family: Pretendard;
    font-weight: 600;
    height: 43px;
    line-height: 43px;
    margin-top: 24px;
  }

  .introduce_body {
    display: flex;
    justify-content: space-between;
    max-width: 1280px;
    width: 66%;
  }

  .introduce_bgimg {
    /* width: 670px; */
    width: 52%;
    height: 503px;
    text-align: center;
    line-height: 559px;
  }

  .company_introduce_logo_on {
    transform: translateY(-510px);
    filter: blur(1);
    -webkit-filter: blur(0);
    opacity: 1;
    transition: transform 1s, opacity 1s, filter 1s;
    transition-delay: 0.1s;
    width: 46%;
    min-width: 212px;
    max-width: 308px;
  }

  .company_introduce_logo {
    transform: translateY(-300px);
    filter: blur(10px);
    -webkit-filter: blur(10px);
    opacity: 0;
  }

  .introduce_body_text {
    width: 515px;
    margin-left: 20px;
    display: flex;
    row-gap: 36px;
    flex-direction: column;
    justify-content: center;
  }

  .introduce_body_header {
    width: 103px;
    height: 36px;
    line-height: 36px;
    background-color: #001572;
    border-radius: 18px;
    font-size: 16px;
    font-family: Poppins;
    color: #fff;
    text-align: center;
  }

  .introduce_text {
    font-size: 16px;
    font-family: Pretendard;
    letter-spacing: normal;
    font-weight: normal;
    color: #2e2f30;
    line-height: 22px;
    word-break: keep-all;
  }

  .introduce_footer {
    margin-top: 16px;
    font-size: 24px;
    font-family: Pretendard;
    font-weight: 500;
    color: #2e2f30;
  }

  @media all and (min-width: 1025px) and (max-width: 1345px) {
    .introduce_text {
      font-size: 15px;
      font-family: Pretendard;
      letter-spacing: normal;
      font-weight: normal;
      color: #2e2f30;
      line-height: 22px;
      white-space: keep-all;
    }

    .introduce_body_text {
      width: 400px;
      margin-left: 20px;
      display: flex;
      row-gap: 20px;
      flex-direction: column;
      justify-content: center;
    }

    .introduce_footer {
      margin-top: 16px;
      font-size: 22px;
      font-family: Pretendard;
      font-weight: 500;
      color: #2e2f30;
    }
  }

  /* 여기 */

  .organization {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .organization_title {
    font-family: Pretendard;
    font-size: 64px;
    font-weight: bold;
    color: #fff;
  }

  .organization_subtitle {
    width: 257px;
    height: 36px;
    border-radius: 17px;
    background-color: #00177c;
    line-height: 36px;
    font-size: 16px;
    font-family: Poppins;
    color: #fff;
    text-align: center;
    margin-top: 34px;
    margin-bottom: 72px;
  }

  .organization_action {
    display: flex;
    flex-direction: column;
    align-items: center;

    transform: translateY(80px);
    filter: blur(10px);
    -webkit-filter: blur(10px);
    opacity: 0;
  }

  .organization_action_on {
    display: flex;
    flex-direction: column;
    align-items: center;

    transform: translateY(0);
    filter: blur(1);
    -webkit-filter: blur(0);
    opacity: 1;
    transition: transform 1s, opacity 1s, filter 1s;
    transition-delay: 0.1s;
  }

  .org_box_big {
    width: 228px;
    height: 58px;
    border: 1px solid #fff;
    text-align: center;
    line-height: 58px;
    background-color: rgba(255, 255, 255, 0.1);
  }

  .org_box_small {
    width: 253px;
    height: 58px;
    border: 1px solid #fff;
    text-align: center;
    line-height: 58px;
    background-color: rgba(255, 255, 255, 0.1);
  }

  .org_box_big_font {
    font-family: Pretendard;
    font-size: 23px;
    font-weight: 600;
    color: #fff;
    letter-spacing: normal;

    transform: translateY(80px);
    filter: blur(10px);
    -webkit-filter: blur(10px);
    opacity: 0;
  }

  .org_box_big_font_on {
    font-family: Pretendard;
    font-size: 23px;
    font-weight: 600;
    color: #fff;
    letter-spacing: normal;

    transform: translateY(0);
    filter: blur(1);
    -webkit-filter: blur(0);
    opacity: 1;
    transition: transform 1.3s, opacity 1.3s, filter 1.3s;
    transition-delay: 0.2s;
  }

  .org_box_small_font {
    font-family: Pretendard;
    font-size: 21px;
    font-weight: 600;
    color: #fff;
    letter-spacing: normal;

    transform: translateY(80px);
    filter: blur(10px);
    -webkit-filter: blur(10px);
    opacity: 0;
  }

  .org_box_small_font_on {
    font-family: Pretendard;
    font-size: 21px;
    font-weight: 600;
    color: #fff;
    letter-spacing: normal;

    transform: translateY(0);
    filter: blur(1);
    -webkit-filter: blur(0);
    opacity: 1;
    transition: transform 1.3s, opacity 1.3s, filter 1.3s;
    transition-delay: 0.5s;
  }

  .org_line_h {
    width: 1px;
    height: 55px;
    background-color: #fff;
  }

  .org_line_h2 {
    width: 1px;
    height: 25px;
    background-color: #fff;
  }

  .org_line2 {
    display: flex;
    align-items: center;
  }

  .org_line_w {
    height: 1px;
    width: 222px;
    background-color: white;
  }

  .org_line2_1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .org_line2_c {
    width: 11px;
    height: 11px;
    border-radius: 11px;
    background-color: white;
  }

  .org_line3 {
    display: flex;
  }

  .org_line_h3 {
    width: 1px;
    height: 37px;
    background-color: white;
  }

  .org_line_w3 {
    height: 1px;
    width: 341px;
    background-color: white;
  }

  .org_line4 {
    display: flex;
    justify-content: space-between;
    width: 1282px;
  }

  /* .org_line4_h {
    margin: 0px 43px;
  } */
  .org_line_h4 {
    width: 1px;
    height: 19px;
    background-color: white;
  }

  .org_line5 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .cometo {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .comto_header {
    width: 1280px;
    display: flex;
  }

  .cometo_title {
    width: 530px;
  }

  .cometo_mark {
    width: 87px;
    height: 14px;
    margin-bottom: 34px;
  }

  .cometo_title_text {
    font-size: 43px;
    line-height: 43px;
    font-family: Pretendard;
    font-weight: 500;
  }

  .cometo_blue {
    color: #00177c;
    font-weight: 600;
  }

  .comto_black {
    margin-top: 20px;
  }

  .cometo_line {
    width: 1px;
    height: 198px;
    background-color: #e1e1e1;
  }

  .cometo_info {
    margin-left: 110px;
    display: flex;
    flex-direction: column;
  }

  .cometo_info1 {
    display: flex;
    margin-bottom: 38px;
  }

  .cometo_address {
    margin-right: 45px;
  }

  .cometo_info_header {
    display: flex;
    height: 20px;
    align-items: center;
  }

  .cometo_info_header_img {
    width: 20px;
    height: 20px;
  }

  .cometo_info_header_text {
    font-size: 19px;
    font-weight: 600;
    font-family: Pretendard;
    margin-left: 11px;
  }

  .cometo_info_body {
    font-size: 16px;
    color: #6f7277;
    margin-top: 11px;
    margin-left: 30px;
    font-family: Pretendard;
  }

  .cometo_subway_img {
    display: flex;
    height: 25px;
    margin-top: 11px;
    margin-left: 30px;
    font-family: Pretendard;
    font-size: 19px;
    font-weight: 600;
    align-items: center;
  }

  .cometo_subway_img_m {
    margin-left: 5px;
    margin-right: 14px;
  }

  .cometo_info_body2 {
    font-size: 16px;
    color: #6f7277;
    margin-top: 11px;
    margin-left: 30px;
    font-family: Pretendard;
    height: 16px;
    line-height: 16px;
  }

  .cometo_body {
    width: 1280px;
    height: 430px;
    margin-top: 78px;
  }

  .company_map {
    width: 1280px;
    height: 428px;
  }

  .company_mapunder {
    width: 1258px;
  }

  .company_map_wrapper {
    width: 1280px;
  }

  @media all and (min-width: 1025px) and (max-width: 1345px) {
    .organization_title {
      font-family: Pretendard;
      font-size: 45px;
      font-weight: bold;
      color: #fff;
    }

    .organization_subtitle {
      width: 220px;
      height: 36px;
      border-radius: 17px;
      background-color: #00177c;
      line-height: 36px;
      font-size: 15px;
      font-family: Poppins;
      color: #fff;
      text-align: center;
      margin-top: 34px;
      margin-bottom: 72px;
    }

    .organization_action {
      display: flex;
      flex-direction: column;
      align-items: center;

      transform: translateY(80px);
      filter: blur(10px);
      -webkit-filter: blur(10px);
      opacity: 0;
    }

    .organization_action_on {
      display: flex;
      flex-direction: column;
      align-items: center;

      transform: translateY(0);
      filter: blur(1);
      -webkit-filter: blur(0);
      opacity: 1;
      transition: transform 1s, opacity 1s, filter 1s;
      transition-delay: 0.1s;
    }

    .org_box_big {
      width: 150px;
      height: 40px;
      border: 1px solid #fff;
      text-align: center;
      line-height: 40px;
      background-color: rgba(255, 255, 255, 0.1);
    }

    .org_box_small {
      width: 130px;
      height: 40px;
      border: 1px solid #fff;
      text-align: center;
      line-height: 40px;
      background-color: rgba(255, 255, 255, 0.1);
    }

    .org_box_big_font {
      font-family: Pretendard;
      font-size: 18px;
      font-weight: 600;
      color: #fff;
      letter-spacing: normal;

      transform: translateY(80px);
      filter: blur(10px);
      -webkit-filter: blur(10px);
      opacity: 0;
    }

    .org_box_big_font_on {
      font-family: Pretendard;
      font-size: 18px;
      font-weight: 600;
      color: #fff;
      letter-spacing: normal;

      transform: translateY(0);
      filter: blur(1);
      -webkit-filter: blur(0);
      opacity: 1;
      transition: transform 1.3s, opacity 1.3s, filter 1.3s;
      transition-delay: 0.2s;
    }

    .org_box_small_font {
      font-family: Pretendard;
      font-size: 15px;
      font-weight: 600;
      color: #fff;
      letter-spacing: normal;

      transform: translateY(80px);
      filter: blur(10px);
      -webkit-filter: blur(10px);
      opacity: 0;
    }

    .org_box_small_font_on {
      font-family: Pretendard;
      font-size: 15px;
      font-weight: 600;
      color: #fff;
      letter-spacing: normal;

      transform: translateY(0);
      filter: blur(1);
      -webkit-filter: blur(0);
      opacity: 1;
      transition: transform 1.3s, opacity 1.3s, filter 1.3s;
      transition-delay: 0.5s;
    }

    .org_line_h {
      width: 1px;
      height: 20px;
      background-color: #fff;
    }

    .org_line_h2 {
      width: 1px;
      height: 25px;
      background-color: #fff;
    }

    .org_line2 {
      display: flex;
      align-items: center;
    }

    .org_line_w {
      height: 1px;
      width: 150px;
      background-color: white;
    }

    .org_line2_1 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .org_line2_c {
      width: 8px;
      height: 8px;
      border-radius: 8px;
      background-color: white;
    }

    .org_line3 {
      display: flex;
    }

    .org_line_h3 {
      width: 1px;
      height: 20px;
      background-color: white;
    }

    .org_line_w3 {
      height: 1px;
      width: 250px;
      background-color: white;
    }

    .org_line4 {
      display: flex;
      justify-content: space-between;
      width: 886px;
    }

    /* .org_line4_h {
    margin: 0px 43px;
  } */
    .org_line_h4 {
      width: 1px;
      height: 15px;
      background-color: white;
    }

    .org_line5 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .cometo {
      display: flex;
      height: 100%;
      width: 1000px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .comto_header {
      width: 1000px;
      display: flex;
      justify-content: space-between;
    }

    .cometo_title {
      width: 280px;
    }

    .cometo_mark {
      width: 60px;
      height: 14px;
      margin-bottom: 34px;
    }

    .cometo_title_text {
      font-size: 22px;
      line-height: 28px;
      font-family: Pretendard;
      font-weight: 500;
    }

    .cometo_blue {
      color: #00177c;
      font-weight: 600;
    }

    .comto_black {
      margin-top: 20px;
    }

    .cometo_line {
      width: 1px;
      height: 198px;
      background-color: #e1e1e1;
    }

    .cometo_info {
      margin-left: 50px;
      display: flex;
      flex-direction: column;
    }

    .cometo_info1 {
      display: flex;
      margin-bottom: 38px;
    }

    .cometo_address {
      margin-right: 45px;
    }

    .cometo_info_header {
      display: flex;
      height: 20px;
      align-items: center;
    }

    .cometo_info_header_img {
      width: 20px;
      height: 20px;
    }

    .cometo_info_header_text {
      font-size: 15px;
      font-weight: 600;
      font-family: Pretendard;
      margin-left: 11px;
    }

    .cometo_info_body {
      font-size: 13px;
      color: #6f7277;
      margin-top: 11px;
      margin-left: 30px;
      font-family: Pretendard;
    }

    .cometo_subway_img {
      display: flex;
      height: 20px;
      margin-top: 11px;
      margin-left: 30px;
      font-family: Pretendard;
      font-size: 15px;
      font-weight: 600;
      align-items: center;
    }

    .cometo_subway_img_o {
      width: 22px;
    }

    .cometo_subway_img_m {
      margin-left: 5px;
      margin-right: 10px;
      width: 50px;
    }

    .cometo_info_body2 {
      font-size: 13px;
      color: #6f7277;
      margin-top: 11px;
      margin-left: 30px;
      font-family: Pretendard;
      height: 16px;
      line-height: 16px;
    }

    .cometo_body {
      width: 1280px;
      height: 430px;
      margin-top: 50px;
      display: flex;
      justify-content: center;
    }

    .company_map {
      width: 1000px;
      height: 428px;
    }

    .company_mapunder {
      width: 978px;
    }

    .company_map_wrapper {
      width: 1000px;
    }
  }
}

/* PC(해상도 1920px ~)*/
@media all and (min-width: 1921px) {
}

/* 테블릿 가로, 테블릿 세로 (해상도 768px ~ 1024px)*/
@media all and (min-width: 768px) and (max-width: 1024px) {
  .company_map {
    width: 800px;
  }

  .wrapper {
    display: flex;
    width: 100%;
    height: 875px;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
  }

  .wrapper_cometo {
    display: flex;
    width: 100%;
    height: 600px;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
  }

  .wrapper_org {
    display: flex;
    width: 100%;
    height: 750px;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
  }

  .wrapper_h {
    display: flex;
    width: 100%;
    height: 630px;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
  }

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    /* transform: scale(1.1) rotate(-0.01deg); */
    transition: 0.3s;
    opacity: 1;
    animation: fadeInBg 10s linear both;
  }

  .background_org {
    position: absolute;
    width: 100%;
    height: 700px;
    z-index: -1;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    /* transform: scale(1.1) rotate(-0.01deg); */
    transition: 0.3s;
    opacity: 1;
    animation: fadeInBg 10s linear both;
  }

  .background:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 80vh;
  }

  .background_blur {
    display: flex;
    width: 100%;
    height: 727px;
    background-color: rgba(0, 0, 0, 0.4);
    /* z-index: -1; */
  }

  .background_blur2 {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* z-index: -1; */
  }

  .background_blur3 {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.15);
    /* z-index: -1; */
  }

  .company_area {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .company_org_area {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 700px;
  }

  .company_title_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 875px;
    gap: 49px;
  }

  .company_org_title_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 875px;
    gap: 49px;
  }

  .company_title {
    height: 46px;
    font-family: Pretendard;
    font-size: 46px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 46px;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .company_subtitle {
    height: 20px;
    font-family: Pretendard;
    font-size: 19px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .introduce {
    margin-top: 100px;
    height: 705px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 70px;
  }

  .introduce_header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 92px;
  }

  .introduce_title {
    font-size: 40px;
    font-family: Pretendard;
    font-weight: 600;
    height: 40px;
    line-height: 40px;
  }

  .introduce_subtitle {
    font-size: 43px;
    font-family: Pretendard;
    font-weight: 600;
    height: 43px;
    line-height: 43px;
    margin-top: 24px;
    /* 
        font-family: "Pretendard Variable", Pretendard, -apple-system,
      BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI",
      "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic",
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif; */
  }

  .introduce_body {
    display: flex;
    justify-content: space-between;
    max-width: 1280px;
    width: 66%;
  }

  .introduce_bgimg {
    /* width: 670px; */
    width: 52%;
    height: 503px;
    text-align: center;
    line-height: 559px;
  }

  .company_introduce_logo_on {
    transform: translateY(-510px);
    filter: blur(1);
    -webkit-filter: blur(0);
    opacity: 1;
    transition: transform 1s, opacity 1s, filter 1s;
    transition-delay: 0.1s;
    width: 46%;
    min-width: 180px;
    max-width: 308px;
  }

  .company_introduce_logo {
    transform: translateY(-300px);
    filter: blur(10px);
    -webkit-filter: blur(10px);
    opacity: 0;
    min-width: 180px;
    max-width: 308px;
    width: 46%;
  }

  .introduce_body_header {
    width: 103px;
    height: 36px;
    line-height: 36px;
    background-color: #001572;
    border-radius: 18px;
    font-size: 16px;
    font-family: Poppins;
    color: #fff;
    text-align: center;
  }

  .introduce_text {
    font-size: 15px;
    font-family: Pretendard;
    letter-spacing: normal;
    font-weight: normal;
    color: #2e2f30;
    line-height: 22px;

    white-space: keep-all;
  }

  .introduce_body_text {
    width: 300px;
    margin-left: 20px;
    display: flex;
    row-gap: 15px;
    flex-direction: column;
    justify-content: center;
  }

  .introduce_footer {
    margin-top: 5px;
    font-size: 20px;
    letter-spacing: -0.7px;
    font-family: Pretendard;
    font-weight: 500;
    color: #2e2f30;
  }

  .organization {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .organization_title {
    font-family: Pretendard;
    font-size: 40px;
    font-weight: bold;
    color: #fff;
  }

  .organization_subtitle {
    width: 200px;
    height: 32px;
    border-radius: 17px;
    background-color: #00177c;
    line-height: 36px;
    font-size: 13px;
    font-family: Poppins;
    color: #fff;
    text-align: center;
    margin-top: 34px;
    margin-bottom: 72px;
  }

  .organization_action {
    display: flex;
    flex-direction: column;
    align-items: center;

    transform: translateY(80px);
    filter: blur(10px);
    -webkit-filter: blur(10px);
    opacity: 0;
  }

  .organization_action_on {
    display: flex;
    flex-direction: column;
    align-items: center;

    transform: translateY(0);
    filter: blur(1);
    -webkit-filter: blur(0);
    opacity: 1;
    transition: transform 1s, opacity 1s, filter 1s;
    transition-delay: 0.1s;
  }

  .org_box_big {
    width: 100px;
    height: 40px;
    border: 1px solid #fff;
    text-align: center;
    line-height: 40px;
    background-color: rgba(255, 255, 255, 0.1);
  }

  .org_box_small {
    width: 120px;
    height: 40px;
    border: 1px solid #fff;
    text-align: center;
    line-height: 40px;
    background-color: rgba(255, 255, 255, 0.1);
  }

  .org_box_big_font {
    font-family: Pretendard;
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    letter-spacing: normal;

    transform: translateY(80px);
    filter: blur(10px);
    -webkit-filter: blur(10px);
    opacity: 0;
  }

  .org_box_big_font_on {
    font-family: Pretendard;
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    letter-spacing: normal;

    transform: translateY(0);
    filter: blur(1);
    -webkit-filter: blur(0);
    opacity: 1;
    transition: transform 1.3s, opacity 1.3s, filter 1.3s;
    transition-delay: 0.2s;
  }

  .org_box_small_font {
    font-family: Pretendard;
    font-size: 13px;
    font-weight: 600;
    color: #fff;
    letter-spacing: normal;

    transform: translateY(80px);
    filter: blur(10px);
    -webkit-filter: blur(10px);
    opacity: 0;
  }

  .org_box_small_font_on {
    font-family: Pretendard;
    font-size: 13px;
    font-weight: 600;
    color: #fff;
    letter-spacing: normal;

    transform: translateY(0);
    filter: blur(1);
    -webkit-filter: blur(0);
    opacity: 1;
    transition: transform 1.3s, opacity 1.3s, filter 1.3s;
    transition-delay: 0.5s;
  }

  .org_line_h {
    width: 1px;
    height: 25px;
    background-color: #fff;
  }

  .org_line_h2 {
    width: 1px;
    height: 20px;
    background-color: #fff;
  }

  .org_line2 {
    display: flex;
    align-items: center;
  }

  .org_line_w {
    height: 1px;
    width: 100px;
    background-color: white;
  }

  .org_line2_1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .org_line2_c {
    width: 7px;
    height: 7px;
    border-radius: 7px;
    background-color: white;
  }

  .org_line3 {
    display: flex;
  }

  .org_line_h3 {
    width: 1px;
    height: 15px;
    background-color: white;
  }

  .org_line_w3 {
    height: 1px;
    width: 180px;
    background-color: white;
  }

  .org_line4 {
    display: flex;
    justify-content: space-between;
    width: 665px;
  }

  /* .org_line4_h {
    margin: 0px 43px;
  } */
  .org_line_h4 {
    width: 1px;
    height: 19px;
    background-color: white;
  }

  .org_line5 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .cometo {
    display: flex;
    height: 508px;
    width: 768px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .comto_header {
    width: 724px;
    display: flex;
    justify-content: space-between;
  }

  .cometo_title {
    width: 150px;
  }

  .cometo_mark {
    width: 60px;
    height: 14px;
    margin-bottom: 34px;
  }

  .cometo_title_text {
    font-size: 15px;
    line-height: 22px;
    font-family: Pretendard;
    font-weight: 500;
  }

  .cometo_blue {
    color: #00177c;
    font-weight: 600;
  }

  .comto_black {
    margin-top: 20px;
  }

  .cometo_line {
    width: 1px;
    height: 170px;
    background-color: #e1e1e1;
  }

  .cometo_info {
    margin-right: 50px;
    display: flex;
    flex-direction: column;
  }

  .cometo_info1 {
    display: flex;
    margin-bottom: 20px;
  }

  .cometo_address {
    margin-right: 20px;
  }

  .cometo_info_header {
    display: flex;
    height: 20px;
    align-items: center;
  }

  .cometo_info_header_img {
    width: 15px;
    height: 15px;
  }

  .cometo_info_header_text {
    font-size: 13px;
    font-weight: 600;
    font-family: Pretendard;
    margin-left: 11px;
  }

  .cometo_info_body {
    font-size: 11px;
    color: #6f7277;
    margin-top: 11px;
    margin-left: 30px;
    font-family: Pretendard;
  }

  .cometo_subway_img {
    display: flex;
    height: 20px;
    margin-top: 11px;
    margin-left: 30px;
    font-family: Pretendard;
    font-size: 13px;
    font-weight: 600;
    align-items: center;
  }

  .cometo_subway_img_o {
    width: 17px;
  }

  .cometo_subway_img_m {
    margin-left: 5px;
    margin-right: 5px;
    width: 40px;
  }

  .cometo_info_body2 {
    font-size: 11px;
    color: #6f7277;
    margin-top: 11px;
    margin-left: 30px;
    font-family: Pretendard;
    height: 16px;
    line-height: 16px;
  }

  .company_map_wrap {
    height: 308px;
  }

  .cometo_body {
    width: 720px;
    height: 308px;
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }

  .company_map {
    width: 720px;
    height: 308px;
  }

  .company_mapunder {
    width: 700px;
  }

  .company_map_wrapper {
    width: 1000px;
  }
}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width: 767px) {
  .company_map {
    width: calc(100% - 40px);
  }

  .wrapper {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
  }

  .wrapper_cometo {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
  }

  .wrapper_org {
    display: flex;
    width: 100%;
    height: 624px;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
  }

  .wrapper_h {
    display: flex;
    width: 100%;
    height: 530px;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
  }

  .background {
    position: absolute;
    width: 100%;
    height: 530px;
    z-index: -1;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    /* transform: scale(1.1) rotate(-0.01deg); */
    transition: 0.3s;
    opacity: 1;
    animation: fadeInBg 10s linear both;
  }

  .background_org {
    position: absolute;
    width: 100%;
    height: 564px;
    z-index: -1;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    /* transform: scale(1.1) rotate(-0.01deg); */
    transition: 0.3s;
    opacity: 1;
    animation: fadeInBg 10s linear both;
  }

  .background:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 80vh;
  }

  .background_blur {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    /* z-index: -1; */
  }

  .background_blur2 {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* z-index: -1; */
  }

  .background_blur3 {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.15);
    /* z-index: -1; */
  }

  .company_area {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .company_org_area {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 564px;
  }

  .company_title_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 24px;
  }

  .company_org_title_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .company_title {
    font-family: Pretendard;
    font-size: 34px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 42px;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    width: 300px;
    margin-top: 60px;
    text-align: center;
  }

  .company_subtitle {
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 24px;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    width: 240px;
    text-align: center;
  }

  .introduce {
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 70px;
  }

  .introduce_header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;
  }

  .introduce_title {
    font-size: 24px;
    font-family: Pretendard;
    font-weight: 600;
    height: 24px;
    line-height: 24px;
  }

  .introduce_subtitle {
    font-size: 26px;
    font-family: Pretendard;
    font-weight: 700;
    margin-top: 18px;
    text-align: center;
  }

  .introduce_body {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 40px);
    flex-direction: column;
    align-items: center;
  }

  .introduce_bgimg {
    /* width: 670px; */
    width: 335px;

    height: 252px;
    text-align: center;
    line-height: 559px;
    margin-bottom: 25px;
  }

  .company_introduce_logo_on {
    transform: translateY(-390px);
    filter: blur(1);
    -webkit-filter: blur(0);
    opacity: 1;
    transition: transform 1s, opacity 1s, filter 1s;
    transition-delay: 0.1s;
    width: 160px;
  }

  .company_introduce_logo {
    transform: translateY(-300px);
    filter: blur(10px);
    -webkit-filter: blur(10px);
    opacity: 0;
    width: 160px;
  }

  .introduce_body_header {
    width: 103px;
    height: 36px;
    line-height: 36px;
    background-color: #001572;
    border-radius: 18px;
    font-size: 16px;
    font-family: Poppins;
    color: #fff;
    text-align: center;
  }

  .introduce_text {
    font-size: 15px;
    font-family: Pretendard;
    letter-spacing: normal;
    font-weight: normal;
    color: #2e2f30;
    line-height: 22px;

    white-space: keep-all;
  }

  .introduce_body_text {
    width: 100%;
    display: flex;
    row-gap: 30px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .introduce_footer {
    margin-top: 5px;
    font-size: 20px;
    letter-spacing: -0.7px;
    font-family: Pretendard;
    font-weight: 500;
    color: #2e2f30;
  }

  .organization {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .organization_title {
    font-family: Pretendard;
    font-size: 40px;
    font-weight: bold;
    color: #fff;
  }

  .organization_subtitle {
    width: 232px;
    height: 45px;
    border-radius: 22px;
    background-color: #00177c;
    line-height: 45px;
    font-size: 13px;
    font-family: Poppins;
    color: #fff;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 47px;
  }

  .organization_action {
    display: flex;
    flex-direction: column;
    align-items: center;

    transform: translateY(80px);
    filter: blur(10px);
    -webkit-filter: blur(10px);
    opacity: 0;
  }

  .organization_action_on {
    display: flex;
    flex-direction: column;
    align-items: center;

    transform: translateY(0);
    filter: blur(1);
    -webkit-filter: blur(0);
    opacity: 1;
    transition: transform 1s, opacity 1s, filter 1s;
    transition-delay: 0.1s;
  }

  .org_box_big {
    width: 80px;
    height: 30px;
    border: 1px solid #fff;
    text-align: center;
    line-height: 40px;
    background-color: rgba(255, 255, 255, 0.1);
  }

  .org_box_small {
    width: 80px;
    height: 30px;
    border: 1px solid #fff;
    text-align: center;
    line-height: 40px;
    background-color: rgba(255, 255, 255, 0.1);
  }

  .org_box_big_font {
    font-family: Pretendard;
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    letter-spacing: normal;

    transform: translateY(80px);
    filter: blur(10px);
    -webkit-filter: blur(10px);
    opacity: 0;
  }

  .org_box_big_font_on {
    font-family: Pretendard;
    font-size: 10px;
    font-weight: 600;
    color: #fff;
    letter-spacing: normal;

    transform: translateY(-5px);
    filter: blur(1);
    -webkit-filter: blur(0);
    opacity: 1;
    transition: transform 1.3s, opacity 1.3s, filter 1.3s;
    transition-delay: 0.2s;
  }

  .org_box_small_font {
    font-family: Pretendard;
    font-size: 13px;
    font-weight: 600;
    color: #fff;
    letter-spacing: normal;

    transform: translateY(80px);
    filter: blur(10px);
    -webkit-filter: blur(10px);
    opacity: 0;
  }

  .org_box_small_font_on {
    font-family: Pretendard;
    font-size: 10px;
    font-weight: 600;
    color: #fff;
    letter-spacing: normal;
    letter-spacing: -0.3px;
    transform: translateY(-5px);
    filter: blur(1);
    -webkit-filter: blur(0);
    opacity: 1;
    transition: transform 1.3s, opacity 1.3s, filter 1.3s;
    transition-delay: 0.5s;
  }

  .org_line_h {
    width: 1px;
    height: 25px;
    background-color: #fff;
  }

  .org_line_h2 {
    width: 1px;
    height: 12px;
    background-color: #fff;
  }

  .org_line2 {
    display: flex;
    align-items: center;
  }

  .org_line_w {
    height: 1px;
    width: 45px;
    background-color: white;
  }

  .org_line2_1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .org_line2_c {
    width: 7px;
    height: 7px;
    border-radius: 7px;
    background-color: white;
  }

  .org_line3 {
    display: flex;
  }

  .org_line_h3 {
    width: 1px;
    height: 15px;
    background-color: white;
    display: none;
  }

  .org_line_w3 {
    height: 1px;
    width: 85px;
    background-color: white;
  }

  .org_line4 {
    display: flex;
    justify-content: space-between;
    width: 338px;
  }

  /* .org_line4_h {
    margin: 0px 43px;
  } */
  .org_line_h4 {
    width: 1px;
    height: 15px;
    background-color: white;
  }

  .org_line5 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .cometo {
    display: flex;
    width: calc(100% - 40px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .comto_header {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .cometo_title {
    width: 100%;
  }

  .cometo_mark {
    width: 60px;
    height: 14px;
    margin-bottom: 20px;
  }

  .cometo_title_text {
    font-size: 26px;
    font-family: Pretendard;
    font-weight: 500;
    width: 100%;
    margin-bottom: 37px;
  }

  .cometo_blue {
    color: #00177c;
    font-weight: 600;
  }

  .comto_black {
    margin-top: 5px;
  }

  .cometo_line {
    display: none;
  }

  .cometo_info {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .cometo_info1 {
    display: flex;
    margin-bottom: 30px;
    flex-direction: column;
    gap: 30px;
  }

  .cometo_address {
    margin-right: 20px;
  }

  .cometo_info_header {
    display: flex;
    height: 20px;
    align-items: center;
  }

  .cometo_info_header_img {
    width: 21px;
    height: 21px;
  }

  .cometo_info_header_text {
    font-size: 19px;
    font-weight: 600;
    font-family: Pretendard;
    margin-left: 11px;
  }

  .cometo_info_body {
    font-size: 16px;
    color: #6f7277;
    margin-top: 11px;
    margin-left: 30px;
    font-family: Pretendard;
  }

  .cometo_subway_img {
    display: flex;
    height: 20px;
    margin-top: 11px;
    margin-left: 30px;
    font-family: Pretendard;
    font-size: 19px;
    font-weight: 600;
    align-items: center;
    margin-bottom: 13px;
  }

  .cometo_subway_img_o {
    height: 25px;
  }

  .cometo_subway_img_m {
    margin-left: 5px;
    margin-right: 13px;
    height: 25px;
  }

  .cometo_info_body2 {
    font-size: 16px;
    color: #6f7277;
    margin-left: 30px;
    font-family: Pretendard;
    line-height: 25px;
  }

  .company_map_wrap {
    height: 308px;
  }

  .cometo_body {
    width: 100%;
    height: 280px;
    margin-top: 30px;
    margin-bottom: 60px;
    display: flex;
    justify-content: center;
  }

  .company_map {
    width: 100%;
    height: 308px;
  }

  .company_mapunder {
    width: 100%;
  }

  .company_map_wrapper {
    width: 100%;
  }
}
