/* PC (해상도 1024px)*/
/* PC (해상도 1024px)*/
@media all and (min-width: 1025px) {
  .zw3d_false {
  }
  .zw3d_true {
    width: 14px;
    height: 14px;
    border-radius: 14px;
    background-color: #fff;
  }
  .zw3d_empty {
    width: 10px;
    height: 10px;
    border-radius: 12px;
    background-color: none;
    border: 2px solid #fff;
  }
  .zw3d_datalist {
    display: flex;
  }
  .zw3d_datalist_header {
    display: flex;
  }
  .wrapper {
    display: flex;
    width: 100%;
    aspect-ratio: 2.55;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
  }

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    transition: 0.3s;
    opacity: 1;
  }

  .background:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .zwcad_area {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .zwcad_title_wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 1280px;
    height: 100%;
    gap: 63px;
    margin-top: 81px;
  }

  .zwcad_title {
    height: 56px;
    font-family: Pretendard;
    font-size: 75px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 56px;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .zwcad_subtitle {
    height: 20px;
    font-family: Pretendard;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .zwcad_title_btn_wrap {
    display: flex;
    align-items: center;
    gap: 21px;
    margin-top: 36px;
  }

  .zwcad_title_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 176px;
    height: 52px;
    border-radius: 25px;
    border: solid 1px #fff;
    background-color: transparent;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .zwcad_title_btn:last-child {
    background-color: #fff;
    color: #222;
  }

  .zwcad_category {
    display: flex;
    justify-content: center;
    height: 83px;
    border-bottom: 1px solid #c0c0c0;
    margin-bottom: 106px;
  }

  .zwcad_category_btn_wrap {
    display: flex;
    align-items: center;
    gap: 60px;
    width: 1280px;
  }

  .zwcad_category_btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    /* width: auto; */
    height: 100%;
    font-family: Pretendard;
    font-size: 19px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .zwcad_category_btn:hover {
    color: #00177c;
  }

  .zwcad_category_btn:after {
    display: block;
    content: "";
    border-bottom: solid 2px #00177c;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    transform-origin: 0% 50%;
  }

  .zwcad_category_btn:hover:after {
    transform: scaleX(1);
  }

  .button_focus {
    border-bottom: 2px solid #00177c;
    color: #00177c;
  }

  .zwcad_feature_list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 75px;
  }

  .zwcad_feature_item_wrap {
    display: flex;
    width: 100%;
  }

  .zwcad_feature_item_wrap:global.gray {
    background-color: #eceff7;
  }

  .zwcad_feature_item_wrap:global.gray:last-child {
    padding: 70px 0;
  }

  .zwcad_feature_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1280px;
    margin: 0 auto;
  }

  .zwcad_feature_description_wrap {
    display: flex;
    flex-direction: column;
  }

  .zwcad_feature_index {
    height: 18px;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: normal;
    text-align: left;
    color: #111;
  }

  .zwcad_feature_name {
    margin-top: 22px;
    height: 36px;
    font-family: Pretendard;
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 36px;
    letter-spacing: normal;
    text-align: left;
    color: #001572;
  }

  .zwcad_feature_text {
    margin-top: 62px;
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.7;
    letter-spacing: normal;
    text-align: left;
    color: #111;
    word-break: keep-all;
  }

  .zwcad_feature_text .highlight {
    box-shadow: inset 0 -12px 0 rgba(0, 159, 169, 0.24);
    /* border-bottom: 12px solid rgba(0, 159, 169, 0.24); */
  }

  .zwcad_feature_item_wrap:first-child .zwcad_feature_text {
    width: 545px;
  }

  .zwcad_feature_item_wrap:nth-child(2) .zwcad_feature_text {
    width: 566px;
    margin-bottom: 32px;
  }

  .zwcad_feature_item_wrap:nth-child(3) .zwcad_feature_text {
    width: 545px;
    margin-bottom: 54px;
  }

  .tag_square {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 347px;
    height: 60px;
    background-color: rgba(0, 21, 114, 0.08);
    font-family: Pretendard;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #111;
  }

  .tag_grid {
    display: flex;
    gap: 10px;
  }

  .tag_container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    background-color: rgba(0, 21, 114, 0.08);
    padding: 0 19px;
    gap: 25px;
    margin-top: 32px;
  }

  .tag_wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 13px;
    margin-top: 41px;
    width: 600px;
  }

  .tag_list {
    display: flex;
    flex-direction: column;
    gap: 9px;
    margin-top: 32px;
  }

  .tag_grid_item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 0px 20px;
    border-radius: 20px;
    background-color: #ebedf4;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #111;
  }

  .tag_container_item {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #111;
    gap: 10px;
  }

  .tag_wrap_item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 0px 20px;
    border-radius: 1px;
    background-color: #ebedf4;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #111;
    gap: 10px;
  }

  .tag_list_item {
    display: flex;
    align-items: center;
    height: 45px;
    background-color: #eceef4;
    padding: 0 19px;
    gap: 25px;
  }

  .tag_list_item_label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #111;
    width: 61px;
  }

  .tag_list_item_text {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #111;
  }

  .item_dot {
    border-radius: 100%;
    width: 6px;
    height: 6px;
    background-color: #009fa9;
  }

  .zw3d_info_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .zw3d_info_title {
    font-family: Pretendard;
    font-size: 43px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 39px;
    letter-spacing: normal;
    text-align: center;
    color: #191919;
    height: 39px;
  }

  .zw3d_info_description {
    width: 100%;
    margin-top: 56px;
    text-align: center;
    font-family: Pretendard;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78;
    letter-spacing: normal;
    color: #111;
  }

  .zw3d_info_description .highlight {
    font-weight: 600;
    box-shadow: inset 0 -12px 0 rgba(0, 159, 169, 0.24);
    /* border-bottom: 12px solid rgba(0, 159, 169, 0.24); */
  }

  .zw3d_info_guarantee {
    margin-top: 60px;
    height: 17px;
    font-family: Pretendard;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 17px;
    letter-spacing: normal;
    text-align: center;
    color: #111;
  }

  .zw3d_info_guarantee > span {
    font-weight: 600;
  }

  .zw3d_procedure_wrap {
    display: flex;
    flex-direction: column;
    gap: 36px;
    width: 1280px;
    margin: 102px auto 151px;
  }

  .zw3d_procedure_title_wrap {
    display: flex;
    flex-direction: column;
    gap: 19px;
  }

  .zw3d_procedure_label {
    height: 13px;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 13px;
    letter-spacing: normal;
    text-align: left;
    color: #555;
  }

  .zw3d_procedure_title {
    height: 36px;
    font-family: Pretendard;
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 36px;
    letter-spacing: normal;
    text-align: left;
    color: #191919;
  }

  .zw3d_procedure_title > span {
    color: #001572;
  }

  .zw3d_prodcedure_list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .zw3d_procedure_item_wrap {
    display: flex;
    width: 100%;
  }

  .zw3d_procedure_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1280px;
    margin: 0 auto;
  }

  .zw3d_procedure_img {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .zw3d_procedure_description_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 33px;
  }

  .zw3d_procedure_item_index {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;
    background-color: #001572;
    font-family: Poppins;
    font-size: 17.5px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    border-radius: 50%;
  }

  .zw3d_procedure_item_title_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 11px;
  }

  .zw3d_procedure_item_sub_title {
    height: 16px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 16px;
    letter-spacing: -0.16px;
    text-align: center;
    color: #009fa9;
  }

  .zw3d_procedure_item_title {
    height: 31px;
    font-family: Pretendard;
    font-size: 35px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 31px;
    letter-spacing: normal;
    text-align: center;
    color: #191919;
  }

  .zw3d_procedure_item_text {
    margin-top: 12px;
    text-align: center;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.7;
    letter-spacing: normal;
    text-align: center;
    color: #191919;
  }

  .zdream_introduce_wrap {
    display: flex;
    justify-content: center;
  }

  .zdream_introduce {
    display: flex;
    width: 1280px;
    margin: 81px 0px 29px 0px;
    justify-content: space-between;
  }

  .zdream_introduce_card {
    width: 318px;
    height: 305px;

    padding: 33px 33px 0px 33px;
    border-radius: 5px;
    box-shadow: 4.6px 5.3px 14.7px 1.3px rgba(65, 65, 65, 0.15);
    background-color: #fff;
    text-align: center;
  }

  .zdream_introduce_txt,
  .zdream_introduce_txt2 {
    text-align: center;
    font-size: 26px;
    font-family: Pretendard;
    font-weight: 500;
    color: #111;
    height: 26px;
    line-height: 26px;
  }

  .zdream_introduce_txt2 {
    margin-top: 8px;
  }

  .zdream_introduce_txt_color {
    color: #009fa9;
    font-weight: 600;
  }

  .zdream_box_img1 {
    margin-top: 38px;
  }

  .zdream_box_img2 {
    margin-top: 22px;
  }

  .zdream_box_img3 {
    margin-top: 31px;
  }

  .zdream_list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 60px;
  }

  .zdream_box {
    display: flex;
  }

  .zdream_contents {
    margin-left: 44px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .zdream_box_title {
    font-family: Pretendard;
    font-size: 35px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #009fa9;
  }

  .zdream_line {
    width: 532px;
    height: 1px;
    background-color: #c0c0c0;
    margin: 38px 0px 52px 0px;
  }

  .zdream_details {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }

  .zdream_detail {
    display: flex;

    font-family: Pretendard;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #191919;
    align-items: center;
  }

  .zdream_box_dot {
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background-color: #009fa9;
    margin-right: 13px;
  }

  .compare_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .compare_header {
    width: 1280px;
    margin-bottom: 24px;
    font-size: 28px;
    font-family: Pretendard;
  }

  .ch_1 {
    font-weight: bold;
  }

  .ch_2 {
    color: #009fa9;
    font-weight: bold;
  }

  .compare_line {
    display: flex;
  }

  .zw3d_compare_line {
    display: flex;
    width: 100%;
  }

  .compare_content1 {
    width: 512px;
    height: 57px;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #111111;
    background-color: #f1f1f1;
    line-height: 57px;
    border-right: 1px solid white;
  }

  .compare_content2 {
    width: 383px;
    height: 57px;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    background-color: #001572;
    line-height: 57px;
    border-right: 1px solid white;
  }

  .compare_content3 {
    width: 383px;
    height: 57px;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    background-color: #9aa6dd;
    line-height: 57px;
  }

  .compare_content4 {
    display: flex;
  }

  .compare_content5 {
    width: 512px;
    height: 57px;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #111111;
    background-color: #f1f1f1;
    line-height: 57px;
    border-top: 2px solid white;
    border-right: 1px solid white;
  }

  .compare_blue1 {
    background-color: #001572;
    width: 191px;
    border-right: 1px solid white;
    border-top: 2px solid white;
  }

  .compare_blue2 {
    background-color: #001572;
    width: 191px;
    border-top: 2px solid white;
    border-right: 1px solid white;
  }

  .compare_sky1 {
    background-color: #9aa6dd;
    width: 191px;
    border-right: 1px solid white;
    border-top: 2px solid white;
  }

  .compare_sky2 {
    background-color: #9aa6dd;
    width: 191px;
    border-top: 2px solid white;
  }

  .compare_blue1,
  .compare_blue2,
  .compare_sky1,
  .compare_sky2 {
    text-align: center;
    line-height: 57px;
    font-family: Pretendard;
    font-size: 17px;
    color: #fff;
  }

  .listopen {
    min-height: 200px;
    max-height: 1400px;
    transition: all 0.5s ease-in-out;
  }

  .listhidden {
    min-height: 0px;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
  }

  .tite_line {
    display: flex;
    width: 1280px;
    justify-content: space-between;
    height: 58px;
    align-items: center;
  }

  .title_text {
    display: flex;
  }

  .title_index {
    margin-left: 14px;
    margin-right: 29px;
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    background-color: #f3f3f3;
    border-radius: 2px;
  }

  .title_name {
    height: 32px;
    font-family: Pretendard;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 32px;
    letter-spacing: normal;
    text-align: left;
    color: #191919;
  }

  .title_arrow {
    margin-right: 45px;
  }

  .compare_list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .contents_text {
    width: 475px;
    height: 48px;
    padding-left: 37px;
    background-color: #f3f3f3;
    text-align: left;
    line-height: 48px;
    margin-right: 1px;
  }

  .contents_blue {
    background-color: #001572;
    width: 191px;
    border-right: 1px solid white;
  }

  .contents_sky {
    background-color: #9aa6dd;
    width: 191px;
    border-right: 1px solid white;
  }

  .contents_sky2 {
    background-color: #9aa6dd;
    width: 191px;
  }

  .contents_blue,
  .contents_sky,
  .contents_sky2 {
    text-align: center;
    line-height: 57px;
    font-family: Pretendard;
    font-size: 17px;
    color: #fff;
    height: 48px;
  }

  .contents_box {
    border-bottom: 1px solid #c0c0c0;
  }

  .compare_quote {
    width: 1280px;
    text-align: end;
    margin-top: 39px;
    margin-bottom: 223px;
    font-family: Pretendard;
    font-size: 15px;
    color: #191919;
  }

  .compare_quote2 {
    width: 1280px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 39px;
    margin-bottom: 223px;
    font-family: Pretendard;
    font-size: 15px;
    color: #191919;
  }

  .quote2_box {
    font-family: Pretendard;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #191919;

    width: 151px;
    height: 73px;

    padding: 14px 14px;
    border: solid 1px #e1e1e1;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-top: 14px;
  }

  .quote2_box_line {
    display: flex;
    gap: 6px;
  }

  .quote2_check {
    height: 14px;
    width: 19px;
  }

  .quote2_circle {
    width: 15px;
    height: 15px;
    background-color: #000000;
    border-radius: 15px;
    margin-right: 4px;
  }

  .quote2_empty {
    width: 13px;
    height: 13px;
    border: 1px solid #000000;
    border-radius: 15px;
    margin-right: 4px;
  }

  .compare_content8 {
    width: 512px;
    height: 84px;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #111111;
    background-color: #f1f1f1;
    line-height: 57px;
    border-top: 2px solid white;
    border-right: 1px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .compare_content6 {
    width: 440px;
    height: 84px;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    background-color: #001572;
    border-right: 1px solid white;
    display: flex;
  }

  .compare_blue3 {
    background-color: #001572;
    width: 108px;
    border-right: 1px solid white;
    border-top: 2px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .compare_blue4 {
    background-color: #001572;
    width: 113px;
    border-top: 2px solid white;
    border-right: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .compare_content7 {
    width: 326px;
    height: 84px;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    background-color: #9aa6dd;
    display: flex;
  }

  .compare_sky3 {
    background-color: #9aa6dd;
    width: 108px;
    border-right: 1px solid white;
    border-top: 2px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .compare_sky4 {
    background-color: #9aa6dd;

    border-top: 2px solid white;
  }

  .compare_blue3,
  .compare_blue4,
  .compare_sky3,
  .compare_sky4 {
    font-family: Pretendard;
    font-size: 17px;
    color: #fff;
  }

  .text2 {
    font-size: 14px;
    letter-spacing: -0.14px;
    margin-top: 3px;
    font-family: Pretendard;
  }

  .contents_text2 {
    width: 475px;
    height: 48px;
    padding-left: 37px;
    background-color: #f3f3f3;
    text-align: left;
    line-height: 48px;
    margin-right: 1px;
  }

  .contents_text3 {
    width: 455px;
    height: 48px;
    padding-left: 37px;
    padding-right: 20px;
    background-color: #f3f3f3;
    text-align: left;
    line-height: 48px;
    margin-right: 1px;
    display: flex;
    justify-content: space-between;
  }

  .contents_text4 {
    width: 455px;
    height: 52px;
    padding-left: 37px;
    padding-right: 20px;
    background-color: #f3f3f3;
    text-align: left;
    margin-right: 1px;
    display: flex;
    justify-content: space-between;
  }

  .contents_youtube {
    display: flex;
    gap: 5px;
    align-items: center;
  }
  .zw3d_datalist {
    display: flex;
  }

  .contents_blue2 {
    background-color: #001572;
    width: 108px;
    border-right: 1px solid white;
  }

  .contents_blue3 {
    background-color: #001572;
    width: 113px;
    border-right: 1px solid white;
  }

  .contents_sky3 {
    background-color: #9aa6dd;
    width: 108px;
    border-right: 1px solid white;
  }

  .contents_blue2,
  .contents_blue3,
  .contents_sky3,
  .contents_sky4 {
    text-align: center;
    line-height: 48px;
    font-family: Pretendard;
    font-size: 17px;
    color: #fff;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .contents_blue_52 {
    text-align: center;
    line-height: 52px;
    font-family: Pretendard;
    font-size: 17px;
    color: #fff;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #001572;
    width: 108px;
    border-right: 1px solid white;
  }

  .contents_blue3_52 {
    text-align: center;
    line-height: 52px;
    font-family: Pretendard;
    font-size: 17px;
    color: #fff;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #001572;
    width: 113px;
    border-right: 1px solid white;
  }

  .contents_sky_52 {
    text-align: center;
    line-height: 52px;
    font-family: Pretendard;
    font-size: 17px;
    color: #fff;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #9aa6dd;
    width: 108px;
    border-right: 1px solid white;
  }

  .contents_box {
    border-bottom: 1px solid #c0c0c0;
  }

  @media all and (min-width: 1025px) and (max-width: 1300px) {
    .zwcad_title_wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: calc(100% - 40px);
      max-width: 1280px;
      height: 100%;
      gap: 45px;
      margin-left: 40px;
    }

    .zwcad_title {
      height: 60px;
      font-family: Pretendard;
      font-size: 60px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 60px;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
      margin-top: 10%;
      margin-top: 0px;
    }

    .zwcad_title_btn_wrap {
      display: flex;
      margin-top: 0px;
    }

    .zwcad_category_btn_wrap {
      display: flex;
      align-items: center;
      gap: 60px;
      width: calc(100% - 80px);
    }

    .zwcad_feature_item_wrap {
      display: flex;
      width: calc(100% - 80px);
    }

    .zwcad_feature_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin: 0 auto;
    }

    .zwcad_feature_img {
      width: 45%;
    }

    .zwcad_feature_description_wrap {
      width: 45%;
    }

    .zwcad_feature_item_wrap:first-child .zwcad_feature_text {
      width: 100%;
    }

    .zwcad_feature_item_wrap:nth-child(3) .zwcad_feature_text {
      width: 100%;
      margin-bottom: 54px;
    }

    .zwcad_feature_item_wrap:nth-child(2) .zwcad_feature_text {
      width: 100%;
      margin-bottom: 32px;
    }

    .tag_grid {
      display: flex;
      gap: 5px;
    }

    .tag_grid_item {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      padding: 0px 10px;
      border-radius: 20px;
      background-color: #ebedf4;
      font-family: Pretendard;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: #111;
    }

    .tag_container {
      display: flex;
      justify-content: space-between;
      height: 60px;
      background-color: rgba(0, 21, 114, 0.08);
      padding: 0 12px;
      gap: 5px;
      margin-top: 32px;
    }

    .tag_container_item {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: Pretendard;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: #111;
      gap: 5px;
    }

    .tag_wrap {
      display: flex;
      flex-wrap: wrap;
      gap: 10px 13px;
      margin-top: 41px;
      width: 100%;
    }

    .tag_wrap_item {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      padding: 0px 20px;
      border-radius: 1px;
      background-color: #ebedf4;
      font-family: Pretendard;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: #111;
      gap: 10px;
    }

    .zw3d_procedure_title_wrap {
      display: flex;
      flex-direction: column;
      gap: 19px;
      width: 100%;
      align-items: center;
    }

    .zw3d_procedure_label {
      height: 13px;
      font-family: Pretendard;
      font-size: 17px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 13px;
      letter-spacing: normal;
      text-align: left;
      color: #555;
      width: calc(100% - 80px);
    }

    .zw3d_procedure_title {
      height: 36px;
      font-family: Pretendard;
      font-size: 40px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 36px;
      letter-spacing: normal;
      text-align: left;
      color: #191919;
      width: calc(100% - 80px);
    }

    .zw3d_procedure_wrap {
      display: flex;
      flex-direction: column;
      gap: 36px;
      width: 100%;
      margin: 102px auto 151px;
    }

    .zw3d_procedure_item_wrap {
      display: flex;
      width: calc(100% - 80px);
    }

    .zw3d_procedure_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin: 0 auto;
    }

    .zw3d_procedure_img {
      display: flex;
      align-items: center;
      width: 50%;
    }

    .zw3d_procdure_img_c {
      width: 100%;
    }

    .zw3d_procedure_description_wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50%;
      gap: 33px;
    }

    .zdream_introduce {
      display: flex;
      width: calc(100% - 80px);
      margin: 81px 0px 29px 0px;
      justify-content: space-between;
    }

    .zdream_introduce_card {
      width: 28%;
      height: 305px;
      max-width: 311px;

      padding: 33px 15px 0px 15px;
      border-radius: 5px;
      box-shadow: 4.6px 5.3px 14.7px 1.3px rgba(65, 65, 65, 0.15);
      background-color: #fff;
      text-align: center;
    }

    .zdream_box_img1,
    .zdream_box_img2,
    .zdream_box_img3 {
      width: 100%;
    }

    .zdream_box {
      display: flex;
      width: calc(100% - 80px);
      justify-content: space-between;
      height: auto !important;
    }

    .zdream_line {
      width: 100%;
      height: 1px;
      background-color: #c0c0c0;
      margin: 38px 0px 52px 0px;
    }
    .zdream_img_box {
      width: 55%;
      min-width: 500px;
    }

    .zdrea_box_img {
      width: 100% !important;
      height: auto !important;
    }

    .zdream_contents {
      margin-left: 0px;
      width: 41%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .compare_header {
      width: calc(100% - 80px);
      margin-bottom: 24px;
      font-size: 28px;
      font-family: Pretendard;
    }

    .compare_body {
      width: calc(100% - 80px);
    }

    .contents_box {
      border-bottom: 1px solid #c0c0c0;
      width: 100%;
    }

    .compare_line {
      display: flex;
      width: 100%;
    }

    .compare_content1,
    .compare_content5 {
      width: 40%;
    }

    .compare_content2,
    .compare_content3,
    .compare_content4 {
      width: 30%;
    }

    .listopen,
    .listhidden {
      width: 100%;
    }

    .zdream_detail {
      width: 100%;
    }

    .contents_text {
      width: calc(40% - 37px);
      font-size: 13px;
    }

    .contents_blue,
    .contents_sky,
    .contents_sky2 {
      width: 100%;
    }

    .compare_quote {
      width: calc(100% - 80px);
    }

    .zdream_detail_contents {
      width: 15% !important;
    }

    .compare_list_header {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .compare_line {
      display: flex;
    }

    .compare_content8 {
      width: 40%;
    }

    .contents_text4,
    .contents_text3 {
      width: calc(40% - 57px);
    }

    .compare_content6 {
      width: 57.1%;
    }

    .compare_content7 {
      width: 42.9%;
    }

    .compare_blue3,
    .compare_blue4,
    .compare_sky3,
    .compare_sky4 {
      font-size: 14px;
    }

    .zw3d_compare_list,
    .zw3d_compare_line {
      width: calc(100% - 80px);
    }

    .tite_line {
      width: 100%;
    }

    .smallbox {
      width: 13.97%;
    }

    .bigbox {
      width: 14.62%;
    }

    .zw3d_datalist {
      display: flex;
      width: 60%;
    }
    .zw3d_datalist_header {
      display: flex;
      width: 60%;
    }
    .contents_sky_52,
    .contents_blue_52,
    .contents_blue2,
    .contents_blue3,
    .contents_blue3_52,
    .contents_sky3,
    .contents_sky4 {
      width: 100%;
    }

    .zdream_detail {
      font-size: 15px;
    }

    .text2 {
      font-size: 10px;
    }

    .compare_quote2 {
      width: calc(100% - 80px);
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 39px;
      margin-bottom: 223px;
      font-family: Pretendard;
      font-size: 15px;
      color: #191919;
    }
  }
}

/* 테블릿 가로, 테블릿 세로 (해상도 768px ~ 1024px)*/
@media all and (min-width: 768px) and (max-width: 1024px) {
  .wrapper {
    display: flex;
    width: 100%;
    height: 530px;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
  }

  .background {
    position: absolute;
    width: 100%;
    height: 530px;
    z-index: -1;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    transition: 0.3s;
    opacity: 1;
  }

  .background:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 530px;
  }

  .zwcad_area {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 530px;
  }

  .zwcad_title_wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: calc(100% - 40px);
    height: 100%;
    gap: 24px;
    margin-top: 81px;
  }

  .zwcad_title {
    height: 56px;
    font-family: Pretendard;
    font-size: 34px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 56px;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .zwcad_subtitle {
    height: 20px;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .zwcad_title_btn_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin-top: 36px;
  }

  .zwcad_title_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 134px;
    height: 45px;
    border-radius: 23px;
    border: solid 1px #fff;
    background-color: transparent;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .zwcad_title_btn:last-child {
    background-color: #fff;
    color: #222;
  }

  .zwcad_category {
    display: flex;
    justify-content: center;
    height: 67.5px;
    border-bottom: 1px solid #c0c0c0;
    margin-bottom: 65px;
  }

  .zwcad_category_btn_wrap {
    display: flex;
    align-items: center;
    gap: 60px;
    width: 1280px;
  }

  .zwcad_category_btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    /* width: auto; */
    height: 100%;
    font-family: Pretendard;
    font-size: 19px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .zwcad_category_btn:hover {
    color: #00177c;
  }

  .zwcad_category_btn:after {
    display: block;
    content: "";
    border-bottom: solid 2px #00177c;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    transform-origin: 0% 50%;
  }

  .zwcad_category_btn:hover:after {
    transform: scaleX(1);
  }

  .button_focus {
    border-bottom: 2px solid #00177c;
    color: #00177c;
  }

  .zwcad_feature_list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 30px;
  }

  .zwcad_feature_item_wrap {
    display: flex;
    width: 100%;
    max-width: 660px;
    margin-bottom: 60px;
  }

  .zwcad_feature_item_wrap:global.gray {
    background-color: #eceff7;
  }

  .zwcad_feature_item_wrap:global.gray:last-child {
    padding: 0;
  }

  .zwcad_feature_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 40px);
    margin: 0;
    padding: 20px;
    gap: 30px;
  }

  .zwcad_feature_img {
    width: 100%;
  }

  .zwcad_feature_description_wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .zwcad_feature_index {
    height: 18px;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: normal;
    text-align: left;
    color: #111;
  }

  .zwcad_feature_name {
    margin-top: 22px;
    height: 36px;
    font-family: Pretendard;
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 36px;
    letter-spacing: normal;
    text-align: left;
    color: #001572;
  }

  .zwcad_feature_text {
    margin-top: 30px;
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.7;
    letter-spacing: normal;
    text-align: left;
    color: #111;
    word-break: keep-all;
  }

  .zwcad_feature_text .highlight {
    box-shadow: inset 0 -12px 0 rgba(0, 159, 169, 0.24);
    /* border-bottom: 12px solid rgba(0, 159, 169, 0.24); */
  }

  .zwcad_feature_item_wrap:first-child .zwcad_feature_text {
    width: 100%;
  }

  .zwcad_feature_item_wrap:nth-child(2) .zwcad_feature_text {
    width: 100%;
    margin-bottom: 30px;
  }

  .zwcad_feature_item_wrap:nth-child(3) .zwcad_feature_text {
    width: 100%;
    margin-bottom: 30px;
  }

  .tag_square {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 347px;
    height: 60px;
    background-color: rgba(0, 21, 114, 0.08);
    font-family: Pretendard;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #111;
  }

  .tag_grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .tag_container {
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: 60px; */
    background-color: rgba(0, 21, 114, 0.08);
    padding: 10px 20px;
    gap: 5px;
    margin-top: 32px;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .tag_wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 13px;
    margin-top: 41px;
    width: 100%;
  }

  .tag_list {
    display: flex;
    flex-direction: column;
    gap: 9px;
    margin-top: 32px;
  }

  .tag_grid_item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 0px 20px;
    border-radius: 20px;
    background-color: #ebedf4;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #111;
  }

  .tag_container_item {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #111;
    gap: 10px;
  }

  .tag_wrap_item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 0px 20px;
    border-radius: 1px;
    background-color: #ebedf4;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #111;
    gap: 10px;
  }

  .tag_list_item {
    display: flex;
    align-items: flex-start;
    /* height: 45px; */
    background-color: #eceef4;
    padding: 10px 19px;
    gap: 25px;
  }

  .tag_list_item_label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #111;
    width: 61px;
  }

  .tag_list_item_text {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #111;
  }

  .item_dot {
    border-radius: 100%;
    width: 6px;
    height: 6px;
    background-color: #009fa9;
  }

  .zw3d_info_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 40px);
    padding: 0 20px;
    word-break: auto-phrase;
  }

  .zw3d_info_title {
    font-family: Pretendard;
    font-size: 43px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 39px;
    letter-spacing: normal;
    text-align: center;
    color: #191919;
    height: 39px;
  }

  .zw3d_info_description {
    width: 100%;
    margin-top: 56px;
    text-align: center;
    font-family: Pretendard;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78;
    letter-spacing: normal;
    color: #111;
  }

  .zw3d_info_description .highlight {
    font-weight: 600;
    box-shadow: inset 0 -12px 0 rgba(0, 159, 169, 0.24);
    /* border-bottom: 12px solid rgba(0, 159, 169, 0.24); */
  }

  .zw3d_info_guarantee {
    margin-top: 60px;
    /* height: 17px; */
    font-family: Pretendard;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 17px; */
    letter-spacing: normal;
    text-align: center;
    color: #111;
  }

  .zw3d_info_guarantee > span {
    font-weight: 600;
  }

  .zw3d_procedure_wrap {
    display: flex;
    flex-direction: column;
    gap: 36px;
    /* width: calc(100% - 40px); */
    margin: 100px 0px;
    width: 100%;
    align-items: center;
  }

  .zw3d_procedure_title_wrap {
    display: flex;
    flex-direction: column;
    gap: 19px;
    align-items: center;
  }

  .zw3d_procedure_label {
    height: 13px;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 13px;
    letter-spacing: normal;
    text-align: left;
    color: #555;
  }

  .zw3d_procedure_title {
    height: 36px;
    font-family: Pretendard;
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 36px;
    letter-spacing: normal;
    text-align: left;
    color: #191919;
  }

  .zw3d_procedure_title > span {
    color: #001572;
  }

  .zw3d_prodcedure_list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 660px;
    gap: 40px;
  }

  .zw3d_procedure_item_wrap {
    display: flex;
    width: 100%;
  }

  .zw3d_procedure_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    gap: 30px;
  }

  .zw3d_procedure_img {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .zw3d_procdure_img_c {
    width: 100%;
  }

  .zw3d_procedure_description_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 33px;
  }

  .zw3d_procedure_item_index {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;
    background-color: #001572;
    font-family: Poppins;
    font-size: 17.5px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    border-radius: 50%;
  }

  .zw3d_procedure_item_title_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 11px;
  }

  .zw3d_procedure_item_sub_title {
    height: 16px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 16px;
    letter-spacing: -0.16px;
    text-align: center;
    color: #009fa9;
  }

  .zw3d_procedure_item_title {
    height: 31px;
    font-family: Pretendard;
    font-size: 35px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 31px;
    letter-spacing: normal;
    text-align: center;
    color: #191919;
  }

  .zw3d_procedure_item_text {
    margin-top: 12px;
    text-align: center;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.7;
    letter-spacing: normal;
    text-align: center;
    color: #191919;
  }

  .zdream_introduce_wrap {
    display: flex;
    justify-content: center;
  }

  .zdream_introduce {
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);
    margin: 60px 20px 30px;
    justify-content: space-between;
    gap: 40px;
    align-items: center;
  }

  .zdream_introduce_card {
    width: calc(100% - 60px);
    max-width: 660px;
    /* height: 305px; */
    padding: 30px 0px;
    border-radius: 5px;
    box-shadow: 4.6px 5.3px 14.7px 1.3px rgba(65, 65, 65, 0.15);
    background-color: #fff;
    text-align: center;
  }

  .zdream_introduce_txt,
  .zdream_introduce_txt2 {
    text-align: center;
    font-size: 26px;
    font-family: Pretendard;
    font-weight: 500;
    color: #111;
    height: 26px;
    line-height: 26px;
  }

  .zdream_introduce_txt2 {
    margin-top: 8px;
  }

  .zdream_introduce_txt_color {
    color: #009fa9;
    font-weight: 600;
  }

  .zdream_box_img1 {
    margin-top: 38px;
  }

  .zdream_box_img2 {
    margin-top: 22px;
  }

  .zdream_box_img3 {
    margin-top: 31px;
  }

  .zdream_list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 60px;
  }

  .zdream_box {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto !important;
    gap: 20px;
    max-width: 660px;
  }

  .zdream_img_box {
    width: 100%;
  }

  .zdrea_box_img {
    width: 100% !important;
    height: auto !important;
  }

  .zdream_contents {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .zdream_box_title {
    font-family: Pretendard;
    font-size: 35px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #009fa9;
  }

  .zdream_line {
    width: 100%;
    height: 1px;
    background-color: #c0c0c0;
    margin: 20px 0px 40px 0px;
  }

  .zdream_details {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }

  .zdream_detail {
    display: flex;
    font-family: Pretendard;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #191919;
    align-items: center;
    width: 100%;
  }

  .zdream_box_dot {
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background-color: #009fa9;
    margin-right: 13px;
  }

  .compare_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .compare_header {
    width: calc(100% - 40px);
    margin-bottom: 24px;
    font-size: 20px;
    font-family: Pretendard;
  }
  .compare_body {
    width: calc(100% - 40px);
  }

  .ch_1 {
    font-weight: bold;
  }

  .ch_2 {
    color: #009fa9;
    font-weight: bold;
  }

  .compare_line {
    display: flex;
  }

  .zw3d_compare_line {
    display: flex;
    width: calc(100% - 40px);
  }

  .compare_content1 {
    width: 40%;
    height: 41px;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #111111;
    background-color: #f1f1f1;
    line-height: 41px;
    border-right: 1px solid white;
  }

  .compare_content2 {
    width: 30%;
    height: 41px;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    background-color: #001572;
    line-height: 41px;
    border-right: 1px solid white;
  }

  .compare_content3 {
    width: 30%;
    height: 41px;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    background-color: #9aa6dd;
    line-height: 41px;
  }

  .compare_content4 {
    display: flex;
    width: 30%;
  }

  .compare_content5 {
    width: 40%;
    height: 41px;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #111111;
    background-color: #f1f1f1;
    line-height: 41px;
    border-top: 2px solid white;
    border-right: 1px solid white;
  }

  .compare_blue1 {
    background-color: #001572;
    width: 50%;
    border-right: 1px solid white;
    border-top: 2px solid white;
  }

  .compare_blue2 {
    background-color: #001572;
    width: 50%;
    border-top: 2px solid white;
    border-right: 1px solid white;
  }

  .compare_sky1 {
    background-color: #9aa6dd;
    width: 50%;
    border-right: 1px solid white;
    border-top: 2px solid white;
  }

  .compare_sky2 {
    background-color: #9aa6dd;
    width: 50%;
    border-top: 2px solid white;
  }

  .compare_blue1,
  .compare_blue2,
  .compare_sky1,
  .compare_sky2 {
    text-align: center;
    line-height: 41px;
    font-family: Pretendard;
    font-size: 14px;
    color: #fff;
  }

  .listopen {
    min-height: 200px;
    max-height: 1400px;
    transition: all 0.5s ease-in-out;
  }

  .listhidden {
    min-height: 0px;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
  }

  .tite_line {
    display: flex;
    width: 100%;
    justify-content: space-between;
    min-height: 65px;
    align-items: center;
  }

  .title_text {
    display: flex;
  }

  .title_index {
    margin-left: 12px;
    margin-right: 10px;
    width: 28px;
    height: 28px;
    text-align: center;
    line-height: 32px;
    background-color: #f3f3f3;
    border-radius: 2px;
  }

  .title_name {
    height: 32px;
    font-family: Pretendard;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 32px;
    letter-spacing: normal;
    text-align: left;
    color: #191919;
    letter-spacing: -0.3px;
  }

  .title_arrow {
    margin-right: 11px;
  }

  .compare_list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .contents_text {
    width: calc(40% - 13px);
    height: 48px;
    padding-left: 13px;
    background-color: #f3f3f3;
    text-align: left;
    line-height: 48px;
    margin-right: 1px;
    white-space: pre-wrap;
  }

  .contents_blue {
    background-color: #001572;
    width: 100%;
    border-right: 1px solid white;
  }

  .contents_sky {
    background-color: #9aa6dd;
    width: 100%;
    border-right: 1px solid white;
  }

  .contents_sky2 {
    background-color: #9aa6dd;
    width: 100%;
  }

  .contents_blue,
  .contents_sky,
  .contents_sky2 {
    text-align: center;
    line-height: 57px;
    font-family: Pretendard;
    font-size: 17px;
    color: #fff;
    height: 48px;
  }

  .contents_box {
    border-bottom: 1px solid #c0c0c0;
    width: 100%;
  }

  .compare_quote {
    width: calc(100% - 40px);
    text-align: end;
    margin-top: 39px;
    margin-bottom: 223px;
    font-family: Pretendard;
    font-size: 10px;
    color: #191919;
  }

  .compare_quote2 {
    width: calc(100% - 40px);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 30px;
    margin-bottom: 80px;
    font-family: Pretendard;
    font-size: 12px;
    color: #191919;
  }

  .quote2_box {
    font-family: Pretendard;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #191919;

    width: 124px;
    height: 62px;

    padding: 14px 14px;
    border: solid 1px #e1e1e1;
    display: flex;
    flex-direction: column;
    row-gap: 9px;
    margin-top: 14px;
  }

  .quote2_box_line {
    display: flex;
    gap: 6px;
  }

  .quote2_check {
    height: 12px;
    width: 14px;
  }

  .quote2_circle {
    width: 14px;
    height: 14px;
    background-color: #000000;
    border-radius: 15px;
    margin-right: 4px;
  }

  .quote2_empty {
    width: 12px;
    height: 12px;
    border: 1px solid #000000;
    border-radius: 15px;
    margin-right: 4px;
  }

  .compare_content8 {
    width: 40%;
    height: 60px;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #111111;
    background-color: #f1f1f1;
    line-height: 60px;
    border-top: 2px solid white;
    border-right: 1px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .compare_content6 {
    height: 60px;
    font-family: Pretendard;
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    background-color: #001572;
    border-right: 1px solid white;
    display: flex;
    width: 57.5%;
  }
  .compare_content7 {
    height: 60px;
    font-family: Pretendard;
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    background-color: #9aa6dd;
    display: flex;
    width: 42.5%;
  }

  .compare_blue3 {
    background-color: #001572;
    width: 24.9%;
    border-right: 1px solid white;
    border-top: 2px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 60px;
  }

  .compare_blue4 {
    background-color: #001572;
    width: 25.3%;
    border-top: 2px solid white;
    border-right: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 70px;
  }

  .compare_sky3 {
    background-color: #9aa6dd;
    width: 33%;
    border-right: 1px solid white;
    border-top: 2px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 60px;
  }

  .compare_sky4 {
    background-color: #9aa6dd;

    border-top: 2px solid white;
  }

  .compare_blue3,
  .compare_blue4,
  .compare_sky3,
  .compare_sky4 {
    font-family: Pretendard;
    font-size: 10px;
    color: #fff;
  }

  .text2 {
    font-size: 14px;
    letter-spacing: -0.14px;
    margin-top: 3px;
    font-family: Pretendard;
  }

  .contents_text2 {
    width: 475px;
    height: 48px;
    padding-left: 37px;
    background-color: #f3f3f3;
    text-align: left;
    line-height: 48px;
    margin-right: 1px;
  }

  .contents_text3 {
    width: calc(40% - 23px);
    height: 48px;
    padding-left: 13px;
    padding-right: 10px;
    background-color: #f3f3f3;
    text-align: left;
    line-height: 48px;
    margin-right: 1px;
    display: flex;
    justify-content: space-between;
  }

  .contents_text4 {
    width: calc(40% - 23px);
    height: 52px;
    padding-left: 13px;
    padding-right: 10px;
    background-color: #f3f3f3;
    text-align: left;
    margin-right: 1px;
    display: flex;
    justify-content: space-between;
  }

  .contents_youtube {
    display: flex;
    gap: 5px;
    align-items: center;
  }
  .zw3d_datalist {
    display: flex;
    width: 60%;
    overflow-x: scroll;
    -ms-overflow-style: none; /* 인터넷 익스플로러 */
    scrollbar-width: none; /* 파이어폭스 */
  }
  .zw3d_datalist::-webkit-scrollbar {
    /* display: none; */
  }

  .zw3d_datalist_header {
    display: flex;
    overflow-x: auto;
    width: 60%;
  }

  .contents_blue2 {
    background-color: #001572;
    width: 100%;
    border-right: 1px solid white;
  }

  .contents_blue3 {
    background-color: #001572;
    width: 100%;
    border-right: 1px solid white;
  }

  .contents_sky3 {
    background-color: #9aa6dd;
    width: 100%;
    border-right: 1px solid white;
  }

  .contents_blue2,
  .contents_blue3,
  .contents_sky3,
  .contents_sky4 {
    text-align: center;
    line-height: 48px;
    font-family: Pretendard;
    font-size: 17px;
    color: #fff;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .contents_blue_52 {
    text-align: center;
    line-height: 52px;
    font-family: Pretendard;
    font-size: 17px;
    color: #fff;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #001572;
    width: 100%;
    border-right: 1px solid white;
  }

  .contents_blue3_52 {
    text-align: center;
    line-height: 52px;
    font-family: Pretendard;
    font-size: 17px;
    color: #fff;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #001572;
    width: 100%;
    border-right: 1px solid white;
  }

  .contents_sky_52 {
    text-align: center;
    line-height: 52px;
    font-family: Pretendard;
    font-size: 17px;
    color: #fff;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #9aa6dd;
    width: 100%;
    border-right: 1px solid white;
  }

  .contents_box {
    border-bottom: 1px solid #c0c0c0;
  }
  .wid15 {
    width: 15%;
  }
  .compare_list_header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .zw3d_compare_list {
    width: calc(100% - 40px);
  }
  .smallbox {
    width: 24.9%;
    min-width: 60px;
  }
  .bigbox {
    width: 25.3%;
    min-width: 70px;
  }

  .zw3d_false {
  }
  .zw3d_true {
    width: 14px;
    height: 14px;
    border-radius: 14px;
    background-color: #fff;
  }
  .zw3d_empty {
    width: 10px;
    height: 10px;
    border-radius: 12px;
    background-color: none;
  }
}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width: 767px) {
  .wrapper {
    display: flex;
    width: 100%;
    height: 530px;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
  }

  .background {
    position: absolute;
    width: 100%;
    height: 530px;
    z-index: -1;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    transition: 0.3s;
    opacity: 1;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .background:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 530px;
  }

  .zwcad_area {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 530px;
  }

  .zwcad_title_wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: calc(100% - 40px);
    height: 100%;
    gap: 24px;
    margin-top: 81px;
  }

  .zwcad_title {
    height: 56px;
    font-family: Pretendard;
    font-size: 34px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 56px;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    margin-top: 80px;
  }

  .zwcad_subtitle {
    height: 20px;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    margin-bottom: 52px;
  }

  .zwcad_title_btn_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin-top: 36px;
    width: 136px;
    height: 109px;
    margin-bottom: 54px;
  }

  .zwcad_title_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 134px;
    height: 45px;
    border-radius: 23px;
    border: solid 1px #fff;
    background-color: transparent;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .zwcad_title_btn:last-child {
    background-color: #fff;
    color: #222;
  }

  .zwcad_category {
    display: flex;
    justify-content: center;
    height: 67.5px;
    border-bottom: 1px solid #c0c0c0;
    margin-bottom: 65px;
  }

  .zwcad_category_btn_wrap {
    display: flex;
    align-items: center;
    gap: 60px;
    width: 1280px;
  }

  .zwcad_category_btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    /* width: auto; */
    height: 100%;
    font-family: Pretendard;
    font-size: 19px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .zwcad_category_btn:hover {
    color: #00177c;
  }

  .zwcad_category_btn:after {
    display: block;
    content: "";
    border-bottom: solid 2px #00177c;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    transform-origin: 0% 50%;
  }

  .zwcad_category_btn:hover:after {
    transform: scaleX(1);
  }

  .button_focus {
    border-bottom: 2px solid #00177c;
    color: #00177c;
  }

  .zwcad_feature_list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 30px;
    margin-bottom: 70px;
  }

  .zwcad_feature_item_wrap {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .zwcad_feature_item_wrap:global.gray {
    background-color: #eceff7;
  }

  .zwcad_feature_item_wrap:global.gray:last-child {
    padding: 0;
  }

  .zwcad_feature_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 40px);
    margin: 0;
    padding: 20px 0px;
    gap: 30px;
  }

  .zwcad_feature_img {
    width: 100%;
  }

  .zwcad_feature_description_wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .zwcad_feature_index {
    height: 18px;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: normal;
    text-align: left;
    color: #111;
  }

  .zwcad_feature_name {
    margin-top: 22px;
    height: 36px;
    font-family: Pretendard;
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 36px;
    letter-spacing: normal;
    text-align: left;
    color: #001572;
  }

  .zwcad_feature_text {
    margin-top: 30px;
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.7;
    letter-spacing: normal;
    text-align: left;
    color: #111;
    word-break: keep-all;
  }

  .zwcad_feature_text .highlight {
    box-shadow: inset 0 -12px 0 rgba(0, 159, 169, 0.24);
    /* border-bottom: 12px solid rgba(0, 159, 169, 0.24); */
  }

  .zwcad_feature_item_wrap:first-child .zwcad_feature_text {
    width: 100%;
  }

  .zwcad_feature_item_wrap:nth-child(2) .zwcad_feature_text {
    width: 100%;
    margin-bottom: 30px;
  }

  .zwcad_feature_item_wrap:nth-child(3) .zwcad_feature_text {
    width: 100%;
    margin-bottom: 30px;
  }

  .tag_square {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
    background-color: rgba(0, 21, 114, 0.08);
    font-family: Pretendard;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #111;
  }

  .tag_grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .tag_container {
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: 60px; */
    background-color: rgba(0, 21, 114, 0.08);
    padding: 10px 20px;
    gap: 5px;
    margin-top: 32px;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .tag_wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 13px;
    margin-top: 41px;
    width: 100%;
  }

  .tag_list {
    display: flex;
    flex-direction: column;
    gap: 9px;
    margin-top: 32px;
  }

  .tag_grid_item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 0px 20px;
    border-radius: 20px;
    background-color: #ebedf4;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #111;
  }

  .tag_container_item {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #111;
    gap: 10px;
  }

  .tag_wrap_item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 0px 20px;
    border-radius: 1px;
    background-color: #ebedf4;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #111;
    gap: 10px;
  }

  .tag_list_item {
    display: flex;
    align-items: flex-start;
    /* height: 45px; */
    background-color: #eceef4;
    padding: 10px 19px;
    gap: 25px;
  }

  .tag_list_item_label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #111;
    width: 61px;
  }

  .tag_list_item_text {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #111;
  }

  .item_dot {
    border-radius: 100%;
    width: 6px;
    height: 6px;
    background-color: #009fa9;
  }

  .zw3d_info_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 40px);
    padding: 0 20px;
    word-break: auto-phrase;
  }

  .zw3d_info_title {
    font-family: Pretendard;
    font-size: 43px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 39px;
    letter-spacing: normal;
    text-align: center;
    color: #191919;
    height: 39px;
  }

  .zw3d_info_description {
    width: 100%;
    margin-top: 56px;
    text-align: center;
    font-family: Pretendard;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78;
    letter-spacing: normal;
    color: #111;
  }

  .zw3d_info_description .highlight {
    font-weight: 600;
    box-shadow: inset 0 -12px 0 rgba(0, 159, 169, 0.24);
    /* border-bottom: 12px solid rgba(0, 159, 169, 0.24); */
  }

  .zw3d_info_guarantee {
    margin-top: 60px;
    /* height: 17px; */
    font-family: Pretendard;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 17px; */
    letter-spacing: normal;
    text-align: center;
    color: #111;
  }

  .zw3d_info_guarantee > span {
    font-weight: 600;
  }

  .zw3d_procedure_wrap {
    display: flex;
    flex-direction: column;
    gap: 36px;
    width: calc(100% - 40px);
    margin: 100px 20px;
  }

  .zw3d_procedure_title_wrap {
    display: flex;
    flex-direction: column;
    gap: 19px;
  }

  .zw3d_procedure_label {
    height: 13px;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 13px;
    letter-spacing: normal;
    text-align: left;
    color: #555;
  }

  .zw3d_procedure_title {
    height: 36px;
    font-family: Pretendard;
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 36px;
    letter-spacing: normal;
    text-align: left;
    color: #191919;
  }

  .zw3d_procedure_title > span {
    color: #001572;
  }

  .zw3d_prodcedure_list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 40px;
  }

  .zw3d_procedure_item_wrap {
    display: flex;
    width: 100%;
  }

  .zw3d_procedure_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    gap: 30px;
  }

  .zw3d_procedure_img {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .zw3d_procdure_img_c {
    width: 100%;
  }

  .zw3d_procedure_description_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 33px;
  }

  .zw3d_procedure_item_index {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;
    background-color: #001572;
    font-family: Poppins;
    font-size: 17.5px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    border-radius: 50%;
  }

  .zw3d_procedure_item_title_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 11px;
  }

  .zw3d_procedure_item_sub_title {
    height: 16px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 16px;
    letter-spacing: -0.16px;
    text-align: center;
    color: #009fa9;
  }

  .zw3d_procedure_item_title {
    height: 31px;
    font-family: Pretendard;
    font-size: 35px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 31px;
    letter-spacing: normal;
    text-align: center;
    color: #191919;
  }

  .zw3d_procedure_item_text {
    margin-top: 12px;
    text-align: center;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.7;
    letter-spacing: normal;
    text-align: center;
    color: #191919;
  }

  .zdream_introduce_wrap {
    display: flex;
    justify-content: center;
  }

  .zdream_introduce {
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);
    margin: 60px 20px 30px;
    justify-content: space-between;
    gap: 40px;
  }

  .zdream_introduce_card {
    width: calc(100% - 60px);
    /* height: 305px; */

    padding: 30px;
    border-radius: 5px;
    box-shadow: 4.6px 5.3px 14.7px 1.3px rgba(65, 65, 65, 0.15);
    background-color: #fff;
    text-align: center;
  }

  .zdream_introduce_txt,
  .zdream_introduce_txt2 {
    text-align: center;
    font-size: 26px;
    font-family: Pretendard;
    font-weight: 500;
    color: #111;
    height: 26px;
    line-height: 26px;
  }

  .zdream_introduce_txt2 {
    margin-top: 8px;
  }

  .zdream_introduce_txt_color {
    color: #009fa9;
    font-weight: 600;
  }

  .zdream_box_img1 {
    margin-top: 38px;
  }

  .zdream_box_img2 {
    margin-top: 22px;
    width: 100%;
  }

  .zdream_box_img3 {
    margin-top: 31px;
    width: 100%;
  }

  .zdream_list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 60px;
  }

  .zdream_box {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto !important;
    gap: 20px;
  }

  .zdream_img_box {
    width: 100%;
  }

  .zdrea_box_img {
    width: 100% !important;
    height: auto !important;
  }

  .zdream_contents {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .zdream_box_title {
    font-family: Pretendard;
    font-size: 35px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #009fa9;
  }

  .zdream_line {
    width: 100%;
    height: 1px;
    background-color: #c0c0c0;
    margin: 20px 0px 40px 0px;
  }

  .zdream_details {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }

  .zdream_detail {
    display: flex;
    font-family: Pretendard;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #191919;
    align-items: center;
    width: 100%;
    letter-spacing: -0.2px;
  }

  .zdream_box_dot {
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background-color: #009fa9;
    margin-right: 13px;
  }

  .compare_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .compare_header {
    width: calc(100% - 40px);
    margin-bottom: 24px;
    font-size: 20px;
    font-family: Pretendard;
  }
  .compare_body {
    width: calc(100% - 40px);
  }

  .ch_1 {
    font-weight: bold;
  }

  .ch_2 {
    color: #009fa9;
    font-weight: bold;
  }

  .compare_line {
    display: flex;
  }

  .zw3d_compare_line {
    display: flex;
    width: calc(100% - 40px);
  }

  .compare_content1 {
    width: 40%;
    height: 41px;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #111111;
    background-color: #f1f1f1;
    line-height: 41px;
    border-right: 1px solid white;
  }

  .compare_content2 {
    width: 30%;
    height: 41px;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    background-color: #001572;
    line-height: 41px;
    border-right: 1px solid white;
  }

  .compare_content3 {
    width: 30%;
    height: 41px;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    background-color: #9aa6dd;
    line-height: 41px;
  }

  .compare_content4 {
    display: flex;
    width: 30%;
  }

  .compare_content5 {
    width: 40%;
    height: 41px;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #111111;
    background-color: #f1f1f1;
    line-height: 41px;
    border-top: 2px solid white;
    border-right: 1px solid white;
  }

  .compare_blue1 {
    background-color: #001572;
    width: 50%;
    border-right: 1px solid white;
    border-top: 2px solid white;
  }

  .compare_blue2 {
    background-color: #001572;
    width: 50%;
    border-top: 2px solid white;
    border-right: 1px solid white;
  }

  .compare_sky1 {
    background-color: #9aa6dd;
    width: 50%;
    border-right: 1px solid white;
    border-top: 2px solid white;
  }

  .compare_sky2 {
    background-color: #9aa6dd;
    width: 50%;
    border-top: 2px solid white;
  }

  .compare_blue1,
  .compare_blue2,
  .compare_sky1,
  .compare_sky2 {
    text-align: center;
    line-height: 41px;
    font-family: Pretendard;
    font-size: 14px;
    color: #fff;
  }

  .listopen {
    min-height: 200px;
    max-height: 2500px;
    transition: all 0.5s ease-in-out;
  }

  .listhidden {
    min-height: 0px;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
  }

  .tite_line {
    display: flex;
    width: 100%;
    justify-content: space-between;
    min-height: 65px;
    align-items: center;
  }

  .title_text {
    display: flex;
  }

  .title_index {
    margin-left: 12px;
    margin-right: 10px;
    width: 28px;
    height: 28px;
    text-align: center;
    line-height: 32px;
    background-color: #f3f3f3;
    border-radius: 2px;
  }

  .title_name {
    height: 32px;
    font-family: Pretendard;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: normal;
    text-align: left;
    color: #191919;
    letter-spacing: -0.3px;
    display: flex;
    align-items: center;
  }

  .title_arrow {
    margin-right: 11px;
  }

  .compare_list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .contents_text {
    width: calc(40% - 14px);
    min-height: 48px;
    padding: 10px 7px 10px 7px;
    background-color: #f3f3f3;
    text-align: left;
    margin-right: 1px;
    white-space: pre-wrap;
    line-height: 15px;
    display: flex;
    align-items: center;
  }

  .contents_blue {
    background-color: #001572;
    width: 100%;
    border-right: 1px solid white;
  }

  .contents_sky {
    background-color: #9aa6dd;
    width: 100%;
    border-right: 1px solid white;
  }

  .contents_sky2 {
    background-color: #9aa6dd;
    width: 100%;
  }

  .contents_blue,
  .contents_sky,
  .contents_sky2 {
    text-align: center;
    line-height: 57px;
    font-family: Pretendard;
    font-size: 17px;
    color: #fff;
    height: 100%;
    padding: 5px 0px;
  }

  .contents_box {
    border-bottom: 1px solid #c0c0c0;
    width: 100%;
  }

  .compare_quote {
    width: calc(100% - 40px);
    text-align: end;
    margin-top: 39px;
    margin-bottom: 80px;
    font-family: Pretendard;
    font-size: 10px;
    color: #191919;
  }

  .compare_quote2 {
    width: calc(100% - 40px);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 30px;
    margin-bottom: 80px;
    font-family: Pretendard;
    font-size: 12px;
    color: #191919;
  }

  .quote2_box {
    font-family: Pretendard;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #191919;

    width: 124px;
    height: 62px;

    padding: 14px 14px;
    border: solid 1px #e1e1e1;
    display: flex;
    flex-direction: column;
    row-gap: 9px;
    margin-top: 14px;
  }

  .quote2_box_line {
    display: flex;
    gap: 6px;
  }

  .quote2_check {
    height: 12px;
    width: 14px;
  }

  .quote2_circle {
    width: 14px;
    height: 14px;
    background-color: #000000;
    border-radius: 15px;
    margin-right: 4px;
  }

  .quote2_empty {
    width: 12px;
    height: 12px;
    border: 1px solid #000000;
    border-radius: 15px;
    margin-right: 4px;
  }

  .compare_content8 {
    width: 40%;
    height: 60px;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #111111;
    background-color: #f1f1f1;
    line-height: 60px;
    border-top: 2px solid white;
    border-right: 1px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 145px;
  }

  .compare_content6 {
    height: 60px;
    font-family: Pretendard;
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    background-color: #001572;
    border-right: 1px solid white;
    display: flex;
  }

  .compare_blue3 {
    background-color: #001572;
    width: 24.9%;
    border-right: 1px solid white;
    border-top: 2px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 60px;
  }

  .compare_blue4 {
    background-color: #001572;
    width: 25.3%;
    border-top: 2px solid white;
    border-right: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 70px;
  }

  .compare_content7 {
    height: 60px;
    font-family: Pretendard;
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    background-color: #9aa6dd;
    display: flex;
  }

  .compare_sky3 {
    background-color: #9aa6dd;
    width: 33%;
    border-right: 1px solid white;
    border-top: 2px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 60px;
  }

  .compare_sky4 {
    background-color: #9aa6dd;

    border-top: 2px solid white;
  }

  .compare_blue3,
  .compare_blue4,
  .compare_sky3,
  .compare_sky4 {
    font-family: Pretendard;
    font-size: 10px;
    color: #fff;
  }

  .text2 {
    font-size: 11px;
    letter-spacing: -0.14px;
    margin-top: 3px;
    font-family: Pretendard;
  }

  .contents_text2 {
    width: 475px;
    min-height: 48px;
    padding-left: 37px;
    background-color: #f3f3f3;
    text-align: left;
    line-height: 48px;
    margin-right: 1px;
  }

  .contents_text3 {
    width: calc(40% - 23px);
    min-height: 48px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #f3f3f3;
    text-align: left;
    line-height: 20px;
    margin-right: 1px;
    display: flex;
    justify-content: space-between;
    min-width: 125px;
    align-items: center;
  }

  .contents_text4 {
    width: calc(40% - 23px);
    min-height: 48px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #f3f3f3;
    text-align: left;
    margin-right: 1px;
    display: flex;
    justify-content: space-between;
    min-width: 125px;
    align-items: center;
  }

  .contents_youtube {
    display: flex;
    gap: 5px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .zw3d_datalist {
    display: flex;
    width: 60%;
    overflow-x: scroll;
    -ms-overflow-style: none; /* 인터넷 익스플로러 */
    scrollbar-width: none; /* 파이어폭스 */
  }
  .zw3d_datalist::-webkit-scrollbar {
    /* display: none; */
  }

  .zw3d_datalist_header {
    display: flex;
    overflow-x: auto;
    width: 60%;
  }

  .contents_blue2 {
    background-color: #001572;
    width: 100%;
    border-right: 1px solid white;
  }

  .contents_blue3 {
    background-color: #001572;
    width: 100%;
    border-right: 1px solid white;
  }

  .contents_sky3 {
    background-color: #9aa6dd;
    width: 100%;
    border-right: 1px solid white;
  }

  .contents_blue2,
  .contents_blue3,
  .contents_sky3,
  .contents_sky4 {
    text-align: center;
    line-height: 48px;
    font-family: Pretendard;
    font-size: 17px;
    color: #fff;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0px;
  }

  .contents_blue_52 {
    text-align: center;
    line-height: 52px;
    font-family: Pretendard;
    font-size: 17px;
    color: #fff;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #001572;
    width: 100%;
    border-right: 1px solid white;
    height: 52px;
    padding: 5px 0px;
  }

  .contents_blue3_52 {
    text-align: center;
    line-height: 52px;
    font-family: Pretendard;
    font-size: 17px;
    color: #fff;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #001572;
    width: 100%;
    border-right: 1px solid white;
    height: 52px;
    padding: 5px 0px;
  }

  .contents_sky_52 {
    text-align: center;
    line-height: 52px;
    font-family: Pretendard;
    font-size: 17px;
    color: #fff;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #9aa6dd;
    width: 100%;
    border-right: 1px solid white;
    padding: 5px 0px;
  }

  @media all and (max-width: 563px) {
    .contents_blue_52 {
      text-align: center;
      line-height: 52px;
      font-family: Pretendard;
      font-size: 17px;
      color: #fff;
      height: 52px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #001572;
      width: 100%;
      border-right: 1px solid white;
      height: 52px;
      padding: 8.5px 0px;
    }

    .contents_blue3_52 {
      text-align: center;
      line-height: 52px;
      font-family: Pretendard;
      font-size: 17px;
      color: #fff;
      height: 52px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #001572;
      width: 100%;
      border-right: 1px solid white;
      height: 52px;
      padding: 8.5px 0px;
    }

    .contents_sky_52 {
      text-align: center;
      line-height: 52px;
      font-family: Pretendard;
      font-size: 17px;
      color: #fff;
      height: 52px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #9aa6dd;
      width: 100%;
      border-right: 1px solid white;
      padding: 8.5px 0px;
    }
  }
  @media all and (max-width: 416px) {
    .contents_blue_52 {
      text-align: center;
      line-height: 52px;
      font-family: Pretendard;
      font-size: 17px;
      color: #fff;
      height: 52px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #001572;
      width: 100%;
      border-right: 1px solid white;
      height: 52px;
      padding: 13px 0px;
    }

    .contents_blue3_52 {
      text-align: center;
      line-height: 52px;
      font-family: Pretendard;
      font-size: 17px;
      color: #fff;
      height: 52px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #001572;
      width: 100%;
      border-right: 1px solid white;
      height: 52px;
      padding: 13px 0px;
    }

    .contents_sky_52 {
      text-align: center;
      line-height: 52px;
      font-family: Pretendard;
      font-size: 17px;
      color: #fff;
      height: 52px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #9aa6dd;
      width: 100%;
      border-right: 1px solid white;
      padding: 13px 0px;
    }
  }

  .contents_box {
    border-bottom: 1px solid #c0c0c0;
  }
  .wid15 {
    width: 15%;
    height: 100%;
  }
  .compare_list_header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .zw3d_compare_list {
    width: calc(100% - 40px);
  }
  .smallbox {
    width: 24.9%;
    min-width: 60px;
  }
  .bigbox {
    width: 25.3%;
    min-width: 70px;
  }

  .zw3d_false {
  }
  .zw3d_true {
    width: 14px;
    height: 14px;
    border-radius: 14px;
    background-color: #fff;
  }
  .zw3d_empty {
    width: 10px;
    height: 10px;
    border-radius: 12px;
    background-color: none;
  }
}
