.wrap {
  /* overflow-y: auto;
  height: 100%; */
  width: 100%;
  /* height: 100%; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: flex-start;
  flex-direction: column;
  position: absolute;
  /* overflow-x: hidden; */
}

.sidemenu_wrapper {
  margin-top: 81px;
  z-index: 999;
  display: flex;
  width: 100%;
  height: calc(100vh - 82px);
  height: calc(100dvh - 82px);
  position: fixed;
  background-color: #fff;
  justify-content: space-between;
  flex-direction: column;
}

.sidemenu_area {
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  height: calc(100% - 89px);
  margin: 20px 30px 0 20px;
  overflow-y: auto;
}

.sidemenu_item {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sidemenu_item_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 9px);
  padding: 19px 9px 19px 0;
}

.sidemenu_item_label {
  font-family: Pretendard;
  font-size: 19px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: normal;
  text-align: left;
  color: #111;
}

.sidemenu_item_active .sidemenu_item_title {
  border-bottom: 1px solid #001572;
}

.sidemenu_item_active .sidemenu_item_label {
  color: #001572;
}

.sidemenu_item_arrow {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidemenu_item_arrow>img {
  transition: 0.5s;
}

.sidemenu_item_active .sidemenu_item_arrow>img {
  transition: 0.5s;
  filter: invert(11%) sepia(54%) saturate(5649%) hue-rotate(215deg) brightness(85%) contrast(127%);
  transform: rotate(180deg);
}

@keyframes dropdown {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

.sidemenu_submenu_list {
  display: none;
}

.sidemenu_item_active .sidemenu_submenu_list {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid #e1e1e1;
}

.sidemenu_submenu_item {
  display: flex;
  align-items: center;
  width: calc(100% - 13.5px);
  padding: 12px 0 12px 13.5px;
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #111;
  transition: 0.4s ease;
  animation: dropdown 0.4s ease;
}

.sidemenu_submenu_item:hover,
.sidemenu_submenu_item:focus,
.sidemenu_submenu_item:active {
  background-color: #f8f8f8;
}

.sidemenu_footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 19px;
  padding: 20px 0;
  background-color: #021122;
}

.sidemenu_footer>span {
  width: 4px;
  height: 19px;
  background-color: rgba(255, 255, 255, 0.4);
}

.sidemenu_footer_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(50% - 4px);
  height: 100%;
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  cursor: pointer;
}

@media all and (min-width: 1025px) {
  .header {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    background-color: transparent;
    top: 0;
    transition: all 0.3s ease-in-out;
  }

  .scrollHeader {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    top: 0;
    transition: all 0.3s ease-in-out;
  }

  .header_main {
    display: flex;
    width: 100%;
    height: 80px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ffffff;
    transition: all 0.3s ease-in-out;
  }

  .scrollHeader .header_main {
    background-color: #fff;
    border-bottom: 1px solid #e1e1e1;
    transition: all 0.3s ease-in-out;
  }

  .header_sub {
    display: flex;
    width: 100%;
    height: 370px;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(0, 7, 19, 0.8);
    transition: all 0.3s ease-in-out;
  }

  .header_left_area {
    padding-left: 20px;
    /* width: 253px; */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header_center_area {
    width: calc(100% - 531px);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* gap: 125px; */
  }

  .menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 30px;
    height: 100%;
    position: relative;
  }

  .menu>span {
    font-family: Pretendard;
    font-size: 21px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    padding: 5px 0px;
    height: 30px;
    line-height: 35px;
    white-space: nowrap;
  }

  .scrollHeader .menu>span {
    color: #000;
  }

  .menu>span:hover {
    /* color: #608aff; */
  }

  .menu>span:after {
    display: block;
    content: "";
    border-bottom: solid 2px #00177c;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    transform-origin: 0% 50%;
  }

  .menu>span:hover:after {
    transform: scaleX(1);
  }

  .sub_menu {
    position: absolute;
    display: flex;
    top: 80px;
    padding: 20px 0;
    /* width: 100%; */
    justify-content: flex-start;
    gap: 30px;
  }

  .sub_menu2 {
    position: absolute;
    display: flex;
    top: 80px;
    padding: 20px 0;
    right: -176px;
    /* width: 100%; */
    justify-content: flex-start;
    gap: 10px;
  }

  .sub_menu3 {
    position: absolute;
    display: flex;
    top: 80px;
    padding: 20px 0;
    right: 10px;
    /* width: 100%; */
    justify-content: flex-start;
    gap: 80px;
  }

  .sub_menu_btn_group {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 80px;
    right: 193px;
    padding: 30px 0;
    width: 100%;
    justify-content: flex-start;
    gap: 10px;
    cursor: pointer;
  }

  .sub_menu_btn_group2 {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 80px;
    right: 392px;
    padding: 30px 0;
    width: 100%;
    justify-content: flex-start;
    gap: 10px;
    cursor: pointer;
  }

  .sub_menu_btn_group3 {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 80px;
    right: 605px;
    padding: 30px 0;
    width: 100%;
    justify-content: flex-start;
    gap: 10px;
    cursor: pointer;
  }

  .sub_menu_btn_group4 {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 80px;
    right: 820px;
    padding: 30px 0;
    width: 100%;
    justify-content: flex-start;
    gap: 10px;
    cursor: pointer;
  }

  .sub_menu_btn {
    width: 152px;
    height: 53px;
    border-radius: 5px;
    background-color: #009fa9;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    text-align: center;
    line-height: 53px;
  }

  .sub_menu_btn2 {
    background-color: #fff;
    color: #050b26;
  }

  .sub_menu_group {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    white-space: nowrap;
  }

  .sub_menu_title {
    width: 100%;
    border-bottom: 1px solid #cccfd3;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    padding: 10px 0;
    max-width: 136px;
  }

  .sub_menu_list {
    display: flex;
    flex-direction: column;
    gap: 21px;
    cursor: pointer;
  }

  .sub_menu_list_item {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .sub_menu_list_item_name {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    height: 16px;
    line-height: 16px;
  }

  .sub_menu_list_item:hover .sub_menu_list_item_name {
    color: #608aff;
  }

  /* .sub_menu_list_item:hover .sub_menu_list_item_name:after {
    display: block;
    content: "";
    border-bottom: solid 2px #608aff;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    transform-origin: 0% 50%;
  }

  .sub_menu_list_item:hover .sub_menu_list_item_name:hover:after {
    transform: scaleX(1);
  } */

  .sub_menu_list_item_text {
    font-family: Pretendard;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #cccfd3;
    height: 12px;
    line-height: 12px;
  }

  .header_right_area {
    /* width: 278px; */
    padding-right: 20px;
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .product_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 124px;
    height: 45px;
    border-radius: 22px;
    border: solid 1px rgba(255, 255, 255, 0.4);
    color: #fff;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    cursor: pointer;
  }

  .scrollHeader .product_btn {
    border: 1px solid #00177c;
    color: #ffffff;
    background-color: #00177c;
  }

  .body {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 100vh; */
    /* overflow-y: auto; */
  }

  .footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #021122;
  }

  .footer_left_area {
    display: flex;
    width: 100%;
  }

  .footer_content_wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .company_info_area {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    padding: 70px 30px 80px 10%;
    gap: 40px;
  }

  .company_info {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .company_info_item {
    display: flex;
    gap: 25px;
  }

  .company_info_label {
    width: 103px;
    height: 16px;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    height: 16px;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .company_info_value {
    font-family: Pretendard;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    line-height: 1.47;
    color: #cccfd3;
    white-space: pre;
  }

  .icon_area {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .sitemap_area {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 150px 10% 26px 30px;
    gap: 47px;
  }

  .menu_list_wrapper {
    display: flex;
    flex-direction: column;
    gap: 27px;
  }

  .menu_list {
    display: flex;
    align-items: flex-start;
    gap: 28px;
  }

  .menu_label {
    font-family: Pretendard;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #f5fafc;
    width: 150px;
    white-space: pre;
  }

  .menu_item_list {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    flex-wrap: wrap;
    height: 100%;
  }

  .menu_item {
    height: 15px;
    line-height: 15px;
    font-family: Pretendard;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #cccfd3;
    border-right: 1px solid #cccfd3;
    padding-right: 10px;
  }

  .menu_item:last-child {
    border-right: none;
  }

  .contact_menu_area {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .contact_menu {
    display: flex;
    gap: 30px;
  }

  .contact_menu_item {
    height: 18px;
    font-family: Pretendard;
    font-size: 19px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .distributor_area {
    display: flex;
    align-items: flex-end;
  }

  .copyright {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding: 30px 0 26px;
    height: 17px;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #cccfd3;
  }

  .admin_login_container {
    display: flex;
    width: 100%;
    height: 100vh;
  }

  .admin_container {
    display: flex;
    width: 100%;
    height: calc(100vh - 80px);
    margin-top: 80px;
  }
}

/* 테블릿 가로, 테블릿 세로 (해상도 768px ~ 1024px)*/
@media all and (min-width: 768px) and (max-width: 1024px) {
  .header {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    background-color: transparent;
    top: 0;
    transition: all 0.3s ease-in-out;
  }

  .body {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* overflow-y: auto; */
  }

  .footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #021122;
  }


  .scrollHeader {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    top: 0;
    transition: all 0.3s ease-in-out;
  }

  .header_main {
    display: flex;
    width: 100%;
    height: 80px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ffffff;
    transition: all 0.3s ease-in-out;
  }

  .scrollHeader .header_main {
    background-color: #fff;
    border-bottom: 1px solid #e1e1e1;
    transition: all 0.3s ease-in-out;
  }

  .header_sub {
    display: flex;
    width: 100%;
    height: 370px;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(0, 7, 19, 0.8);
    transition: all 0.3s ease-in-out;
  }

  .header_left_area {
    padding-left: 20px;
    /* width: 253px; */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header_center_area {
    width: calc(100% - 531px);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* gap: 10px; */
  }

  .menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 5px;
    height: 100%;
    position: relative;
  }

  .menu>span {
    font-family: Pretendard;
    font-size: 21px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    padding: 5px 0px;
    height: 30px;
    line-height: 35px;
    white-space: nowrap;
  }

  .scrollHeader .menu>span {
    color: #000;
  }

  .menu>span:hover {
    /* color: #608aff; */
  }

  .menu>span:after {
    display: block;
    content: "";
    border-bottom: solid 2px #00177c;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    transform-origin: 0% 50%;
  }

  .menu>span:hover:after {
    transform: scaleX(1);
  }

  .sub_menu {
    position: absolute;
    display: flex;
    top: 80px;
    padding: 20px 0;
    /* width: 100%; */
    justify-content: flex-start;
    gap: 30px;
  }

  .sub_menu2 {
    position: absolute;
    display: flex;
    top: 80px;
    padding: 20px 0;
    right: -176px;
    /* width: 100%; */
    justify-content: flex-start;
    gap: 10px;
  }

  .sub_menu3 {
    position: absolute;
    display: flex;
    top: 80px;
    padding: 20px 0;
    right: 10px;
    /* width: 100%; */
    justify-content: flex-start;
    gap: 80px;
  }

  .sub_menu_btn_group {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 80px;
    right: 193px;
    padding: 30px 0;
    width: 100%;
    justify-content: flex-start;
    gap: 10px;
    cursor: pointer;
  }

  .sub_menu_btn_group2 {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 80px;
    right: 392px;
    padding: 30px 0;
    width: 100%;
    justify-content: flex-start;
    gap: 10px;
    cursor: pointer;
  }

  .sub_menu_btn_group3 {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 80px;
    right: 605px;
    padding: 30px 0;
    width: 100%;
    justify-content: flex-start;
    gap: 10px;
    cursor: pointer;
  }

  .sub_menu_btn_group4 {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 80px;
    right: 820px;
    padding: 30px 0;
    width: 100%;
    justify-content: flex-start;
    gap: 10px;
    cursor: pointer;
  }

  .sub_menu_btn {
    width: 152px;
    height: 53px;
    border-radius: 5px;
    background-color: #009fa9;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    text-align: center;
    line-height: 53px;
  }

  .sub_menu_btn2 {
    background-color: #fff;
    color: #050b26;
  }

  .sub_menu_group {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    white-space: nowrap;
  }

  .sub_menu_title {
    width: 100%;
    border-bottom: 1px solid #cccfd3;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    padding: 10px 0;
    max-width: 136px;
  }

  .sub_menu_list {
    display: flex;
    flex-direction: column;
    gap: 21px;
    cursor: pointer;
  }

  .sub_menu_list_item {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .sub_menu_list_item_name {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    height: 16px;
    line-height: 16px;
  }

  .sub_menu_list_item:hover .sub_menu_list_item_name {
    color: #608aff;
  }

  /* .sub_menu_list_item:hover .sub_menu_list_item_name:after {
    display: block;
    content: "";
    border-bottom: solid 2px #608aff;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    transform-origin: 0% 50%;
  }

  .sub_menu_list_item:hover .sub_menu_list_item_name:hover:after {
    transform: scaleX(1);
  } */

  .sub_menu_list_item_text {
    font-family: Pretendard;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #cccfd3;
    height: 12px;
    line-height: 12px;
  }

  .header_right_area {
    /* width: 278px; */
    padding-right: 20px;
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 22px;
  }

  .product_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 124px;
    height: 45px;
    border-radius: 22px;
    border: solid 1px rgba(255, 255, 255, 0.4);
    color: #fff;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    cursor: pointer;
  }

  .scrollHeader .product_btn {
    border: 1px solid #00177c;
    color: #ffffff;
    background-color: #00177c;
  }

  .menu_btn {
    cursor: pointer;
    width: 25px;
    height: 25px;
    position: relative;
  }

  .menu_btn>span {
    position: absolute;
    width: 25px;
    height: 2px;
    background-color: #fff;
    transition: all 1s ease-in-out;
  }

  .menu_btn>span:first-child {
    top: 25%;
    transition: 0.5s;
  }

  .menu_btn>span:last-child {
    bottom: 25%;
    transition: 0.5s;
  }

  .scrollHeader .menu_btn>span {
    background-color: #00177c;
  }

  .menu_btn:global.active {
    position: relative;
  }

  .menu_btn:global.active>span {
    position: absolute;
  }

  .menu_btn:global.active>span:first-child {
    top: 50%;
    transform: translate(0, -50%) rotate(45deg);

  }

  .menu_btn:global.active>span:last-child {
    bottom: 50%;
    transform: translate(0, 50%) rotate(-45deg);
  }

  .footer_left_area {
    display: flex;
  }

  .footer_content_wrap {
    display: flex;
    justify-content: space-between;
  }

  .company_info_area {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    padding: 70px 60px 80px 80px;
    gap: 40px;
  }

  .company_info {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .company_info_item {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }

  .company_info_label {
    width: 103px;
    height: 16px;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    height: 16px;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .company_info_value {
    font-family: Pretendard;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    line-height: 1.47;
    color: #cccfd3;
    white-space: pre-line;
  }

  .icon_area {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .sitemap_area {
    display: flex;
    flex-direction: column;
    padding: 150px 0 26px 60px;
    gap: 47px;
  }

  .menu_list_wrapper {
    display: flex;
    flex-direction: column;
    gap: 27px;
  }

  .menu_list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 28px;
  }

  .menu_label {
    font-family: Pretendard;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #f5fafc;
    width: 150px;
  }

  .menu_item_list {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    flex-wrap: wrap;
    height: 100%;
  }

  .menu_item {
    height: 15px;
    line-height: 15px;
    font-family: Pretendard;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #cccfd3;
    border-right: 1px solid #cccfd3;
    padding-right: 10px;
  }

  .menu_item:last-child {
    border-right: none;
  }

  .contact_menu_area {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .contact_menu {
    display: flex;
    gap: 30px;
  }

  .contact_menu_item {
    height: 18px;
    font-family: Pretendard;
    font-size: 19px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .distributor_area {
    display: flex;
    align-items: flex-end;
    margin-right: 5%;
    padding-bottom: 26px;
  }

  .copyright {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding: 30px 0 26px;
    height: 17px;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #cccfd3;
  }

  .admin_login_container {
    display: flex;
    width: 100%;
    height: 100vh;
  }

  .admin_container {
    display: flex;
    width: 100%;
    height: calc(100vh - 80px);
    margin-top: 80px;
  }
}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width: 767px) {
  .header {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    background-color: transparent;
    top: 0;
    transition: all 0.3s ease-in-out;
  }


  .body {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* overflow-y: auto; */
  }

  .footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #021122;
  }


  .scrollHeader {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    top: 0;
    transition: all 0.3s ease-in-out;
  }

  .header_main {
    display: flex;
    width: 100%;
    height: 80px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ffffff;
    transition: all 0.3s ease-in-out;
  }

  .scrollHeader .header_main {
    background-color: #fff;
    border-bottom: 1px solid #e1e1e1;
    transition: all 0.3s ease-in-out;
  }

  .header_sub {
    display: flex;
    width: 100%;
    height: 370px;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(0, 7, 19, 0.8);
    transition: all 0.3s ease-in-out;
  }

  .header_left_area {
    padding-left: 20px;
    /* width: 253px; */
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .header_left_area>img {
    width: 128px;
  }

  .header_center_area {
    width: calc(100% - 531px);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* gap: 125px; */
  }

  .menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 30px;
    height: 100%;
    position: relative;
  }

  .menu>span {
    font-family: Pretendard;
    font-size: 21px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    padding: 5px 0px;
    height: 30px;
    line-height: 35px;
    white-space: nowrap;
  }

  .scrollHeader .menu>span {
    color: #000;
  }

  .menu>span:hover {
    /* color: #608aff; */
  }

  .menu>span:after {
    display: block;
    content: "";
    border-bottom: solid 2px #00177c;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    transform-origin: 0% 50%;
  }

  .menu>span:hover:after {
    transform: scaleX(1);
  }

  .sub_menu {
    position: absolute;
    display: flex;
    top: 80px;
    padding: 20px 0;
    /* width: 100%; */
    justify-content: flex-start;
    gap: 30px;
  }

  .sub_menu2 {
    position: absolute;
    display: flex;
    top: 80px;
    padding: 20px 0;
    right: -176px;
    /* width: 100%; */
    justify-content: flex-start;
    gap: 10px;
  }

  .sub_menu3 {
    position: absolute;
    display: flex;
    top: 80px;
    padding: 20px 0;
    right: 10px;
    /* width: 100%; */
    justify-content: flex-start;
    gap: 80px;
  }

  .sub_menu_btn_group {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 80px;
    right: 193px;
    padding: 30px 0;
    width: 100%;
    justify-content: flex-start;
    gap: 10px;
    cursor: pointer;
  }

  .sub_menu_btn_group2 {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 80px;
    right: 392px;
    padding: 30px 0;
    width: 100%;
    justify-content: flex-start;
    gap: 10px;
    cursor: pointer;
  }

  .sub_menu_btn_group3 {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 80px;
    right: 605px;
    padding: 30px 0;
    width: 100%;
    justify-content: flex-start;
    gap: 10px;
    cursor: pointer;
  }

  .sub_menu_btn_group4 {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 80px;
    right: 820px;
    padding: 30px 0;
    width: 100%;
    justify-content: flex-start;
    gap: 10px;
    cursor: pointer;
  }

  .sub_menu_btn {
    width: 152px;
    height: 53px;
    border-radius: 5px;
    background-color: #009fa9;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    text-align: center;
    line-height: 53px;
  }

  .sub_menu_btn2 {
    background-color: #fff;
    color: #050b26;
  }

  .sub_menu_group {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    white-space: nowrap;
  }

  .sub_menu_title {
    width: 100%;
    border-bottom: 1px solid #cccfd3;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    padding: 10px 0;
    max-width: 136px;
  }

  .sub_menu_list {
    display: flex;
    flex-direction: column;
    gap: 21px;
    cursor: pointer;
  }

  .sub_menu_list_item {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .sub_menu_list_item_name {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    height: 16px;
    line-height: 16px;
  }

  .sub_menu_list_item:hover .sub_menu_list_item_name {
    color: #608aff;
  }

  /* .sub_menu_list_item:hover .sub_menu_list_item_name:after {
    display: block;
    content: "";
    border-bottom: solid 2px #608aff;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    transform-origin: 0% 50%;
  }

  .sub_menu_list_item:hover .sub_menu_list_item_name:hover:after {
    transform: scaleX(1);
  } */

  .sub_menu_list_item_text {
    font-family: Pretendard;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #cccfd3;
    height: 12px;
    line-height: 12px;
  }

  .header_right_area {
    /* width: 278px; */
    padding-right: 20px;
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 22px;
  }

  .product_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 89px;
    height: 35px;
    border-radius: 22px;
    border: solid 1px rgba(255, 255, 255, 0.4);
    color: #fff;
    font-family: Pretendard;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    cursor: pointer;
  }

  .scrollHeader .product_btn {
    border: 1px solid #00177c;
    color: #ffffff;
    background-color: #00177c;
  }

  .menu_btn {
    cursor: pointer;
    width: 25px;
    height: 25px;
    position: relative;
  }

  .menu_btn>span {
    position: absolute;
    width: 25px;
    height: 2px;
    background-color: #fff;
    transition: all 1s ease-in-out;
  }

  .menu_btn>span:first-child {
    top: 25%;
    transition: 0.5s;
  }

  .menu_btn>span:last-child {
    bottom: 25%;
    transition: 0.5s;
  }

  .scrollHeader .menu_btn>span {
    background-color: #00177c;
  }

  .menu_btn:global.active {
    position: relative;
  }

  .menu_btn:global.active>span {
    position: absolute;
  }

  .menu_btn:global.active>span:first-child {
    top: 50%;
    transform: translate(0, -50%) rotate(45deg);

  }

  .menu_btn:global.active>span:last-child {
    bottom: 50%;
    transform: translate(0, 50%) rotate(-45deg);
  }

  .footer_left_area {
    display: flex;
  }

  .footer_content_wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  .company_info_area {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(100% - 37px);
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.2); */
    padding: 30px 20px 29.8px 17px;
  }

  .company_info {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 22px;
  }

  .company_info_item {
    display: flex;
    gap: 25px;
  }

  .company_info_label {
    width: 90px;
    height: 15px;
    font-family: Pretendard;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 15px;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .company_info_value {
    font-family: Pretendard;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #cccfd3;
    white-space: pre-line;
  }

  .company_footer {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
  }

  .icon_area {
    display: flex;
    align-items: center;
    gap: 13.5px;
  }

  .icon_area>img {
    width: 40px;
  }

  .sitemap_area {
    display: flex;
    flex-direction: column;
    padding: 150px 0 0 30px;
    gap: 47px;
  }

  .menu_list_wrapper {
    display: flex;
    flex-direction: column;
    gap: 27px;
  }

  .menu_list {
    display: flex;
    align-items: flex-start;
    gap: 28px;
  }

  .menu_label {
    font-family: Pretendard;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #f5fafc;
    width: 150px;
  }

  .menu_item_list {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    flex-wrap: wrap;
    height: 100%;
  }

  .menu_item {
    height: 15px;
    line-height: 15px;
    font-family: Pretendard;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #cccfd3;
    border-right: 1px solid #cccfd3;
    padding-right: 10px;
  }

  .menu_item:last-child {
    border-right: none;
  }

  .contact_menu {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 19.5px 0;
    width: 100%;
    gap: 30px;
    border-top: 1px solid rgba(255, 255, 255, 0.2)
  }

  .contact_menu_item {
    height: 19px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 19px;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }

  .distributor_area {
    display: flex;
    align-items: flex-end;
    margin-right: 190px;
    padding-bottom: 26px;
  }

  .copyright {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding: 18.5px 0 19px;
    font-family: Pretendard;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #cccfd3;
    white-space: pre-line;
  }

  .admin_login_container {
    display: flex;
    width: 100%;
    height: 100vh;
  }

  .admin_container {
    display: flex;
    width: 100%;
    height: calc(100vh - 80px);
    margin-top: 80px;
  }
}