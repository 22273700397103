@font-face {
  font-family: "Spoqa Han Sans Neo";
  font-weight: normal;
  src: url("./SpoqaHanSansNeo-Regular.woff") format("woff");
}

@font-face {
  font-family: "SpoqaHanSansNeo";
  font-weight: normal;
  src: url("./SpoqaHanSansNeo-Regular.woff") format("woff");
}

/* @font-face {
  font-family: "Pretendard";
  font-weight: normal;
  src: url("./PretendardVariable.ttf") format("truetype");
} */

@font-face {
  font-family: 'Pretendard';
  font-weight: 45 920;
  font-style: normal;
  font-display: swap;
  src: url('./PretendardVariable.woff2') format('woff2-variations');
}

/* @font-face {
  font-family: 'Pretendard';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
} */

@font-face {
  font-family: "LibreBaskerville";
  font-weight: normal;
  src: url("./LibreBaskerville-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: normal;
  src: url("./Poppins-Regular.ttf") format("truetype");
}