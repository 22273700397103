/* PC (해상도 1024px)*/
@media all and (min-width: 100px) {
  .wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    position: relative;
    overflow: hidden;
  }

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    /* transform: scale(1.1) rotate(-0.01deg); */
    transition: 0.3s;
    opacity: 1;
  }

  .background:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .login_area {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;
    height: 100%;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .section {
    width: calc(100% - 170px);
    height: 100%;
    margin: 0 85px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: center;
    gap: 30px;
  }

  .section > div {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .logo {
    /* width: 146px;
        height: auto; */
    margin-bottom: 30px;
  }

  .title {
    font-family: Pretendard;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .item {
    width: 100%;
    margin-bottom: 39px;
  }

  .item_title {
    font-family: Pretendard;
    font-size: 15px;
    line-height: 2.27;
    color: #fff;
    margin-left: 17px;
  }

  .item_input {
    font-family: Pretendard;
    height: 51px;
  }

  .item_input > input {
    font-family: Pretendard;
  }

  .button {
    font-family: Pretendard;
    width: 100%;
    height: 51px;
    /* background-color: #1cb691; */
  }

  .header {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    background-color: #2083e6;
    top: 0;
    transition: all 0.3s ease-in-out;
  }

  .header_main {
    display: flex;
    width: 100%;
    height: 80px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ffffff;
    transition: all 0.3s ease-in-out;
  }

  .header_left_area {
    width: 253px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header_center_area {
    width: calc(100% - 531px);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* gap: 125px; */
  }

  .header_right_area {
    width: 278px;
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn_logout {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 124px;
    height: 45px;
    border-radius: 22px;
    border: solid 1px rgba(255, 255, 255, 0.4);
    color: #fff;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    cursor: pointer;
  }
}

/* 테블릿 가로, 테블릿 세로 (해상도 768px ~ 1024px)*/
@media all and (min-width: 768px) and (max-width: 1024px) {
}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width: 767px) {
}

/* PC(해상도 1920px ~)*/
@media all and (min-width: 1921px) {
}
