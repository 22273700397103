@media all and (min-width: 1025px) {
  .wrapper_h {
    display: flex;
    width: 100%;
    aspect-ratio: 2.55;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
  }

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    /* background-repeat: no-repeat; */
    background-position: 50% 0;
    background-size: cover;
    transition: 0.3s;
    opacity: 1;
    animation: fadeInBg 10s linear both;
  }

  .background:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .background_blur {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    /* z-index: -1; */
  }

  .foxit_area {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .foxit_title_wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    max-width: 1280px;
    height: 100%;
    gap: 49px;
  }

  .foxit_title {
    height: 72px;
    font-family: Pretendard;
    font-size: 75px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 72px;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    margin-top: 10%;
  }

  .foxit_subtitle {
    height: 20px;
    font-family: Pretendard;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .foxit_title_buttons {
    display: flex;
    margin-top: 36px;
  }

  .foxit_title_button1 {
    height: 50px;
    width: 174px;
    border: 1px solid #fff;
    border-radius: 25px;
    color: #fff;
    text-align: center;
    line-height: 50px;
    font-size: 17px;
    font-family: Pretendard;
    margin-right: 20px;
  }

  .foxit_title_button2 {
    height: 50px;
    width: 174px;
    border: 1px solid #fff;
    border-radius: 25px;
    color: #222;
    text-align: center;
    line-height: 50px;
    font-size: 17px;
    font-family: Pretendard;
    background-color: #fff;
    font-weight: normal;
  }

  .foxit_category {
    height: 85px;
    line-height: 85px;
    border-bottom: 1px solid #c0c0c0;
    margin-bottom: 100px;
    display: flex;
    justify-content: center;
  }

  .foxit_category_buttons {
    display: flex;
    width: 1280px;
  }

  .foxit_button {
    line-height: 85px;
    height: 85px;
    font-size: 19px;
    font-family: Pretendard;
    margin-right: 61px;
    font-weight: bold;
    color: #191919;
  }

  .foxit_button:hover {
    line-height: 85px;
    height: 85px;
    font-size: 19px;
    font-family: Pretendard;
    margin-right: 61px;
    font-weight: bold;
    color: #00177c;
  }

  .button_focus {
    border-bottom: 2px solid #00177c;
    color: #00177c;
  }

  .foxit_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 110px;
    margin-bottom: 100px;
    width: 100%;
  }

  .foxit_contents {
    width: 1280px;
    display: flex;
    justify-content: space-between;
    height: 411px;
  }

  .foxit_info {
    display: flex;
    flex-direction: column;
    width: 582px;
    justify-content: center;
  }

  .foxit_info_title {
    font-size: 40px;
    font-weight: bold;
    color: #00177c;
    width: 100%;
    line-height: 50px;
    font-family: Pretendard;
  }

  .foxit_info_title2 {
    letter-spacing: -1.12px;
  }

  .foxit_info_contents {
    font-size: 20px;
    width: 100%;
    font-family: Pretendard;
    letter-spacing: normal;
    margin-top: 53px;
    margin-bottom: 44px;
    line-height: 30px;
    white-space: nowrap;
  }

  .foxit_info_contents2 {
    font-size: 20px;
    width: 100%;
    font-family: Pretendard;
    letter-spacing: normal;
    margin-top: 40px;
    margin-bottom: 40px;
    line-height: 30px;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    gap: 28px;
  }

  .focus_line {
    /* border-bottom: 8px solid rgba(0, 159, 169, 0.24); */
    background-color: rgba(0, 159, 169, 0.24);
    height: 12px;
    position: inherit;
  }

  .foxit_info_tags {
    display: flex;
    gap: 7px;
  }

  .function_info_tag {
    height: 40px;
    line-height: 40px;
    background-color: #ebedf4;
    border-radius: 20px;
    margin-top: 7px;
    margin-bottom: 7px;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
  }

  .under_line {
    padding-bottom: 2px;
    padding-right: 2px;
    padding-left: 2px;
  }

  .under_line2 {
    padding-bottom: 2px;
    padding-right: 2px;
    padding-left: 2px;
    font-weight: 600;
  }

  .focus_text {
    box-shadow: inset 0 -12px 0 rgba(0, 159, 169, 0.24);
  }

  .foxit_introduce {
    display: flex;
    flex-direction: column;
    margin-top: 17px;
    align-items: center;
  }

  .foxit_introduce_p1 {
    font-size: 43px;
    font-family: Pretendard;
    font-weight: bold;
    height: 43px;
    line-height: 43px;
  }

  .foxit_introduce_p2 {
    margin-top: 34px;
    margin-bottom: 32px;
    font-family: Pretendard;
    font-weight: 600;
    color: #00177c;
    font-size: 21px;
    height: 21px;
    line-height: 21px;
  }

  .foxit_introduce_p3 {
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
  }

  .foxit_contents_check {
    display: flex;
  }

  .foxit_contents_img {
    margin-right: 8px;
  }

  .foxit_contents_txt {
    white-space: pre-wrap;
    word-break: break-all;
  }

  .foxit_info_hashtag {
    font-size: 16px;
    font-family: Pretendard;
    letter-spacing: -0.08px;
    font-weight: normal;
    background-color: #ebedf4;
    height: 110px;
    width: 576px;
    padding: 18px 19px 19px 17px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 15px;
  }

  .hashtag_txt {
    line-height: 25px;
  }

  .foxit_esign_feature {
    height: 930px;
    width: 100%;
    background-color: #ebedf4;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .foxit_esign_feature_header {
    margin: 80px 0px 40px 0px;
    font-family: Pretendard;
    font-size: 45px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #00177c;
  }

  .foxit_esign_feature_contents {
    width: 1280px;
    display: flex;
    justify-content: space-between;
    margin-top: 35px;
  }

  .feature_img {
    width: 402px;
    height: 210px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  }

  .feature_text {
    height: 90px;
    line-height: 90px;
    text-align: center;
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 600;
  }

  .feature_card {
    width: 402px;
    height: 300px;
    border-radius: 20px;
    background-color: #fff;
  }

  .foxit_esign_adv {
    margin-bottom: 120px;
  }

  .foxit_esign_adv_header {
    font-family: Pretendard;
    font-size: 45px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #00177c;
    margin-bottom: 98px;
  }

  .foxit_esign_adv_contents {
    width: 980px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .foxit_esign_adv_content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .foxit_esign_adv_num {
    width: 47px;
    height: 47px;
    border-radius: 47px;
    font-family: ;
    font-size: 17px;
    color: white;
    background-color: #00177c;
    text-align: center;
    line-height: 47px;
  }

  .foxit_esign_adv_img {
    margin: 32px 0px;
  }

  .foxit_esign_adv_title {
    margin-bottom: 44px;
    font-family: Pretendard;
    font-size: 34px;
    font-weight: 600;
  }

  .foxit_esign_adv_txt {
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    line-height: 27px;
    text-align: center;
  }

  .pdfreader_wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .pdfreader_header {
    width: 1280px;
    text-align: left;
    margin-bottom: 36px;
  }

  .pdfreader_body {
    width: 1280px;
  }

  .header_txt1 {
    margin-top: 20px;
    font-size: 17px;
    font-family: Pretendard;
    height: 17px;
    line-height: 17px;
    color: #555555;
    font-weight: 500;
    margin-bottom: 17px;
  }

  .header_txt2 {
    height: 40px;
    font-size: 40px;
    line-height: 40px;
    font-weight: bold;
    font-family: Pretendard;
    color: #191919;
  }

  .header_txt3 {
    color: #00177c;
  }

  .pdfreader_contents {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 107px;
  }

  .reader_cardimg {
    width: 608px;
    height: 433px;
  }

  .reader_img {
    width: 100%;
    height: auto;
  }

  .reader_cardtitle {
    margin: 38.5px 0px;
    font-size: 35px;
    height: 35px;
    line-height: 35px;
    font-family: Pretendard;

    color: #00177c;
    font-weight: bold;
  }

  .reader_cardcontents {
    font-family: Pretendard;
    font-size: 17px;
    white-space: pre-wrap;
    word-break: keep-all;
    width: 610px;
    letter-spacing: -0.07px;
    line-height: 28px;
  }

  @media all and (min-width: 1025px) and (max-width: 1300px) {
    .foxit_title_wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: calc(100% - 40px);
      max-width: 1280px;
      height: 100%;
      gap: 45px;
      margin-left: 40px;
    }

    .foxit_title {
      height: 60px;
      font-family: Pretendard;
      font-size: 60px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 60px;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
      margin-top: 10%;
    }

    .foxit_title_buttons {
      display: flex;
      margin-top: 0px;
    }

    .foxit_category {
      height: 85px;
      line-height: 85px;
      border-bottom: 1px solid #c0c0c0;
      margin-bottom: 80px;
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }

    .foxit_category_buttons {
      display: flex;
      width: calc(100% - 40px);
    }

    /* foxit pdf esign */
    .foxit_contents {
      width: calc(100% - 80px);
      display: flex;
      justify-content: space-between;
      height: auto;
    }

    .foxit_pdf_img_box {
      width: 45%;
      max-width: 540px;
      min-width: 480px;
    }

    .foxit_pdf_img {
      width: 100%;
    }

    .foxit_info {
      width: 45%;
      max-width: 540px;
    }

    .foxit_info_title {
      font-size: 25px;
      letter-spacing: -0.6px;
    }

    .foxit_info_contents2 {
      font-size: 14px;
      line-height: 25px;
      margin-top: 20px;
      margin-bottom: 20px;
      gap: 20px;
      letter-spacing: -1px;
    }

    .foxit_contents_img {
      height: 11px;
      margin-right: 3px;
    }

    .foxit_info_tags {
      display: flex;
    }

    .foxit_info_hashtag {
      width: calc(100% - 36px);
      height: auto;
      font-size: 14px;
    }

    .hashtag_txt {
      line-height: 20px;
    }

    .function_info_tag {
      height: auto;
      line-height: 40px;
      background-color: #ebedf4;
      border-radius: 20px;
      margin-top: 7px;
      margin-bottom: 7px;
      font-family: Pretendard;
      font-size: 12px;

      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
    }

    .foxit_esign_feature_contents {
      width: calc(100% - 80px);
      display: flex;
      justify-content: space-between;
      margin-top: 35px;
    }

    .foxit_esign_feature {
      height: 800px;
      width: 100%;
      background-color: #ebedf4;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .feature_card {
      width: 31%;
      height: auto;
      border-radius: 20px;
      background-color: #fff;
    }

    .feature_img {
      width: 100%;
      aspect-ratio: 1.91;
      height: auto;
      background-repeat: no-repeat;
      background-size: 100%;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
    }

    .feature_text {
      height: 60px;
      line-height: 60px;
      text-align: center;
      font-family: Pretendard;
      font-size: 16px;
      font-weight: 600;
    }

    .foxit_esign_adv {
      margin-bottom: 120px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .foxit_esign_adv_header {
      font-family: Pretendard;
      font-size: 45px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
      color: #00177c;
      margin-bottom: 60px;
    }

    .foxit_esign_adv_contents {
      width: calc(100% - 80px);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    /* foxit pdf reader */
    .pdfreader_header {
      width: calc(100% - 80px);
      text-align: left;
      margin-bottom: 36px;
    }

    .pdfreader_body {
      width: calc(100% - 80px);
    }

    .pdfreader_contents {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 80px;
    }

    .reader_cardbox {
      width: 47%;
    }

    .reader_cardimg {
      width: 100%;
      height: auto;
    }

    .reader_cardcontents {
      font-family: Pretendard;
      font-size: 17px;
      white-space: pre-wrap;
      word-break: keep-all;
      width: 100%;
      letter-spacing: -0.07px;
      line-height: 28px;
    }

    /* foxit pdf editor */
    .pdfeditor_imgbox {
      width: 45%;
    }

    .pdfeditor_img {
      width: 100%;
    }

    .foxit_info_contents {
      font-size: 16px;
      width: 100%;
      font-family: Pretendard;
      letter-spacing: normal;
      margin-top: 30px;
      margin-bottom: 30px;
      line-height: 25px;

      white-space: pre-wrap;
      word-break: break-all;
    }
  }
}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width: 1024px) {
  .wrapper_h {
    display: flex;
    width: 100%;
    height: 530px;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
  }

  .background {
    position: absolute;
    width: 100%;
    height: 530px;
    z-index: -1;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    transition: 0.3s;
    opacity: 1;
  }

  .background:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 530px;
  }

  .background_blur {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    /* z-index: -1; */
  }

  .foxit_area {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 530px;
  }

  .foxit_title_wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: calc(100% - 40px);
    height: 100%;
    gap: 24px;
    margin-top: 81px;
  }

  .foxit_title {
    height: 56px;
    font-family: Pretendard;
    font-size: 34px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 56px;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .foxit_subtitle {
    height: 20px;
    font-family: Pretendard;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .foxit_title_buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin-top: 36px;
    height: 109px;
  }

  .foxit_title_button1 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 134px;
    height: 45px;
    border-radius: 23px;
    border: solid 1px #fff;
    background-color: transparent;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .foxit_title_button2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 134px;
    height: 45px;
    border-radius: 23px;
    border: solid 1px #fff;
    background-color: transparent;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    background-color: #fff;
    color: #222;
  }

  @media all and (max-width: 767px) {
    .wrapper_h {
      display: flex;
      width: 100%;
      height: 530px;
      justify-content: flex-start;
      position: relative;
      overflow: hidden;
      flex-direction: column;
      align-items: center;
    }

    .background {
      position: absolute;
      width: 100%;
      height: 530px;
      z-index: -1;
      background-repeat: no-repeat;
      background-position: 50% 0;
      background-size: cover;
      transition: 0.3s;
      opacity: 1;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }

    .background:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 530px;
    }

    .background_blur {
      display: flex;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      /* z-index: -1; */
    }

    .foxit_area {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 530px;
    }

    .foxit_title_wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      width: calc(100% - 40px);
      height: 100%;
      gap: 24px;
      margin-top: 81px;
    }

    .foxit_title {
      height: 34px;
      font-family: Pretendard;
      font-size: 34px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 34px;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
      margin-top: 80px;
    }

    .foxit_subtitle {
      height: 17px;
      font-family: Pretendard;
      font-size: 17px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 17px;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
    }

    .foxit_title_buttons {
      display: flex;
      flex-direction: column;
      align-items: center;

      justify-content: flex-end;
      gap: 15px;
      margin-top: 36px;
      width: 136px;
      height: 109px;
      margin-bottom: 84px;
    }

    .foxit_title_button1 {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 134px;
      height: 45px;
      border-radius: 23px;
      border: solid 1px #fff;
      background-color: transparent;
      font-family: Pretendard;
      font-size: 17px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
    }

    .foxit_title_button2 {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 134px;
      height: 45px;
      border-radius: 23px;
      border: solid 1px #fff;
      background-color: transparent;
      font-family: Pretendard;
      font-size: 17px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      background-color: #fff;
      color: #222;
    }
  }

  .foxit_category {
    height: 67.5px;
    line-height: 67.5px;
    border-bottom: 1px solid #c0c0c0;
    margin-bottom: 100px;
    display: flex;
    justify-content: center;
    margin-bottom: 37.5px;
  }

  .foxit_category_buttons {
    display: flex;
    width: 1280px;
  }

  .foxit_button {
    line-height: 85px;
    height: 85px;
    font-size: 19px;
    font-family: Pretendard;
    margin-right: 61px;
    font-weight: bold;
    color: #191919;
  }

  .foxit_button:hover {
    line-height: 85px;
    height: 85px;
    font-size: 19px;
    font-family: Pretendard;
    margin-right: 61px;
    font-weight: bold;
    color: #00177c;
  }

  .button_focus {
    border-bottom: 2px solid #00177c;
    color: #00177c;
  }

  .foxit_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 80px;
    margin-bottom: 80px;
    width: 100%;
  }

  .foxit_contents {
    width: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    max-width: 660px;
  }

  .foxit_pdf_img_box {
    width: 100%;
  }

  .foxit_pdf_img {
    width: 100%;
  }

  .foxit_info {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
  }

  .foxit_info_title {
    font-size: 30px;
    font-weight: bold;
    color: #00177c;
    width: 100%;
    line-height: 43px;
    font-family: Pretendard;
    margin-top: 30px;
  }

  .foxit_info_title2 {
    letter-spacing: -1.12px;
  }

  .foxit_info_contents {
    font-size: 16px;
    width: 100%;
    font-family: Pretendard;
    letter-spacing: normal;
    margin: 20px 0px;
    line-height: 27px;
    white-space: pre-wrap;
    word-break: break-all;
  }

  .foxit_info_contents2 {
    font-size: 16px;
    width: 100%;
    font-family: Pretendard;
    letter-spacing: normal;
    margin: 25px 0px;
    line-height: 30px;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .focus_line {
    /* border-bottom: 8px solid rgba(0, 159, 169, 0.24); */
    background-color: rgba(0, 159, 169, 0.24);
    height: 12px;
    position: inherit;
  }

  .foxit_info_tags {
    display: flex;
    flex-wrap: wrap;
    column-gap: 8px;
  }

  .function_info_tag {
    height: 40px;
    line-height: 40px;
    background-color: #ebedf4;
    border-radius: 20px;
    margin-top: 4px;
    margin-bottom: 4px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
  }

  .under_line {
    padding-bottom: 2px;
    padding-right: 2px;
    padding-left: 2px;
  }

  .under_line2 {
    padding-bottom: 2px;
    padding-right: 2px;
    padding-left: 2px;
    font-weight: 600;
  }

  .focus_text {
    box-shadow: inset 0 -12px 0 rgba(0, 159, 169, 0.24);
  }

  .foxit_introduce {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 40px);
    max-width: 660px;
  }

  .foxit_introduce_p1 {
    font-size: 34px;
    font-family: Pretendard;
    font-weight: bold;
    line-height: 43px;
    width: 250px;
    white-space: prewrap;
    text-align: center;
  }

  .foxit_introduce_p2 {
    margin: 20px 0px;
    font-family: Pretendard;
    font-weight: 600;
    color: #00177c;
    font-size: 16px;
    height: 21px;
    line-height: 21px;
  }

  .foxit_introduce_p3 {
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    font-family: Pretendard;
    text-align: center;
  }

  .foxit_contents_check {
    display: flex;
  }

  .foxit_contents_img {
    margin-right: 8px;
  }

  .foxit_contents_txt {
    white-space: pre-wrap;
    word-break: break-all;
  }

  .foxit_info_hashtag {
    font-size: 16px;
    font-family: Pretendard;
    letter-spacing: -0.08px;
    font-weight: normal;
    background-color: #ebedf4;

    width: calc(100% - 20px);
    padding: 10px;
    white-space: pre-wrap;
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
  }

  .hashtag_txt {
    line-height: 25px;
  }

  .foxit_esign_feature {
    width: 100%;
    background-color: #ebedf4;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .foxit_esign_feature_header {
    margin: 40px 0px;

    font-family: Pretendard;
    font-size: 34px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #00177c;
  }

  .foxit_esign_feature_contents {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
    margin-bottom: 20px;
  }

  .feature_img {
    width: 100%;
    height: 175px;
    border-top-right-radius: 17px;
    border-top-left-radius: 17px;
    background-size: cover;
  }

  .feature_text {
    height: 90px;
    line-height: 90px;
    text-align: center;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: 600;
  }

  .feature_card {
    width: 335px;

    border-radius: 20px;
    background-color: #fff;
  }

  .foxit_esign_adv {
    margin-bottom: 120px;
  }

  .foxit_esign_adv_header {
    font-family: Pretendard;
    font-size: 34px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #00177c;
    margin-bottom: 45px;
  }

  .foxit_esign_adv_contents {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 70px;
  }

  .foxit_esign_adv_content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .foxit_esign_adv_num {
    width: 47px;
    height: 47px;
    border-radius: 47px;
    font-family: ;
    font-size: 17px;
    color: white;
    background-color: #00177c;
    text-align: center;
    line-height: 47px;
  }

  .foxit_esign_adv_img {
    margin: 10px 0px;
  }

  .foxit_esign_adv_title {
    margin-bottom: 17px;
    font-family: Pretendard;
    font-size: 38px;
    font-weight: 600;
  }

  .foxit_esign_adv_txt {
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    line-height: 27px;
    text-align: center;
  }

  .pdfreader_wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .pdfreader_header {
    width: calc(100% - 40px);
    text-align: left;
    margin-bottom: 42px;
    max-width: 660px;
  }

  .pdfreader_body {
    width: calc(100% - 40px);
    max-width: 660px;
  }

  .header_txt1 {
    margin-top: 20px;
    font-size: 17px;
    font-family: Pretendard;
    height: 17px;
    line-height: 17px;
    color: #555555;
    font-weight: 500;
    margin-bottom: 17px;
  }

  .header_txt2 {
    height: 30px;
    font-size: 30px;
    line-height: 30px;
    font-weight: bold;
    font-family: Pretendard;
    color: #191919;
  }

  .header_txt3 {
    color: #00177c;
  }

  .pdfreader_contents {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 80px;
    margin-bottom: 80px;
  }

  .reader_cardimg {
    width: 100%;
  }

  .reader_img {
    width: 100%;
    height: auto;
  }

  .reader_cardtitle {
    margin: 30px 0px 20px 0px;
    font-size: 30px;
    height: 35px;
    line-height: 35px;
    font-family: Pretendard;

    color: #00177c;
    font-weight: bold;
  }

  .reader_cardcontents {
    font-family: Pretendard;
    font-size: 16px;
    white-space: pre-wrap;
    word-break: keep-all;
    width: 100%;
    letter-spacing: -0.07px;
    line-height: 28px;
  }

  .il {
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);

    max-width: 660px;
  }

  .ir {
    display: flex;
    flex-direction: column-reverse;
    width: calc(100% - 40px);

    max-width: 660px;
  }

  .pdfeditor_imgbox {
    width: 100%;
  }

  .pdfeditor_img {
    width: 100%;
  }
}