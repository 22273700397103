.detail_files {
  display: flex;
}

.file_list {
  position: absolute;
  width: 170px;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  padding: 5px 0px 10px 0px;
  top: 25px;
  background-color: #fff;
}

.file_len {
  margin-top: 20px;
}

@media all and (min-width: 1025px) {
  .wrapper_h {
    display: flex;
    width: 100%;
    aspect-ratio: 2.55;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
  }

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    /* background-repeat: no-repeat; */
    background-position: 50% 0;
    background-size: cover;
    transition: 0.3s;
    opacity: 1;
    animation: fadeInBg 10s linear both;
  }

  .background:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .background_blur {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    /* z-index: -1; */
  }

  .support_area {
    height: 100%;
  }

  .support_title_wrap {
    display: flex;
    flex-direction: column;
    row-gap: 58px;
    align-items: center;
    height: 100%;
    justify-content: center;
  }

  .support_title {
    font-family: Pretendard;
    font-size: 75px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .support_subtitle {
    font-family: Pretendard;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .support_category {
    height: 90px;
    line-height: 90px;
    border-bottom: 1px solid #c0c0c0;
    margin-bottom: 59px;
    display: flex;
    justify-content: center;
  }

  .support_category_buttons {
    display: flex;
    width: 1280px;
  }

  .support_button {
    line-height: 89px;
    height: 89px;
    font-size: 19px;
    font-family: Pretendard;
    margin-right: 61px;
    font-weight: bold;
    color: #191919;
  }

  .button_focus {
    border-bottom: 2px solid #00177c;
    color: #00177c;
  }

  .onsite_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .online_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .onsite_body {
    width: 1280px;
  }

  .onsite_quote {
    width: 100%;
    text-align: right;
    margin-bottom: 65px;
    font-family: Pretendard;
  }

  .onsite_quote_mark {
    color: #009fa9;
  }

  .onsite_contents {
    display: flex;
    justify-content: space-between;
  }

  .onsite_header {
    margin-top: 84px;
    /* width: 450px; */
  }

  .onsite_title {
    font-family: Pretendard;
    font-size: 45px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.23px;
    text-align: left;
    color: #191919;
    margin-bottom: 47px;
  }

  .onsite_subtitle {
    width: 353px;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #2e2f30;
    letter-spacing: -0.01px;
    line-height: 23px;
  }

  .onsite_subtitle2 {
    color: #00177c;
    font-weight: 500;
  }

  .onsite_input_line {
    width: 831px;
    display: flex;
    justify-content: space-between;
  }

  .onsite_input_wrapper {
    margin-bottom: 60px;
  }

  .onsite_input_header {
    font-family: pretendard;
    font-size: 19px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #111;
    margin-bottom: 16px;
  }

  .onsite_input_header_none {
    height: 39px;
  }

  .onsite_input_body {
    width: 356px;
    height: 58px;
    padding: 0px 22px;
    display: flex;
    border: 1px solid #e1e1e1;
  }

  .onsite_input_post {
    width: 787px;
    height: 58px;
    padding: 0px 22px;
    display: flex;
    border: 1px solid #e1e1e1;
  }

  .onsite_input_textarea {
    width: 787px;
    height: 122px;
    padding: 20px 22px;
    display: flex;
    border: 1px solid #e1e1e1;
  }

  .onsite_input {
    width: 100%;
    outline: none;
    border: none;
    font-size: 19px;
    font-family: Pretendard;
  }

  .onsite_email_text {
    margin-top: 5px;
    margin-left: 5px;
    font-size: 16px;
    font-family: Pretendard;
  }

  .onsite_input_time {
    width: 130px;
    outline: none;
    border: none;
    font-size: 19px;
    font-family: Pretendard;
  }

  .onsite_post {
    width: 100%;
    outline: none;
    border: none;
    font-size: 19px;
    font-family: Pretendard;
  }

  .onsite_textarea {
    width: 100%;
    outline: none;
    border: none;
    font-size: 19px;
    height: 122px;
    resize: none;
    font-family: Pretendard;
  }

  .onsite_line {
    width: 100%;
    height: 1px;
    background-color: #e1e1e1;
    margin-bottom: 50px;
  }

  .postmodal {
    margin: 10% auto;
    width: 600px;
    border: 1px solid black;
  }

  .submitmodal {
    width: 441px;
    height: 215px;
    margin: 15% auto;
    padding: 40px 0px 20px 0px;
    border-radius: 20px;
    border: solid 1px #e1e1e1;
    background-color: #fff;
  }

  .submitmodal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1001;
  }

  .sm_body {
    display: flex;
    width: 100%;
    flex-direction: column;
    row-gap: 35px;
    align-items: center;
  }

  .sm_title {
    height: 24px;
    font-family: Pretendard;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #111;
    line-height: 24px;
  }

  .sm_text {
    text-align: center;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #333;
    font-weight: 500;
  }

  .sm_point {
    color: #00177c;
    font-weight: 600;
  }

  .sm_button {
    width: 152px;
    height: 53px;
    line-height: 53px;
    text-align: center;
    border-radius: 5px;
    background-color: #00177c;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #fff;
  }

  .selectbox_onsite {
    width: 400px;
    padding: 0px 22px;
    height: 60px;
    outline: none;
    font-size: 19px;
    font-family: Pretendard;
    border: 1px solid #e1e1e1;
    background: url("http://bluetns.co.kr/img/common/arrow_down.png") no-repeat
      94% 50% / 15px auto;
    appearance: none;
    color: #191919;
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
  }

  .selectbox_list {
    height: 100px;
  }

  .send_button {
    margin: 35px auto 230px auto;
    width: 255px;
    height: 60px;
    border-radius: 5px;
    line-height: 60px;
    text-align: center;
    background-color: #00177c;
    font-family: Pretendard;
    font-size: 19px;
    font-weight: 500;
    color: #fff;
  }

  .online_title {
    margin-top: 20px;
    margin-bottom: 125px;

    font-family: Pretendard;
    font-size: 64px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.32px;
    text-align: left;
    color: #191919;
  }

  .online_body {
    background-color: #eff5fa;
    width: 100%;
    /* height: 326px; */
    padding-top: 10px;
    margin-bottom: 230px;
    display: flex;
    justify-content: center;
  }

  .online_info {
    width: 1280px;
    display: flex;
    justify-content: space-between;
  }

  .online_icon {
    display: inherit;
    position: relative;
  }

  .online_text {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .tech_support {
    font-family: Pretendard;
    font-size: 23px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #111;
  }

  .info_text {
    margin-top: 48px;
    margin-bottom: 42px;
    font-family: Pretendard;
    font-size: 21px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.52;
    letter-spacing: normal;
    text-align: center;
    color: #2e2f30;
  }

  .info_text2 {
    color: #00177c;
  }

  .info_box {
    width: 532px;
    padding: 0px 22px;
    height: 134px;
    background-color: rgba(207, 225, 240, 0.5);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .info_box_header {
    font-family: Pretendard;
    font-size: 30px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #191919;
    margin-bottom: 19px;
  }

  .info_box_info {
    width: 253px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.5px;
    text-align: left;
  }

  .info_box_info2 {
    color: #00177c;
  }

  .info_box_button {
    width: 256px;
    height: 70px;
    text-align: center;
    line-height: 70px;
    font-family: Pretendard;
    font-size: 22px;
    background-color: #00177c;
    border-radius: 5px;
    color: #fff;
  }

  .faqlist {
    margin-bottom: 95px;
  }

  .faqmore {
    margin-bottom: 230px;
    text-align: center;

    width: 191px;
    height: 60px;
    margin: 0 auto 230px auto;
    border-radius: 30px;
    border: solid 1px #e1e1e1;
    background-color: #fff;
    line-height: 60px;
    font-family: Pretendard;
    font-size: 19px;
    font-weight: 500;
  }

  .faqline {
    display: flex;
    justify-content: center;
  }

  .faqsearchline {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .faqsearch {
    width: 1280px;
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid #191919;
    padding-bottom: 36px;
  }

  .faqsearchbox {
    width: 337px;
    height: 52px;
    border: 1px solid #e1e1e1;
    padding: 0px 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .faqsearchinput {
    width: 300px;
    height: 17px;
    font-size: 17px;
    line-height: 17px;
    font-family: Pretendard;
    outline: none;
    border: none;
  }

  .faq_open {
    width: 1176px;
    border-bottom: 1px solid #e1e1e1;
    transition: all 0.3s ease-in-out;
    display: flex;
    padding: 40px 68px 40px 36px;
    background-color: #f8f8f8;
  }

  .faq_close {
    width: 1176px;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    display: flex;
    padding: 0px 68px 0px 36px;
  }

  .answer {
    min-width: 40px;
    height: 40px;
    border-radius: 40px;
    background-color: #00177c;
    color: white;
    font-size: 24px;
    font-family: Pretendard;
    margin-right: 39px;
    text-align: center;
    line-height: 40px;
  }

  .answer_text {
    width: 1100px;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    text-align: left;
    color: #2e2f30;
    padding-top: 12px;
  }

  .faq_contents_title {
    height: 80px;
    border-bottom: 1px solid #e1e1e1;
    display: flex;
    width: 1184px;
    padding: 0px 48px;
    justify-content: space-between;
    align-items: center;
  }

  .titleQ {
    margin-right: 53px;
    font-size: 24px;
    font-family: Pretendard;
  }

  .open_title {
    font-family: Pretendard;
    font-size: 19px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #00177c;
  }

  .close_title {
    font-family: Pretendard;
    font-size: 19px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #111111;
  }

  /* 여기 */
  @media all and (min-width: 1025px) and (max-width: 1300px) {
    .support_category {
      padding-left: 40px;
    }

    .faq_databody {
      width: 100%;
    }

    .open_body {
      width: 100%;
    }

    .faq_contents_title {
      height: 80px;
      border-bottom: 1px solid #e1e1e1;
      display: flex;
      width: calc(100% - 80px);
      padding: 0px 40px;
      justify-content: space-between;
      align-items: center;
    }

    .faq_open {
      width: calc(100% - 56px);
      border-bottom: 1px solid #e1e1e1;
      transition: all 0.3s ease-in-out;
      display: flex;
      padding: 40px 28px 40px 28px;
      background-color: #f8f8f8;
    }

    .faq_close {
      width: calc(100% - 56px);
      max-height: 0px;
      overflow: hidden;
      transition: all 0.3s ease-in-out;
      display: flex;
      padding: 0px 28px;
    }

    .answer_text {
      font-family: Pretendard;
      font-size: 17px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      text-align: left;
      color: #2e2f30;
      padding-top: 12px;
      width: 100%;
    }

    .onsite_body {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .onsite_header {
      margin-top: 0px;
      /* width: 450px; */
    }

    .onsite_subtitle {
      width: 100%;
      font-family: Pretendard;
      font-size: 17px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: #2e2f30;
      letter-spacing: -0.01px;
      line-height: 23px;
    }

    .onsite_input_section {
      width: 900px;
    }

    .onsite_input_line {
      width: 900px;
      display: flex;
      justify-content: space-between;
    }

    .onsite_input_post {
      width: 860px;
      height: 58px;
      padding: 0px 22px;
      display: flex;
      border: 1px solid #e1e1e1;
    }

    .onsite_input_textarea {
      width: 860px;
      height: 122px;
      padding: 20px 22px;
      display: flex;
      border: 1px solid #e1e1e1;
    }

    .onsite_contents {
      display: flex;
      flex-direction: column;
      width: calc(100% - 80px);
    }

    .online_info {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .online_icon {
      display: inherit;
      position: relative;
      bottom: 21px;
      left: 40px;
    }

    .online_text {
      text-align: center;
      margin-right: 40px;
    }
  }

  @media all and (min-width: 768px) and (max-width: 1100px) {
    .online_icon {
      display: inherit;
      position: relative;
      bottom: 21px;
      left: 40px;
      width: 430px;
      height: 349px;
    }
  }
}

/* 테블릿 가로, 테블릿 세로 (해상도 768px ~ 1024px)*/
@media all and (min-width: 768px) and (max-width: 1024px) {
  .wrapper_h {
    display: flex;
    width: 100%;
    height: 630px;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
  }

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    /* background-repeat: no-repeat; */
    background-position: 50% 0;
    background-size: cover;
    transition: 0.3s;
    opacity: 1;
    animation: fadeInBg 10s linear both;
  }

  .background:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .background_blur {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    /* z-index: -1; */
  }

  .support_area {
    height: 100%;
  }

  .support_title_wrap {
    display: flex;
    flex-direction: column;
    row-gap: 49px;
    align-items: center;
    height: 100%;
    justify-content: center;
  }

  .support_title {
    font-family: Pretendard;
    font-size: 60px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .support_subtitle {
    font-family: Pretendard;
    font-size: 19px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .support_category {
    height: 67px;
    line-height: 67px;
    border-bottom: 1px solid #c0c0c0;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
  }

  .support_category_buttons {
    display: flex;
    width: calc(100% - 40px);
  }

  .support_button {
    line-height: 89px;
    height: 89px;
    font-size: 19px;
    font-family: Pretendard;
    margin-right: 61px;
    font-weight: bold;
    color: #191919;
  }

  .button_focus {
    border-bottom: 2px solid #00177c;
    color: #00177c;
  }

  .onsite_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .online_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: 790px;
  }

  .onsite_body {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .onsite_quote {
    width: 100%;
    text-align: right;
    margin-bottom: 30px;
    font-family: Pretendard;
  }

  .onsite_quote_mark {
    color: #009fa9;
  }

  .onsite_contents {
    display: flex;
    flex-direction: column;
    width: 660px;
    row-gap: 40px;
  }

  .onsite_header {
    margin-top: 0px;
    /* width: 450px; */
  }

  .onsite_title {
    font-family: Pretendard;
    font-size: 45px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.23px;
    text-align: left;
    color: #191919;
    margin-bottom: 47px;
  }

  .onsite_subtitle {
    width: 100%;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #2e2f30;
    letter-spacing: -0.01px;
    line-height: 23px;
  }

  .onsite_subtitle2 {
    color: #00177c;
    font-weight: 500;
  }

  .onsite_input_line {
    width: 660px;
    display: flex;
    justify-content: space-between;
  }

  .onsite_input_wrapper {
    margin-bottom: 25px;
  }

  .onsite_input_header {
    font-family: pretendard;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #111;
    margin-bottom: 16px;
  }

  .onsite_input_header_none {
    height: 39px;
  }

  .onsite_input_body {
    width: 256px;
    height: 58px;
    padding: 0px 22px;
    display: flex;
    border: 1px solid #e1e1e1;
  }

  .onsite_input_post {
    width: 616px;
    height: 58px;
    padding: 0px 22px;
    display: flex;
    border: 1px solid #e1e1e1;
  }

  .onsite_input_textarea {
    width: 626px;
    height: 122px;
    padding: 20px 22px;
    display: flex;
    border: 1px solid #e1e1e1;
  }

  .onsite_input {
    width: 100%;
    outline: none;
    border: none;
    font-size: 16px;
    font-family: Pretendard;
  }

  .onsite_email_text {
    margin-top: 5px;
    margin-left: 5px;
    font-size: 14px;
    font-family: Pretendard;
  }

  .onsite_input_time {
    width: 130px;
    outline: none;
    border: none;
    font-size: 16px;
    font-family: Pretendard;
  }

  .onsite_post {
    width: 100%;
    outline: none;
    border: none;
    font-size: 16px;
    font-family: Pretendard;
  }

  .onsite_textarea {
    width: 100%;
    outline: none;
    border: none;
    font-size: 16px;
    height: 122px;
    resize: none;
    font-family: Pretendard;
  }

  .onsite_line {
    width: 100%;
    height: 1px;
    background-color: #e1e1e1;
    margin-bottom: 25px;
  }

  .postmodal {
    margin: 15% auto;
    width: 600px;
    border: 1px solid black;
  }

  .submitmodal {
    width: 441px;
    height: 215px;
    margin: 15% auto;
    padding: 40px 0px 20px 0px;
    border-radius: 20px;
    border: solid 1px #e1e1e1;
    background-color: #fff;
  }

  .submitmodal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1001;
  }

  .sm_body {
    display: flex;
    width: 100%;
    flex-direction: column;
    row-gap: 35px;
    align-items: center;
  }

  .sm_title {
    height: 24px;
    font-family: Pretendard;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #111;
    line-height: 24px;
  }

  .sm_text {
    text-align: center;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #333;
    font-weight: 500;
  }

  .sm_point {
    color: #00177c;
    font-weight: 600;
  }

  .sm_button {
    width: 152px;
    height: 53px;
    line-height: 53px;
    text-align: center;
    border-radius: 5px;
    background-color: #00177c;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #fff;
  }

  .selectbox_onsite {
    width: 300px;
    padding: 0px 22px;
    height: 60px;
    outline: none;
    font-size: 16px;
    font-family: Pretendard;
    border: 1px solid #e1e1e1;
    background: url("http://bluetns.co.kr/img/common/arrow_down.png") no-repeat
      94% 50% / 15px auto;
    color: #191919;
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
  }

  .selectbox_list {
    height: 100px;
  }

  .send_button {
    margin: 35px auto 100px auto;
    width: 255px;
    height: 60px;
    border-radius: 5px;
    line-height: 60px;
    text-align: center;
    background-color: #00177c;
    font-family: Pretendard;
    font-size: 19px;
    font-weight: 500;
    color: #fff;
  }

  .online_title {
    margin-top: 20px;
    margin-bottom: 80px;

    font-family: Pretendard;
    font-size: 52px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.32px;
    text-align: left;
    color: #191919;
  }

  .online_body {
    background-color: #eff5fa;
    width: 100%;
    height: 240px;
    display: flex;
    justify-content: center;
  }

  .online_info {
    width: 100%;
    display: flex;
    flex-direction: column;
    width: 660px;
    align-items: center;
  }

  .online_icon {
    display: inherit;
    position: relative;
    bottom: 40px;
    width: 400px;
  }

  .online_text {
    text-align: center;
  }

  .info_text {
    margin-top: 10px;
    margin-bottom: 40px;
    font-family: Pretendard;
    font-size: 21px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.52;
    letter-spacing: normal;
    text-align: center;
    color: #2e2f30;
  }

  .info_text2 {
    color: #00177c;
  }

  .info_box {
    width: 532px;
    padding: 0px 22px;
    height: 134px;
    background-color: rgba(207, 225, 240, 0.5);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 100px;
  }

  .info_box_header {
    font-family: Pretendard;
    font-size: 30px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #191919;
    margin-bottom: 19px;
  }

  .info_box_info {
    width: 253px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.5px;
    text-align: left;
  }

  .info_box_info2 {
    color: #00177c;
  }

  .info_box_button {
    width: 256px;
    height: 70px;
    text-align: center;
    line-height: 70px;
    font-family: Pretendard;
    font-size: 22px;
    background-color: #00177c;
    border-radius: 5px;
    color: #fff;
  }

  .faqlist {
    margin-bottom: 80px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .faqmore {
    text-align: center;
    width: 191px;
    height: 60px;
    margin: 0 auto 100px auto;
    border-radius: 30px;
    border: solid 1px #e1e1e1;
    background-color: #fff;
    line-height: 60px;
    font-family: Pretendard;
    font-size: 19px;
    font-weight: 500;
  }

  .faqline {
    display: flex;
    justify-content: center;
    width: 660px;
  }

  .faqsearchline {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .faqsearch {
    width: 660px;
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid #191919;
    padding-bottom: 36px;
  }

  .faqsearchbox {
    width: 337px;
    height: 52px;
    border: 1px solid #e1e1e1;
    padding: 0px 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .faqsearchinput {
    width: 300px;
    height: 17px;
    font-size: 17px;
    line-height: 17px;
    font-family: Pretendard;
    outline: none;
    border: none;
  }

  .faq_open {
    width: calc(100% - 60px);
    border-bottom: 1px solid #e1e1e1;
    transition: all 0.3s ease-in-out;
    display: flex;
    padding: 30px 28px 30px 32px;
    background-color: #f8f8f8;
  }

  .faq_close {
    width: calc(100% - 60px);
    max-height: 0px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    display: flex;
    padding: 0px 28px 0px 32px;
  }

  .answer {
    min-width: 32px;
    height: 32px;
    border-radius: 40px;
    background-color: #00177c;
    color: white;
    font-size: 20px;
    font-family: Pretendard;
    margin-right: 46px;
    text-align: center;
    line-height: 32px;
  }

  .answer_text {
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    text-align: left;
    color: #2e2f30;
    padding-top: 12px;
    width: 100%;
  }

  .faq_databody {
    width: 100%;
  }

  .open_body {
    width: 100%;
  }

  .faq_contents_title {
    height: 60px;
    border-bottom: 1px solid #e1e1e1;
    display: flex;
    width: calc(100% - 80px);
    padding: 0px 40px;
    justify-content: space-between;
    align-items: center;
  }

  .titleQ {
    margin-right: 53px;
    font-size: 20px;
    font-family: Pretendard;
  }

  .open_title {
    font-family: Pretendard;
    font-size: 19px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #00177c;
  }

  .close_title {
    font-family: Pretendard;
    font-size: 19px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #111111;
  }
}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width: 767px) {
  .wrapper_h {
    display: flex;
    width: 100%;
    height: 530px;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
  }

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    /* background-repeat: no-repeat; */
    background-position: 50% 0;
    background-size: cover;
    transition: 0.3s;
    opacity: 1;
    animation: fadeInBg 10s linear both;
  }

  .background:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .background_blur {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    /* z-index: -1; */
  }

  .support_area {
    height: 100%;
  }

  .support_title_wrap {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    align-items: center;
    height: 100%;
    justify-content: center;
  }

  .support_title {
    font-family: Pretendard;
    font-size: 34px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .support_subtitle {
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .support_category {
    height: 67px;
    line-height: 67px;
    border-bottom: 1px solid #c0c0c0;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
  }

  .support_category_buttons {
    display: flex;
    width: calc(100% - 40px);
  }

  .support_button {
    line-height: 89px;
    height: 89px;
    font-size: 19px;
    font-family: Pretendard;
    margin-right: 61px;
    font-weight: bold;
    color: #191919;
  }

  .button_focus {
    border-bottom: 2px solid #00177c;
    color: #00177c;
  }

  .onsite_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .online_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .onsite_body {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .onsite_quote {
    width: 100%;
    text-align: right;
    margin-bottom: 30px;
    font-family: Pretendard;
  }

  .onsite_quote_mark {
    color: #009fa9;
  }

  .onsite_contents {
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);
    row-gap: 40px;
  }

  .onsite_header {
    margin-top: 0px;
    /* width: 450px; */
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .onsite_title {
    font-family: Pretendard;
    font-size: 45px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.23px;
    text-align: center;
    color: #191919;
    margin-bottom: 47px;
  }

  .onsite_subtitle {
    /* width: 100%; */
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #2e2f30;
    letter-spacing: -0.01px;
    line-height: 23px;
    width: 355px;
  }

  .onsite_subtitle2 {
    color: #00177c;
    font-weight: 500;
  }

  .onsite_input_line {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .onsite_input_flex {
    flex-direction: row;
  }

  .onsite_input_wrapper {
    margin-bottom: 25px;
  }

  .onsite_input_header {
    font-family: pretendard;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #111;
    margin-bottom: 16px;
  }

  .onsite_input_header_none {
    height: 35px;
  }

  .onsite_input_body {
    width: calc(100% - 30px);
    height: 50px;
    padding: 0px 15px;
    display: flex;
    border: 1px solid #e1e1e1;
  }

  .onsite_input_post {
    width: calc(100% - 30px);
    height: 58px;
    padding: 0px 15px;
    display: flex;
    border: 1px solid #e1e1e1;
  }

  .onsite_input_textarea {
    width: calc(100% - 30px);
    height: 123px;
    padding: 16px 15px;
    display: flex;
    border: 1px solid #e1e1e1;
  }

  .onsite_input {
    width: 100%;
    outline: none;
    border: none;
    font-size: 16px;
    font-family: Pretendard;
  }

  .onsite_email_text {
    margin-top: 5px;
    margin-left: 5px;
    font-size: 14px;
    font-family: Pretendard;
  }

  .onsite_input_time {
    width: 130px;
    outline: none;
    border: none;
    font-size: 16px;
    font-family: Pretendard;
    color: #191919;
    appearance: none;
  }

  .onsite_post {
    width: 100%;
    outline: none;
    border: none;
    font-size: 16px;
    font-family: Pretendard;
  }

  .onsite_textarea {
    width: 100%;
    outline: none;
    border: none;
    font-size: 16px;
    height: 122px;
    resize: none;
    font-family: Pretendard;
  }

  .onsite_line {
    width: 100%;
    height: 1px;
    background-color: #e1e1e1;
    margin-bottom: 25px;
  }

  .postmodal {
    margin: 35% auto;
    width: calc(100% - 40px);
    border: 1px solid black;
  }

  .post_daum {
    width: 100%;
  }

  .submitmodal {
    width: calc(100% - 40px);
    height: 230px;
    margin: 50% auto;
    padding: 29px 0px 20px 0px;
    border-radius: 20px;
    border: solid 1px #e1e1e1;
    background-color: #fff;
  }

  .submitmodal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1001;
  }

  .sm_body {
    display: flex;
    width: 100%;
    flex-direction: column;
    row-gap: 35px;
    align-items: center;
  }

  .sm_title {
    height: 24px;
    font-family: Pretendard;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #111;
    line-height: 24px;
  }

  .sm_text {
    text-align: center;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #333;
    font-weight: 500;
  }

  .sm_point {
    color: #00177c;
    font-weight: 600;
  }

  .sm_button {
    width: 152px;
    height: 53px;
    line-height: 53px;
    text-align: center;
    border-radius: 5px;
    background-color: #00177c;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #fff;
  }

  .onsite_sb1 {
    width: 60%;
  }

  .onsite_sb2 {
    width: 35%;
  }

  .selectbox_onsite {
    width: 100%;
    padding: 0px 22px;
    height: 60px;
    outline: none;
    font-size: 16px;
    font-family: Pretendard;
    border: 1px solid #e1e1e1;
    background: url("http://bluetns.co.kr/img/common/arrow_down.png") no-repeat
      94% 50% / 15px auto;

    color: #191919;
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
  }

  .selectbox_list {
    height: 100px;
  }

  .send_button {
    margin: 50px auto 60px auto;
    width: 140px;
    height: 50px;
    border-radius: 5px;
    line-height: 50px;
    text-align: center;
    background-color: #00177c;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
  }

  .online_title {
    margin-bottom: 52px;

    font-family: Pretendard;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.32px;
    text-align: left;
    color: #191919;
  }

  .online_body {
    background-color: #eff5fa;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .online_info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .online_icon {
    display: inherit;
    position: relative;
    bottom: 10px;
    width: 60%;
    margin: 0px auto;
  }

  .online_text {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .info_text {
    margin-top: 40px;
    margin-bottom: 40px;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.52;
    letter-spacing: normal;
    text-align: center;
    color: #2e2f30;
    letter-spacing: -0.8px;
  }

  .info_text2 {
    color: #00177c;
  }

  .info_box {
    width: calc(100% - 40px);
    padding: 25px 0px;
    gap: 25px;
    background-color: rgba(207, 225, 240, 0.5);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 60px;
  }

  .info_box_header {
    font-family: Pretendard;
    font-size: 30px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #191919;
    margin-bottom: 19px;
    text-align: center;
  }

  .info_box_info {
    width: 253px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.5px;
    text-align: center;
  }

  .info_box_info2 {
    color: #00177c;
    text-align: center;
    font-size: 14px;
  }

  .info_box_button {
    width: 256px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    font-family: Pretendard;
    font-size: 17px;
    background-color: #00177c;
    border-radius: 5px;
    color: #fff;
  }

  .faqlist {
    margin-bottom: 80px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .faqmore {
    text-align: center;
    width: 142px;
    height: 48px;
    margin: 0 auto 100px auto;
    border-radius: 24px;
    border: solid 1px #e1e1e1;
    background-color: #fff;
    line-height: 48px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
  }

  .faqline {
    display: flex;
    justify-content: center;
    width: calc(100% - 40px);
  }

  .faqsearchline {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .faqsearch {
    width: calc(100% - 40px);
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid #191919;
    padding-bottom: 36px;
  }

  .faqsearchbox {
    width: 215px;
    height: 45px;
    border: 1px solid #e1e1e1;
    padding: 0px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .faqsearchinput {
    width: calc(100% - 40px);
    height: 14px;
    font-size: 14px;
    line-height: 14px;
    font-family: Pretendard;
    outline: none;
    border: none;
  }

  .searchicon {
    width: 19px;
  }

  .faq_open {
    width: calc(100% - 20px);
    border-bottom: 1px solid #e1e1e1;
    transition: all 0.3s ease-in-out;
    display: flex;
    padding: 30px 10px 30px 10px;
    background-color: #f8f8f8;
  }

  .faq_close {
    width: calc(100% - 20px);
    max-height: 0px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    display: flex;
    padding: 0px 10px 0px 10px;
  }

  .answer {
    min-width: 26px;
    height: 26px;
    border-radius: 40px;
    background-color: #00177c;
    color: white;
    font-size: 18px;
    font-family: Pretendard;
    margin-right: 17px;
    text-align: center;
    line-height: 26px;
  }

  .answer_text {
    font-family: Pretendard;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    text-align: left;
    color: #2e2f30;
    padding-top: 5px;
    width: 100%;
  }

  .faq_databody {
    width: 100%;
  }

  .open_body {
    width: 100%;
  }

  .faq_contents_title {
    height: 60px;
    border-bottom: 1px solid #e1e1e1;
    display: flex;
    width: calc(100% - 20px);
    padding: 0px 10px;
    justify-content: space-between;
    align-items: center;
  }

  .titleQ {
    margin-right: 10px;
    font-size: 18px;
    font-family: Pretendard;
  }

  .open_title {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #00177c;
  }

  .close_title {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #111111;
  }
}
