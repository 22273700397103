.paging-container {
  display: flex;
}

.paging-container-landing {
  display: flex;
  justify-content: center;
}

.paging-item {
  height: 30px;
  min-width: 15px;
  padding: 0px 5px;
  margin-right: 5px;
  transition-property: color, border-color, background-color;
  transition-duration: 100ms;
  transition-timing-function: linear;
  font-family: Pretendard;
  font-size: 19px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #191919;
}

.paging-item:last-of-type {
  margin-right: 0;
}

.paging-item:hover,
.paging-item.active {
  color: #00177c;
  border-color: var(--ShareBlue);
  background-color: var(--ShareBlue);
  border-bottom: 1px solid #00177c;
}

.landing-request-list-content.INTEREST .paging-item:hover,
.landing-request-list-content.INTEREST .paging-item.active {
  color: var(--Background);
  border-color: #0c2ebb;
  background-color: #0c2ebb;
}

.landing-request-list-content.TIME .paging-item:hover,
.landing-request-list-content.TIME .paging-item.active {
  color: var(--Background);
  border-color: #77dc00;
  background-color: #77dc00;
}

.landing-request-list-content.TAG .paging-item:hover,
.landing-request-list-content.TAG .paging-item.active {
  color: var(--Background);
  border-color: #ef6c00;
  background-color: #ef6c00;
}

.landing-class-paging.INTEREST .paging-item:hover,
.landing-class-paging.INTEREST .paging-item.active {
  color: var(--Background);
  border-color: #0c2ebb;
  background-color: #0c2ebb;
}

.landing-class-paging.TIME .paging-item:hover,
.landing-class-paging.TIME .paging-item.active {
  color: var(--Background);
  border-color: #77dc00;
  background-color: #77dc00;
}

.landing-class-paging.HASHTAG .paging-item:hover,
.landing-class-paging.HASHTAG .paging-item.active {
  color: var(--Background);
  border-color: #ef6c00;
  background-color: #ef6c00;
}
