.float_btn_area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding: 13px 8px 0; */
}

.floatinb_btn_list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    /* background-color: rgba(9, 11, 17, 0.8); */
    background-color: rgb(25, 29, 39, 0.8);

}

.ea_btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 13px 0 11px;
    gap: 10px;
    background-color: #090b11;
    width: 100%;
}

.ea_label {
    border-top: 1px solid rgba(255, 255, 255, 0.25);
    padding-top: 10px;
    height: 13px;
    line-height: 13px;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.49px;
    color: #fff;
}

.floating_btn_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1px;
}

.floating_btn_item:last-child {
    margin-bottom: 12px;
}

.floating_btn {
    width: 100% !important;
    height: 100% !important;
    margin-bottom: 0px !important;
    background-color: transparent !important;
    border-bottom: none !important;
}

.floating_btn :global .ant-float-btn-body {
    background-color: transparent;
}

.float_btn_area :global .ant-float-btn {
    background-color: #191d27;
}

.float_btn_area :global .ant-float-btn-body {
    background-color: transparent !important;
}

.float_btn_area:global.ant-float-btn-group-square .ant-float-btn-square:last-child {
    border-radius: 0px;
}

.float_btn_area :global .ant-float-btn-content {
    width: 100%;
    height: 100%;
    background-color: transparent;
}

.float_btn_area :global .ant-float-btn-icon {
    width: 100% !important;
    height: 100%;
}


.floating_open_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.floating_open_btn>span {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.floating_open_btn>span:before,
.floating_open_btn>span:after {
    position: absolute;
    content: ' ';
    height: 23px;
    width: 2px;
    background-color: #fff;
}

.floating_open_btn>span:after {
    transform: rotate(-90deg);
}

.floating_btn :global .ant-float-btn-body {
    background-color: transparent;
}

.floating_btn :global .ant-float-btn-content {
    width: 100% !important;
}

.floating_btn :global .ant-float-btn-icon {
    margin: 0 !important;
    width: 100% !important;
    display: flex;
    justify-content: center;
}

.floating_btn_label {
    height: 13px;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}

.top_btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 74px;
    background-color: #009fa9;
    gap: 12px;
    cursor: pointer;
}

.top_btn_label {
    height: 13px;
    line-height: 13px;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}

@media all and (min-width: 768px) and (max-width: 1024px) {
    .top_btn {
        width: 80px;
        height: 80px;
        gap: 4px;
    }

    .top_btn>img {
        width: 22px;
        height: 14px;
    }

    .float_btn_area {
        /* gap: 0px; */
        margin-bottom: 132px;
    }

    .floatinb_btn_list {
        gap: 15px;
        background: none;
        padding: 20px 0 20px;
    }

    .ea_btn {
        padding: 8px 0;
        gap: 7.5px;
    }

    .ea_btn>img {
        width: 44px;
        height: 21px;
    }

    .ea_label {
        font-size: 9px;
        line-height: 11px;
        height: 11px;
        letter-spacing: -0.18px;
        padding-top: 7.5px;
    }

    .float_btn_area :global .ant-float-btn-group-wrap {
        margin-bottom: 0;
        width: 100%;
        box-shadow: none;
        background-color: rgba(25, 29, 39, 0.8);
        border-radius: 0;
    }

    .float_btn_area :global .ant-float-btn-icon {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .floating_btn_item:last-child {
        margin-bottom: 0px;
    }

    .floating_btn_label {
        font-family: Pretendard;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
    }
}

@media all and (max-width: 767px) {
    .top_btn {
        width: 52px;
        height: 52px;
        gap: 4px;
    }

    .top_btn>img {
        width: 22px;
        height: 14px;
    }

    .float_btn_area {
        margin-bottom: 52px;
    }

    .floatinb_btn_list {
        padding-top: 20px;
        padding-bottom: 10px;
        gap: 10px;
    }

    .ea_btn {
        padding: 8px 0;
        gap: 7.5px;
    }

    .ea_btn>img {
        width: 44px;
        height: 21px;
    }

    .ea_label {
        font-size: 9px;
        line-height: 11px;
        height: 11px;
        letter-spacing: -0.18px;
        padding-top: 7.5px;
    }

    .float_btn_area :global .ant-float-btn-group-wrap {
        width: 100%;
        margin-bottom: 0;
        box-shadow: none;
    }

    .float_btn_area :global .ant-float-btn-icon {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .floating_btn_item:last-child {
        margin-bottom: 0px;
    }

    .top_btn_label {
        font-size: 14px;
    }
}