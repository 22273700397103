.innertext {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media all and (min-width: 768px) {
  .pop_contents {
    width: 500px;
    height: 500px;
    overflow: hidden;
  }

  .pop_img {
    height: 500px;
    display: flex;
    /* transform: translate(0); */
    transition-property: transform;
    transition-duration: 0.5s;
    height: 500px;
  }

  .img_on {
    width: 100%;
    height: 500px;
    min-width: 500px;
  }

  .img_hide {
    width: 100%;
    height: auto;
    min-width: 500px;
    z-index: 0;
  }

  .pop_num_btn {
    display: flex;
    gap: 10px;
  }

  .pop_num_btn {
    display: flex;
    gap: 10px;
  }

  .pop_selected {
    height: 8px;
    width: 8px;
    border-radius: 8px;
    background-color: #00177c;
    cursor: pointer;
  }

  .pop_nselected {
    height: 8px;
    width: 8px;
    border-radius: 8px;
    background-color: #bfc4cf;
    cursor: pointer;
  }
}

@media all and (max-width: 767px) {
  .pop_contents {
    width: 300px;
    height: 300px;
    overflow: hidden;
  }

  .pop_img {
    width: 300px;
    height: 300px;
    display: flex;
    /* transform: translate(0); */
    transition-property: transform;
    transition-duration: 0.5s;
  }

  .img_on {
    min-width: 300px;
    height: 300px;
  }

  .img_hide {
    min-width: 300px;
    height: 300px;
    z-index: 0;
  }

  .pop_num_btn {
    display: flex;
    gap: 10px;
  }

  .pop_selected {
    height: 8px;
    width: 8px;
    border-radius: 8px;
    background-color: #00177c;
    cursor: pointer;
  }

  .pop_nselected {
    height: 8px;
    width: 8px;
    border-radius: 8px;
    background-color: #bfc4cf;
    cursor: pointer;
  }
}

/* PC(해상도 1920px ~)*/
@media all and (min-width: 1921px) {
  .wrapper {
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    position: relative;
    overflow: hidden;
  }

  .background_blur {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .donut {
    position: absolute;
    width: 25px;
    top: 50vh;
    left: 50px;
    height: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 13px;
    z-index: 9;
  }

  .skipbtn {
    width: 7px;
    height: 7px;
    background-color: #e1e1e1;
    border-radius: 7px;
  }

  .skbtn_none {
    display: none;
  }

  .scrollicon {
    position: absolute;
    bottom: 0vh;
    transform: translatey(0px);
    animation: float 1.2s ease-in-out infinite;
  }

  @keyframes float {
    0% {
      /* box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6); */
      transform: translatey(0px);
    }

    50% {
      /* box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2); */
      transform: translatey(-20px);
    }

    100% {
      /* box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6); */
      transform: translatey(0px);
    }
  }

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    /* transform: scale(1.1) rotate(-0.01deg); */
    transform: translate(0);
    transition-property: transform;
    transition-duration: 1.5s;
  }

  .background_none {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    /* transform: scale(1.1) rotate(-0.01deg); */
    transform: translate(-100%);
    transition-property: transform;
    transition-duration: 1.5s;
  }

  .background2 {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    /* transform: scale(1.1) rotate(-0.01deg); */
    transform: translate(100%);
    transition-property: transform;
    transition-duration: 1.5s;
  }

  .background_none2 {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    /* transform: scale(1.1) rotate(-0.01deg); */
    transform: translate(0);
    transition-property: transform;
    transition-duration: 1.5s;
  }

  /* 메인 */
  .swiper_area {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transform: translate(0);
    transition-property: transform;
    transition-duration: 1.5s;
  }

  .swiper_area_none {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transform: translate(-100%);
    transition-property: transform;
    transition-duration: 1.5s;
  }

  .swiper_area2 {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transform: translate(100%);
    transition-property: transform;
    transition-duration: 1.5s;
  }

  .swiper_area_none2 {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transform: translate(0);
    transition-property: transform;
    transition-duration: 1.5s;
  }

  .swiper_text1 {
    height: 41px;
    line-height: 41px;
    font-family: Pretendard;
    font-size: 40px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .swiper_text2 {
    margin-top: 45px;
    height: 70px;
    line-height: 70px;
    font-family: Pretendard;
    font-size: 95px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .swiper_text3 {
    margin-top: 53px;
    height: 25px;
    line-height: 25px;
    font-family: Pretendard;
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  /* 상품 */
  .product_area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70%;
    height: 100%;
    padding: 0 15%;
  }

  .product_title {
    height: 49px;
    font-family: Pretendard;
    font-size: 64px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 49px;
    letter-spacing: normal;
    text-align: left;
    color: #111;
  }

  .product_subtitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;
    margin-top: 59px;
    margin-bottom: 46px;
  }

  .product_description:first-child {
    height: 27px;
    font-family: Pretendard;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 27px;
    letter-spacing: normal;
    text-align: left;
    color: #2e2f30;
  }

  .product_description:last-child {
    height: 20px;
    font-family: Pretendard;
    font-size: 19px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: normal;
    text-align: left;
    color: #898a8c;
  }

  .product_card_list {
    display: flex;
    gap: 20px;
  }

  .product_card_wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .product_card_wrapper:first-child {
    margin-top: 104px;
  }

  .product_card_wrapper:nth-child(2) {
    margin-top: 52px;
  }

  .product_card_wrapper:nth-child(3) {
    margin-bottom: 52px;
  }

  .product_card_wrapper:last-child {
    margin-bottom: 104px;
    justify-content: flex-end;
  }

  .product_card_item {
    width: 305px;
    height: 230px;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    border-radius: 15px;
  }

  .product_card_img {
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
    position: absolute;
    z-index: -1;
  }

  .product_card_img_hover {
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
    position: absolute;
    z-index: -1;
    transform: scale(1.1);
  }

  .product_info_wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 60px);
    height: calc(100% - 60px);
    padding: 30px;
    background-color: rgba(34, 34, 34, 0.35);
  }

  .product_info {
    display: flex;
    flex-direction: column;
    gap: 19px;
  }

  .product_info_title {
    font-family: Pretendard;
    font-size: 28px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.14px;
    text-align: left;
    color: #fff;
    white-space: pre-line;
  }

  .product_info_subtitle {
    height: 15px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 15px;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .product_nav_wrap {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .product_nav {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;
    padding: 6px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    cursor: pointer;
  }

  .product_nav_inner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 50%;
  }

  /* 이벤트 */
  .event_background_container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: calc(50% - 112px);
    height: calc(100% - 42px);
    background-color: #fff;
    padding-left: 91px;
    padding-right: 21px;
    padding-bottom: 42px;
  }

  .event_background_container > img {
    width: 100%;
  }

  .event_area {
    display: flex;
    align-items: center;
    width: 85%;
    height: 100%;
    padding-left: 15%;
    gap: 300px;
  }

  .event_area_label {
    display: flex;
    flex-direction: column;
    gap: 69px;
  }

  .event_area_title_wrap {
    display: flex;
    flex-direction: column;
    gap: 39px;
  }

  .event_area_title {
    height: 46px;
    font-family: Poppins;
    font-size: 64px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 46px;
    letter-spacing: normal;
    text-align: left;
    color: #111;
  }

  .event_area_subtitle {
    height: 24px;
    font-family: Pretendard;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 24px;
    letter-spacing: normal;
    text-align: left;
    color: #2e2f30;
    white-space: nowrap;
  }

  .event_nav {
    display: flex;
    align-items: center;
    gap: 11px;
  }

  .event_nav_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;
    border-radius: 50%;
    border: 2px solid #000000;
  }

  .event_nav_btn:global.disable {
    border: 2px solid #b1b4b8;
    cursor: default;
  }

  .event_nav_btn:global.disable > img {
    filter: grayscale(100%);
  }

  .event_nav_btn:first-child > img {
    transform: rotate(180deg);
  }

  .event_next_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;
    border-radius: 50%;
    border: 2px solid #000000;
  }

  .event_list {
    display: flex;
    align-items: center;
    /* width: 70%; */
  }

  .event_item {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: 0px 10px 27px 0 rgba(0, 0, 0, 0.07);
    background-color: #fff;
  }

  .event_info_wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px;
    gap: 58px;
  }

  .event_title {
    font-family: Pretendard;
    font-size: 21px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: normal;
    text-align: left;
    color: #111;
  }

  .event_input_date {
    height: 14px;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 14px;
    letter-spacing: normal;
    text-align: left;
    color: #6f7277;
  }

  /* 공지사항 */
  .notice_area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 70%;
    height: 100%;
    padding: 0 15%;
  }

  .notice_area_label {
    display: flex;
    flex-direction: column;
    gap: 70px;
  }

  .notice_area_title_wrap {
    display: flex;
    flex-direction: column;
    gap: 39px;
  }

  .notice_area_title {
    height: 58px;
    font-family: Pretendard;
    font-size: 64px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 58px;
    letter-spacing: normal;
    text-align: left;
    color: #111;
  }

  .notice_area_subtitle {
    height: 24px;
    font-family: Pretendard;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 24px;
    letter-spacing: normal;
    text-align: left;
    color: #2e2f30;
    white-space: nowrap;
  }

  .notice_nav {
    display: flex;
    align-items: center;
  }

  .notice_viewmore_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 213px;
    height: 46px;
    border-radius: 25px;
    border: solid 2px #111;
    font-family: Poppins;
    font-size: 19px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #111;
  }

  .notice_list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 35px;
    height: 100%;
  }

  .notice_item {
    display: flex;
    justify-content: space-between;
    padding-bottom: 35px;
    border-bottom: 2px solid #e1e1e1;
    gap: 100px;
    width: 833px;
  }

  .notice_info_wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .notice_info_container {
    display: flex;
    flex-direction: column;
    width: 536.5px;
  }

  .notice_label {
    height: 15px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 15px;
    letter-spacing: normal;
    text-align: left;
    color: #00177c;
  }

  .notice_title {
    height: 20px;
    font-family: Pretendard;
    font-size: 21px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: normal;
    text-align: left;
    color: #111;
    margin-top: 29px;
  }

  .notice_text {
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.65;
    letter-spacing: normal;
    text-align: left;
    color: #6f7277;
    margin-top: 25px;
    white-space: pre-line;
    max-height: 56px;
    overflow: hidden;
  }

  .notice_input_date {
    height: 14px;
    font-family: Pretendard;
    font-size: 17.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 14px;
    letter-spacing: normal;
    text-align: left;
    color: #6f7277;
  }

  .notice_img {
    box-shadow: 0px 7px 10px 0 rgba(0, 0, 0, 0.2);
    width: 196px;
    height: 184px;
  }

  /* 고객사 */

  .customer_area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100% - 600px);
    height: 100%;
    background-color: #f8f8f8;
    gap: 146px;
    padding: 0 300px;
  }

  .customer_title_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 39px;
  }

  .customer_title {
    height: 47px;
    font-family: Poppins;
    font-size: 64px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 47px;
    letter-spacing: normal;
    text-align: left;
    color: #111;
  }

  .customer_subtitle {
    height: 24px;
    font-family: Pretendard;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 24px;
    letter-spacing: normal;
    text-align: left;
    color: #2e2f30;
  }

  .customer_list {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 29px 10px;
  }

  .customer_list > img {
    width: 100%;
  }

  .product_card_item_wrap {
    width: 305px;
    height: 230px;
  }
}

/* PC (해상도 1024px)*/
@media all and (min-width: 1025px) and (max-width: 1920px) {
  .wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    position: relative;
    overflow: hidden;
  }
  .wrapperevent {
    height: 100vh;
  }

  .wrapper:nth-child(2) {
    height: 100vh;
  }

  .donut {
    position: absolute;
    width: 25px;
    top: 50vh;
    left: 50px;
    height: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 13px;
    z-index: 9;
  }

  .skipbtn {
    width: 7px;
    height: 7px;
    background-color: #e1e1e1;
    border-radius: 7px;
  }

  .skbtn_none {
    display: none;
  }

  .scrollicon {
    position: absolute;
    bottom: 0vh;
    transform: translatey(0px);
    animation: float 1.2s ease-in-out infinite;
  }

  @keyframes float {
    0% {
      /* box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6); */
      transform: translatey(0px);
    }

    50% {
      /* box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2); */
      transform: translatey(-20px);
    }

    100% {
      /* box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6); */
      transform: translatey(0px);
    }
  }

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    /* transform: scale(1.1) rotate(-0.01deg); */
    transform: translate(0);
    transition-property: transform;
    transition-duration: 1.5s;
  }

  .background_none {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    /* transform: scale(1.1) rotate(-0.01deg); */
    transform: translate(-100%);
    transition-property: transform;
    transition-duration: 1.5s;
  }

  .background2 {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    /* transform: scale(1.1) rotate(-0.01deg); */
    transform: translate(100%);
    transition-property: transform;
    transition-duration: 1.5s;
  }

  .background_none2 {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    /* transform: scale(1.1) rotate(-0.01deg); */
    transform: translate(0);
    transition-property: transform;
    transition-duration: 1.5s;
  }

  .background_blur {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .background:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  /* 메인 */
  .swiper_area {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transform: translate(0);
    transition-property: transform;
    transition-duration: 1.5s;
  }

  .swiper_area_none {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transform: translate(-100%);
    transition-property: transform;
    transition-duration: 1.5s;
  }

  .swiper_area2 {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transform: translate(100%);
    transition-property: transform;
    transition-duration: 1.5s;
  }

  .swiper_area_none2 {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transform: translate(0);
    transition-property: transform;
    transition-duration: 1.5s;
  }

  .swiper_text1 {
    height: 41px;
    line-height: 41px;
    font-family: Pretendard;
    font-size: 40px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .swiper_text2 {
    margin-top: 45px;
    height: 70px;
    line-height: 70px;
    font-family: Pretendard;
    font-size: 95px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .swiper_text3 {
    margin-top: 53px;
    height: 25px;
    line-height: 25px;
    font-family: Pretendard;
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  /* 상품 */
  .product_area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100% - 160px);
    height: calc(100% - 160px);
    padding: 80px;
  }

  .product_title {
    height: 49px;
    font-family: Poppins;
    font-size: 64px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 49px;
    letter-spacing: normal;
    text-align: left;
    color: #111;
  }

  .product_subtitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;
    margin-top: 59px;
    margin-bottom: 46px;
  }

  .product_description:first-child {
    height: 27px;
    font-family: Pretendard;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 27px;
    letter-spacing: normal;
    text-align: left;
    color: #2e2f30;
  }

  .product_description:last-child {
    height: 20px;
    font-family: Pretendard;
    font-size: 19px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: normal;
    text-align: left;
    color: #898a8c;
  }

  .product_card_list {
    display: flex;
    gap: 20px;
    max-width: 1280px;
    width: 100%;
  }

  .product_card_wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }

  .product_card_wrapper:first-child {
    margin-top: 104px;
  }

  .product_card_wrapper:nth-child(2) {
    margin-top: 52px;
  }

  .product_card_wrapper:nth-child(3) {
    margin-bottom: 52px;
  }

  .product_card_wrapper:last-child {
    margin-bottom: 104px;
    justify-content: flex-end;
  }

  .product_card_item {
    /* width: 305px; */
    width: 100%;
    height: 230px;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    border-radius: 15px;
  }

  .product_card_img {
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
    position: absolute;
    z-index: -1;
  }

  .product_card_img_hover {
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
    position: absolute;
    z-index: -1;
    transform: scale(1.1);
  }

  .product_info_wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 60px);
    height: calc(100% - 60px);
    padding: 30px;
    background-color: rgba(34, 34, 34, 0.35);
  }

  .product_info {
    display: flex;
    flex-direction: column;
    gap: 19px;
  }

  .product_info_title {
    font-family: Pretendard;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.14px;
    text-align: left;
    color: #fff;
    white-space: pre-line;
  }

  .product_info_subtitle {
    /* height: 15px; */
    font-family: Pretendard;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 15px; */
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .product_nav_wrap {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .product_nav {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;
    padding: 6px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    cursor: pointer;
  }

  .product_nav_inner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 50%;
  }

  /* 이벤트 */
  .event_background_container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: calc(50% - 112px);
    height: calc(100% - 42px);
    background-color: #fff;
    padding-left: 91px;
    padding-right: 21px;
    padding-bottom: 42px;
  }

  .event_background_container > img {
    width: 100%;
  }

  .event_area {
    display: flex;
    align-items: center;
    width: calc(100% - 100px);
    height: calc(100% - 200px);
    padding: 100px 0 100px 100px;
    gap: 100px;
  }

  .event_area_label {
    display: flex;
    flex-direction: column;
    gap: 69px;
  }

  .event_area_title_wrap {
    display: flex;
    flex-direction: column;
    gap: 39px;
  }

  .event_area_title {
    height: 46px;
    font-family: Poppins;
    font-size: 64px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 46px;
    letter-spacing: normal;
    text-align: left;
    color: #111;
  }

  .event_area_subtitle {
    height: 24px;
    font-family: Pretendard;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 24px;
    letter-spacing: normal;
    text-align: left;
    color: #2e2f30;
    white-space: nowrap;
  }

  .event_nav {
    display: flex;
    align-items: center;
    gap: 11px;
  }

  .event_nav_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;
    border-radius: 50%;
    border: 2px solid #000000;
  }

  .event_nav_btn:global.disable {
    border: 2px solid #b1b4b8;
    cursor: default;
  }

  .event_nav_btn:global.disable > img {
    filter: grayscale(100%);
  }

  .event_nav_btn:first-child > img {
    transform: rotate(180deg);
  }

  .event_list {
    display: flex;
    align-items: center;
    /* width: 70%; */
  }

  .event_item {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: 0px 10px 27px 0 rgba(0, 0, 0, 0.07);
    background-color: #fff;
  }

  .event_item {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: 0px 10px 27px 0 rgba(0, 0, 0, 0.07);
    background-color: #fff;
  }

  .event_info_wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    gap: 58px;
  }

  .event_title {
    font-family: Pretendard;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: normal;
    text-align: left;
    color: #111;
  }

  .event_input_date {
    height: 14px;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 14px;
    letter-spacing: normal;
    text-align: left;
    color: #6f7277;
  }

  /* 공지사항 */
  .notice_area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 200px);
    height: 100%;
    padding: 100px;
    gap: 100px;
  }

  .notice_area_label {
    display: flex;
    flex-direction: column;
    gap: 70px;
  }

  .notice_area_title_wrap {
    display: flex;
    flex-direction: column;
    gap: 39px;
  }

  .notice_area_title {
    height: 58px;
    font-family: Pretendard;
    font-size: 58px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 58px;
    letter-spacing: normal;
    text-align: left;
    color: #111;
  }

  .notice_area_subtitle {
    height: 24px;
    font-family: Pretendard;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 24px;
    letter-spacing: normal;
    text-align: left;
    color: #2e2f30;
    white-space: nowrap;
  }

  .notice_nav {
    display: flex;
    align-items: center;
  }

  .notice_viewmore_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 213px;
    height: 46px;
    border-radius: 25px;
    border: solid 2px #111;
    font-family: Poppins;
    font-size: 19px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #111;
  }

  .notice_list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 35px;
    width: 100%;
    height: 100%;
  }

  .notice_item {
    display: flex;
    justify-content: space-between;
    padding-bottom: 35px;
    border-bottom: 2px solid #e1e1e1;
    gap: 30px;
    width: 100%;
  }

  .notice_info_wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 196px);
  }

  .notice_info_container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .notice_label {
    height: 15px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 15px;
    letter-spacing: normal;
    text-align: left;
    color: #00177c;
  }

  .notice_title {
    /* height: 20px; */
    font-family: Pretendard;
    font-size: 21px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 20px; */
    letter-spacing: normal;
    text-align: left;
    color: #111;
    margin-top: 29px;
  }

  .notice_text {
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 1.65; */
    letter-spacing: normal;
    /* text-align: left; */
    color: #6f7277;
    margin-top: 25px;
    white-space: nowrap;
    /* max-height: 56px; */
    overflow: hidden;
  }

  .innertext {
    width: 100%;
  }

  .innertext > p {
    width: 100%;
    text-align: left !important;
    white-space: pre-line;
  }

  .notice_input_date {
    height: 14px;
    font-family: Pretendard;
    font-size: 17.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 14px;
    letter-spacing: normal;
    text-align: left;
    color: #6f7277;
    margin-top: 16px;
  }

  .notice_img {
    box-shadow: 0px 7px 10px 0 rgba(0, 0, 0, 0.2);
    width: 196px;
    height: 184px;
  }

  /* 고객사 */

  .customer_area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100% - 500px);
    height: calc(100% - 200px);
    background-color: #f8f8f8;
    gap: 100px;
    padding: 100px 250px;
  }

  .customer_title_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 39px;
  }

  .customer_title {
    height: 47px;
    font-family: Poppins;
    font-size: 64px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 47px;
    letter-spacing: normal;
    text-align: left;
    color: #111;
  }

  .customer_subtitle {
    height: 24px;
    font-family: Pretendard;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 24px;
    letter-spacing: normal;
    text-align: left;
    color: #2e2f30;
  }

  .customer_list {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 29px 10px;
  }

  .customer_list > img {
    width: 100%;
  }
}

/* 테블릿 가로, 테블릿 세로 (해상도 768px ~ 1024px)*/
@media all and (min-width: 768px) and (max-width: 1024px) {
  .wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    position: relative;
    overflow: hidden;
  }

  .wrapper:nth-child(2) {
    height: 100vh;
  }

  .donut {
    position: absolute;
    width: 40px;
    bottom: 25px;
    height: 20px;
    display: flex;
    align-items: center;
    column-gap: 10px;
    z-index: 9;
  }

  .skipbtn {
    width: 7px;
    height: 7px;
    background-color: #e1e1e1;
    border-radius: 7px;
  }

  .skbtn_none {
    display: none;
  }

  .scrollicon {
    display: none;
    position: absolute;
    bottom: 0vh;
    transform: translatey(0px);
    animation: float 1.2s ease-in-out infinite;
  }

  @keyframes float {
    0% {
      /* box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6); */
      transform: translatey(0px);
    }

    50% {
      /* box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2); */
      transform: translatey(-20px);
    }

    100% {
      /* box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6); */
      transform: translatey(0px);
    }
  }

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    /* transform: scale(1.1) rotate(-0.01deg); */
    transform: translate(0);
    transition-property: transform;
    transition-duration: 1.5s;
  }

  .background_none {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    /* transform: scale(1.1) rotate(-0.01deg); */
    transform: translate(-100%);
    transition-property: transform;
    transition-duration: 1.5s;
  }

  .background2 {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    /* transform: scale(1.1) rotate(-0.01deg); */
    transform: translate(100%);
    transition-property: transform;
    transition-duration: 1.5s;
  }

  .background_none2 {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    /* transform: scale(1.1) rotate(-0.01deg); */
    transform: translate(0);
    transition-property: transform;
    transition-duration: 1.5s;
  }

  .background_blur {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .background:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  /* 메인 */
  .swiper_area {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transform: translate(0);
    transition-property: transform;
    transition-duration: 1.5s;
  }

  .swiper_area_none {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transform: translate(-100%);
    transition-property: transform;
    transition-duration: 1.5s;
  }

  .swiper_area2 {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transform: translate(100%);
    transition-property: transform;
    transition-duration: 1.5s;
  }

  .swiper_area_none2 {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transform: translate(0);
    transition-property: transform;
    transition-duration: 1.5s;
  }

  .swiper_text1 {
    height: 41px;
    line-height: 41px;
    font-family: Pretendard;
    font-size: 40px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    width: 615px;
    text-align: center;
  }

  .swiper_text2 {
    margin-top: 45px;
    height: 70px;
    line-height: 70px;
    font-family: Pretendard;
    font-size: 95px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .swiper_text3 {
    margin-top: 53px;
    height: 25px;
    line-height: 25px;
    font-family: Pretendard;
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  /* 상품 */
  .product_area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100% - 100px);
    height: calc(100% - 100px);
    padding: 50px;
  }

  .product_title {
    height: 49px;
    font-family: Pretendard;
    font-size: 55px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 49px;
    letter-spacing: normal;
    text-align: left;
    color: #111;
  }

  .product_subtitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;
    margin-top: 59px;
    margin-bottom: 46px;
  }

  .product_description:first-child {
    font-family: Pretendard;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #2e2f30;
    white-space: pre-line;
  }

  .product_description:last-child {
    font-family: Pretendard;
    font-size: 19px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #898a8c;
    white-space: pre-line;
  }

  .product_card_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }

  .product_card_wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .product_card_wrapper:first-child {
    margin-top: 0;
  }

  .product_card_wrapper:nth-child(2) {
    margin-top: 0;
  }

  .product_card_wrapper:nth-child(3) {
    margin-bottom: 0;
  }

  .product_card_wrapper:last-child {
    margin-bottom: 0;
  }

  .product_card_item {
    width: 305px;
    height: 230px;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    border-radius: 15px;
  }

  .product_card_img {
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
    position: absolute;
    z-index: -1;
  }

  .product_card_img_hover {
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
    position: absolute;
    z-index: -1;
    transform: scale(1.1);
  }

  .product_info_wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 60px);
    height: calc(100% - 60px);
    padding: 30px;
    background-color: rgba(34, 34, 34, 0.35);
  }

  .product_info {
    display: flex;
    flex-direction: column;
    gap: 19px;
  }

  .product_info_title {
    font-family: Pretendard;
    font-size: 28px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.14px;
    text-align: left;
    color: #fff;
    white-space: pre-line;
  }

  .product_info_subtitle {
    height: 15px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 15px;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .product_nav_wrap {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .product_nav {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;
    padding: 6px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    cursor: pointer;
  }

  .product_nav_inner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 50%;
  }

  /* 이벤트 */
  .event_background_container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: calc(50% - 112px);
    height: calc(100% - 42px);
    background-color: #fff;
    padding-left: 91px;
    padding-right: 21px;
    padding-bottom: 42px;
  }

  .event_background_container > img {
    width: 100%;
  }

  .event_area {
    display: flex;
    align-items: center;
    width: calc(100% - 100px);
    height: 100%;
    padding: 100px 0 100px 100px;
    gap: 50px;
  }

  .event_area_label {
    display: flex;
    flex-direction: column;
    gap: 69px;
  }

  .event_area_title_wrap {
    display: flex;
    flex-direction: column;
    gap: 39px;
  }

  .event_area_title {
    height: 46px;
    font-family: Pretendard;
    font-size: 55px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 46px;
    letter-spacing: normal;
    text-align: left;
    color: #111;
  }

  .event_area_subtitle {
    height: 24px;
    font-family: Pretendard;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 24px;
    letter-spacing: normal;
    text-align: left;
    color: #2e2f30;
    white-space: nowrap;
  }

  .event_nav {
    display: flex;
    align-items: center;
    gap: 11px;
  }

  .event_nav_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;
    border-radius: 50%;
    border: 2px solid #000000;
  }

  .event_nav_btn:global.disable {
    border: 2px solid #b1b4b8;
    cursor: default;
  }

  .event_nav_btn:global.disable > img {
    filter: grayscale(100%);
  }

  .event_nav_btn:first-child > img {
    transform: rotate(180deg);
  }

  .event_list {
    display: flex;
    align-items: center;
    /* width: 70%; */
  }

  .event_item {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: 0px 10px 27px 0 rgba(0, 0, 0, 0.07);
    background-color: #fff;
  }

  .event_item {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: 0px 10px 27px 0 rgba(0, 0, 0, 0.07);
    background-color: #fff;
  }

  .event_info_wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    gap: 58px;
  }

  .event_title {
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: normal;
    text-align: left;
    color: #111;
  }

  .event_input_date {
    height: 14px;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 14px;
    letter-spacing: normal;
    text-align: left;
    color: #6f7277;
  }

  /* 공지사항 */
  .notice_area {
    /* display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 200px);
    height: calc(100% - 200px);
    padding: 100px; */

    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 200px);
    height: 100%;
    padding: 50px 100px;
    gap: 45px;
  }

  .notice_area_label {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 70px;
  }

  .notice_area_title_wrap {
    display: flex;
    flex-direction: column;
    gap: 39px;
  }

  .notice_area_title {
    height: 48px;
    font-family: Pretendard;
    font-size: 50px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 48px;
    letter-spacing: normal;
    text-align: left;
    color: #111;
  }

  .notice_area_subtitle {
    height: 20px;
    font-family: Pretendard;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: normal;
    text-align: left;
    color: #2e2f30;
    white-space: nowrap;
  }

  .notice_nav {
    display: flex;
    align-items: center;
  }

  .notice_viewmore_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 213px;
    height: 46px;
    border-radius: 25px;
    border: solid 2px #111;
    font-family: Poppins;
    font-size: 19px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #111;
  }

  .notice_list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 35px;
    width: 100%;
    height: 100%;
  }

  .notice_item {
    display: flex;
    justify-content: space-between;
    padding-bottom: 35px;
    border-bottom: 2px solid #e1e1e1;
    gap: 30px;
    width: 100%;
  }

  .notice_info_wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 196px);
  }

  .notice_info_container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .notice_label {
    height: 15px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 15px;
    letter-spacing: normal;
    text-align: left;
    color: #00177c;
  }

  .notice_title {
    height: 20px;
    font-family: Pretendard;
    font-size: 21px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: normal;
    text-align: left;
    color: #111;
    margin-top: 29px;
  }

  .notice_text {
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 1.65; */
    letter-spacing: normal;
    /* text-align: left; */
    color: #6f7277;
    margin-top: 25px;
    white-space: nowrap;
    /* max-height: 56px; */
    overflow: hidden;
  }

  .innertext {
    width: 100%;
  }

  .innertext > p {
    width: 100%;
    text-align: left !important;
    white-space: pre-line;
  }

  .notice_input_date {
    height: 14px;
    font-family: Pretendard;
    font-size: 17.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 14px;
    letter-spacing: normal;
    text-align: left;
    color: #6f7277;
  }

  .notice_img {
    box-shadow: 0px 7px 10px 0 rgba(0, 0, 0, 0.2);
    width: 196px;
    height: 184px;
  }

  /* 고객사 */

  .customer_area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100% - 100px);
    height: calc(100% - 100px);
    background-color: #f8f8f8;
    gap: 100px;
    padding: 50px;
  }

  .customer_title_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 39px;
  }

  .customer_title {
    height: 47px;
    font-family: Pretendard;
    font-size: 55px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 47px;
    letter-spacing: normal;
    text-align: left;
    color: #111;
  }

  .customer_subtitle {
    height: 24px;
    font-family: Pretendard;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 24px;
    letter-spacing: normal;
    text-align: left;
    color: #2e2f30;
  }

  .customer_list {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 29px 10px;
  }

  .customer_list > img {
    width: 100%;
  }
}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width: 767px) {
  .wrapper {
    display: flex;
    width: 100%;

    justify-content: center;
    position: relative;
    overflow: hidden;
  }

  .wrapper:nth-child(2) {
    height: 100vh;
  }

  .donut {
    position: absolute;
    width: 40px;
    bottom: 25px;
    height: 20px;
    display: flex;
    align-items: center;
    column-gap: 10px;
    z-index: 9;
  }

  .skipbtn {
    width: 7px;
    height: 7px;
    background-color: #e1e1e1;
    border-radius: 7px;
  }

  .skbtn_none {
    display: none;
  }

  .scrollicon {
    display: none;
    position: absolute;
    bottom: 0vh;
    transform: translatey(0px);
    animation: float 1.2s ease-in-out infinite;
  }

  @keyframes float {
    0% {
      /* box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6); */
      transform: translatey(0px);
    }

    50% {
      /* box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2); */
      transform: translatey(-20px);
    }

    100% {
      /* box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6); */
      transform: translatey(0px);
    }
  }

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    /* transform: scale(1.1) rotate(-0.01deg); */
    transform: translate(0);
    transition-property: transform;
    transition-duration: 1.5s;
  }

  .background_none {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    /* transform: scale(1.1) rotate(-0.01deg); */
    transform: translate(-100%);
    transition-property: transform;
    transition-duration: 1.5s;
  }

  .background2 {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    /* transform: scale(1.1) rotate(-0.01deg); */
    transform: translate(100%);
    transition-property: transform;
    transition-duration: 1.5s;
  }

  .background_none2 {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    /* transform: scale(1.1) rotate(-0.01deg); */
    transform: translate(0);
    transition-property: transform;
    transition-duration: 1.5s;
  }

  .background_blur {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .background:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  /* 메인 */
  .swiper_area {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transform: translate(0);
    transition-property: transform;
    transition-duration: 1.5s;
  }

  .swiper_area_none {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transform: translate(-100%);
    transition-property: transform;
    transition-duration: 1.5s;
  }

  .swiper_area2 {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transform: translate(100%);
    transition-property: transform;
    transition-duration: 1.5s;
  }

  .swiper_area_none2 {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transform: translate(0);
    transition-property: transform;
    transition-duration: 1.5s;
  }

  .swiper_text1 {
    font-family: Pretendard;
    font-size: 25px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    white-space: pre-line;
  }

  .swiper_text2 {
    margin-top: 26px;
    height: 54px;
    font-family: Pretendard;
    font-size: 45px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 54px;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .swiper_text3 {
    margin-top: 28px;
    height: 20px;
    line-height: 20px;
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }

  /* 상품 */
  .product_area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100% - 21px);
    /* height: 100%; */
    padding: 40px 21px;
  }

  .product_title {
    font-family: Pretendard;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    height: 38px;
    line-height: 38px;
    letter-spacing: normal;
    text-align: left;
    color: #111;
  }

  .product_subtitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;
    margin-top: 36px;
    margin-bottom: 41px;
  }

  .product_description:first-child {
    font-family: Pretendard;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: #2e2f30;
    white-space: pre-line;
  }

  .product_description:last-child {
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: normal;
    text-align: center;
    color: #898a8c;
    white-space: pre-line;
  }

  .product_card_list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .product_card_wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .product_card_wrapper:first-child {
    margin-top: 0px;
  }

  .product_card_wrapper:nth-child(2) {
    margin-top: 0;
  }

  .product_card_wrapper:nth-child(3) {
    margin-bottom: 0;
  }

  .product_card_wrapper:last-child {
    margin-bottom: 0;
    justify-content: flex-end;
  }

  .product_card_item {
    width: 333px;
    height: 251px;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    border-radius: 15px;
  }

  .product_card_img {
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
    position: absolute;
    z-index: -1;
  }

  .product_card_img_hover {
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
    position: absolute;
    z-index: -1;
    transform: scale(1.1);
  }

  .product_info_wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 60px);
    height: calc(100% - 60px);
    padding: 30px;
    background-color: rgba(34, 34, 34, 0.35);
  }

  .product_info {
    display: flex;
    flex-direction: column;
    gap: 19px;
  }

  .product_info_title {
    font-family: Pretendard;
    font-size: 28px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.14px;
    text-align: left;
    color: #fff;
    white-space: pre-line;
  }

  .product_info_subtitle {
    height: 15px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 15px;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .product_nav_wrap {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .product_nav {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;
    padding: 6px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    cursor: pointer;
  }

  .product_nav_inner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 50%;
  }

  /* 이벤트 */
  .event_background_container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: calc(50% - 112px);
    height: calc(100% - 42px);
    background-color: #fff;
    padding-left: 91px;
    padding-right: 21px;
    padding-bottom: 42px;
  }

  .event_background_container > img {
    width: 100%;
  }

  .event_area {
    display: flex;
    flex-direction: column;
    padding: 60px 0 45px 20px;
    align-items: center;
    width: calc(100% - 20px);
    height: calc(100% - 105px);
    gap: 45px;
  }

  .event_area_label {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .event_area_title_wrap {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .event_area_title {
    font-family: Pretendard;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 38px;
    letter-spacing: normal;
    text-align: left;
    color: #111;
    height: 38px;
  }

  .event_area_subtitle {
    font-family: Pretendard;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 21px;
    letter-spacing: normal;
    color: #2e2f30;
    height: 21px;
  }

  .event_nav {
    display: flex;
    align-items: center;
    gap: 10px;
    width: calc(100% - 20px);
    justify-content: flex-end;
    padding-right: 20px;
  }

  .event_nav_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 51px;
    height: 51px;
    border-radius: 50%;
    border: 2px solid #000000;
  }

  .event_nav_btn:global.disable {
    border: 2px solid #b1b4b8;
    cursor: default;
  }

  .event_nav_btn:global.disable > img {
    filter: grayscale(100%);
  }

  .event_nav_btn:first-child > img {
    transform: rotate(180deg);
  }

  .event_list {
    display: flex !important;
    width: 100%;
    justify-content: flex-start;
  }

  .event_item {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: 0px 10px 27px 0 rgba(0, 0, 0, 0.07);
    background-color: #fff;
  }

  .event_item {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: 0px 10px 27px 0 rgba(0, 0, 0, 0.07);
    background-color: #fff;
  }

  .event_info_wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 18px 20px;
    gap: 18px;
  }

  .event_title {
    font-family: Pretendard;
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: normal;
    text-align: left;
    color: #111;
  }

  .event_input_date {
    height: 19px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 19px;
    letter-spacing: normal;
    text-align: left;
    color: #6f7277;
  }

  /* 공지사항 */
  .notice_area {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 40px);
    height: 100%;
    padding: 60px 20px;
    gap: 45px;
  }

  .notice_area_label {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 35px;
  }

  .notice_area_title_wrap {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .notice_area_title {
    font-family: Pretendard;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #111;
  }

  .notice_area_subtitle {
    font-family: Pretendard;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: #2e2f30;
  }

  .notice_nav {
    display: flex;
    align-items: center;
  }

  .notice_viewmore_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 48px;
    border: solid 1px #111;
    font-family: Poppins;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #111;
    border-radius: 25px;
  }

  .notice_list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    gap: 19.5px;
    height: 100%;
  }

  .notice_item {
    display: flex;
    justify-content: space-between;
    padding-bottom: 19.5px;
    border-bottom: 2px solid #e1e1e1;
    gap: 24px;
    width: 100%;
    align-items: center;
  }

  .notice_info_wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 162px);
    gap: 16px;
  }

  .notice_info_container {
    display: flex;
    flex-direction: column;
    /* width: 536.5px; */
  }

  .notice_label {
    font-family: Pretendard;
    font-size: 11.5px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    height: 14px;
    line-height: 14px;
    letter-spacing: normal;
    text-align: left;
    color: #00177c;
  }

  .notice_title {
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: #111;
    margin-top: 12px;
  }

  .notice_text {
    font-family: Pretendard;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 1.65; */
    letter-spacing: normal;
    /* text-align: left; */
    color: #6f7277;
    margin-top: 8px;
    white-space: nowrap;
    /* max-height: 56px; */
    overflow: hidden;
  }

  .innertext {
    width: 100%;
  }

  .innertext > p {
    width: 100%;
    text-align: left !important;
    white-space: pre-line;
  }

  .notice_input_date {
    height: 14px;
    font-family: Pretendard;
    font-size: 17.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 14px;
    letter-spacing: normal;
    text-align: left;
    color: #6f7277;
  }

  .notice_img {
    box-shadow: 0px 7px 10px 0 rgba(0, 0, 0, 0.2);
    width: 138px;
    height: 130px;
  }

  /* 고객사 */

  .customer_area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100% - 40px);
    height: 100%;
    background-color: #f8f8f8;
    gap: 60px;
    padding: 60px 20px;
  }

  .customer_title_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }

  .customer_title {
    font-family: Pretendard;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #111;
  }

  .customer_subtitle {
    font-family: Pretendard;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #2e2f30;
  }

  .customer_list {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    gap: 15px 9px;
  }

  .customer_view_more_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 146px;
    height: 48px;
    border-radius: 24px;
    border: solid 1px #e1e1e1;
    gap: 10px;
    cursor: pointer;
  }

  .customer_view_more_btn > div {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #393939;
  }

  .customer_list > img {
    width: 100%;
  }
}
