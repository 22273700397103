.jodit-container {
  min-height: 400px !important;
  max-height: 400px !important;
}

.jodit-wysiwyg {
  min-height: 300px !important;
  max-height: 300px !important;
}

body {
  margin: 0;
  width: 100%;
  height: 100%;
  position: relative;
  /* font-family: Pretendard;
  -webkit-appearance: none;
  appearance: none; */
}

p {
  margin: 0;
}

.container {
  height: calc(var(--vh, 1vh) * 100);
  height: 100dvh;
  width: 100%;
}

@media all and (min-width: 768px) and (max-width: 1024px) {
  /* .container {
    background: url("http://bluetns.co.kr/img/info_files/tablet.png") no-repeat;
    background-size: 100% auto;
  } */
}

@media all and (max-width: 767px) {
  /* .container {
    background: url("http://bluetns.co.kr/img/info_files/mobile.png") no-repeat;
    background-size: 100% auto;
  } */
}

.cursor {
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.spin-container {
  /* border: 1px solid red; */
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-modal-confirm-content {
  max-width: 100% !important;
}

.ant-input:hover {
  border-color: #13348f !important;
}

:where(.css-dev-only-do-not-override-htwhyh).ant-input:focus,
:where(.css-dev-only-do-not-override-htwhyh).ant-input-focused {
  border-color: #13348f !important;
}

.ant-btn-primary:not(:disabled):hover {
  /* color: #2a2a2a !important;
  fill: #2a2a2a !important; */
  color: #fff !important;
  fill: #fff !important;
}

/* .ant-spin-nested-loading,
.ant-spin-container,
.ant-table,
.ant-table-container,
.ant-table-content,
.ant-table-content > table {
  height: 100% !important;
} */

/***** ant control *****/

/* ant segment */
.segmented .ant-segmented .ant-segmented-item {
  border: 1px solid none;
}

.segmented .ant-segmented.ant-segmented-lg .ant-segmented-item-label {
  font-size: 24px;
}

.ant-segmented .ant-segmented-item-selected {
  border: 1px solid !important;
}

.anticon svg {
  /* width: 20px;
  height: 20px; */
  /* margin-right: 5px; */
}

/* ant table sorter icon */
.list .ant-table-column-sorter {
  content: url("/public/img/common/sort_icon.png");
  width: 18px;
  height: 18px;
}

/* table thead control */
/* .list .ant-table-wrapper .ant-table-column-title,
.list .ant-table-wrapper .ant-table-thead > tr > th,
.list .ant-table-wrapper .ant-table-thead > tr > td {
  font-family: SpoqaHanSansNeo;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #595959;
} */

/* table tbody control */
:where(.css-dev-only-do-not-override-iuwucx).ant-table-wrapper .ant-table-tbody>tr>th,
:where(.css-dev-only-do-not-override-iuwucx).ant-table-wrapper .ant-table-tbody>tr>td {
  font-family: SpoqaHanSansNeo;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #333;
}

/* table link control */
.list .ant-btn-link {
  color: #13348f;
}

.list .ant-btn-link:hover {
  color: #b4e138;
}

.notice-table .ant-table-thead {
  background-color: black;
}

.url .ant-btn {
  padding: 0;
}

/* ant inputbox size */
.inputbox-size {
  width: 282px;
  height: 40px;
}

.ant-select-dropdown {
  min-width: fit-content !important;
}

.list .ant-table-cell {
  max-width: 480px;
}

/* .fc-license-message {
  display: none;
} */

.section_select .select-box .ant-select-arrow {
  color: white;
}

.month-schedule {
  width: 100%;
  display: flex;
  align-items: center;
}

.month-schedule .scd-circle {
  min-width: 8px;
  height: 8px;
  border-radius: 10px;
  margin-right: 5px;
  margin-left: 5px;
}

.month-schedule .scd-time {
  margin-right: 3px;
}

.month-schedule .scd-title {
  /* border: 1px solid red; */
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
}

.search-btn {
  cursor: pointer;
  color: #d9d9d9;
  font-size: 22px;
}

.search-btn:hover {
  color: #13348f;
}

.ant-skeleton.ant-skeleton-element {
  width: 100%;
}

.alert-modal .ant-modal-body {
  white-space: pre-line;
}

.alert-modal {
  width: 455px !important;
}

.alert-modal>.ant-modal-content {
  border-radius: 3px;
}

.alert-modal .ant-btn-default {
  padding-left: 36px;
  padding-right: 36px;
  border-radius: 1px;
}

.alert-modal .ant-btn.ant-btn-lg {
  padding-left: 36px;
  padding-right: 36px;
  border-radius: 1px;
}

.alert-modal .ant-modal-footer {
  margin-top: 22px;
}

.alert-header {
  display: flex;
  align-items: center;
  column-gap: 13px;
}

.alert-icon {
  width: 38px;
  height: 38px;
  background-color: #e3e4f7;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alert-icon>svg {
  width: 23px;
  height: 20px;
}

.alert-title {
  font-family: Pretendard;
  font-size: 19px;
  color: #282828;
}

.alert-content {
  font-family: Pretendard;
  font-size: 16px;
  color: #333;
  margin-left: 10px;
}

/* .swiper {
  width: calc(100% - 168px);
  height: 100%;
} */

@media all and (min-width: 768px) and (max-width: 1023px) {
  .swiper {
    width: calc(100% - 86px);
    height: 100%;
  }
}

@media all and (max-width: 767px) {
  .swiper {
    width: calc(100% - 114px);
    height: 100%;
    margin: 0 !important;
  }
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* background: #fff; */

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.loading_wrapper {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #fff;
  width: 100%;
  height: 100%;
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-wrapper {
  transition-timing-function: linear !important;
}

@media all and (min-width: 1025px) {
  .ant-float-btn-group {
    width: 80px;
    /* height: 79px; */
  }

  .ant-float-btn-body {
    width: 100% !important;
    height: 100% !important;
    background-color: transparent !important;
  }

  .ant-float-btn-group-wrap {
    display: flex;
    margin-bottom: 0px;
  }

  .landing_pop_wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 100px;
  }
}

.ReactModalPortal {
  position: absolute;
  top: 0;
  left: 0;
}

.landing_pop_wrapper {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
}

.landing_pop_container {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
}

.landing_pop_container>img {
  width: 100%;
  max-width: 500px;
  height: auto;
  cursor: pointer;
}

.landing_pop_footer {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  color: #000;
  column-gap: 5px;
  font-size: 13px;
  font-family: Pretendard;
}

.landing_pop_footer_chk {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.landing_pop_footer>p {
  cursor: pointer;
}

@media all and (min-width: 768px) and (max-width: 1024px) {
  .ant-float-btn-group {
    width: 80px;
    border-radius: 0px;
    /* height: 79px; */
  }

  .ant-float-btn-default {
    width: 80px;
    height: 80px;
  }

  .ant-float-btn {
    width: 80px;
    height: 80px;
  }

  .ant-float-btn-body {
    width: 100% !important;
    height: 100% !important;
    /* background-color: transparent !important; */
  }

  .landing_pop_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .anticon svg {
    width: 25px !important;
    height: 25px !important;
  }
}

@media all and (max-width: 767px) {
  .ant-float-btn-group {
    width: 52px;
    border-radius: 0px;
    /* height: 79px; */
  }

  .ant-float-btn-default {
    width: 52px;
    height: 52px;
  }

  .ant-float-btn {
    width: 52px;
    height: 52px;
    border-radius: 0 !important;
  }

  .ant-float-btn-body {
    width: 100% !important;
    height: 100% !important;
    background-color: transparent !important;
  }

  .landing_pop_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    width: calc(100dvw - 40px);
  }

  .anticon svg {
    width: 25px !important;
    height: 25px !important;
  }

  .ant-menu-submenu-title .ant-menu-title-content {
    font-family: Pretendard;
    font-size: 19px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #111;
  }

  .ant-menu-submenu-open .ant-menu-submenu-title {
    background-color: #fff;
    border-end-start-radius: 0px;
    border-end-end-radius: 0px;
    border-bottom: 1px solid #001572;
  }

  .ant-menu-submenu-open .ant-menu-submenu-title .ant-menu-title-content {
    color: #001572;
  }

  .ant-menu-submenu-open .ant-menu-submenu-arrow::after {
    color: #001572;
  }

  .ant-menu-sub {
    background-color: transparent !important;
    border-bottom: 1px solid #e1e1e1 !important;
    margin: 0 20px !important;
  }

  .ant-menu-item .ant-menu-title-content {
    font-family: Pretendard;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #393939;
  }

  .ant-menu-item.ant-menu-item-active .ant-menu-title-content {
    font-family: Pretendard;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #111;
  }
}